import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import {
  getLessonPlans,
  setWatchingProgress,
  addNote,
  editNote,
  deleteNote,
} from "../../../../../Services/api/Trainee/TraineeProvider";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CircularProgressBar from "../../TrainingCourseContent/CircularProgressBar";
import LessonActivityItem from "./LessonActivityItem";
import LessonContentItem from "./LessonContentItem";
import LessonDiscussionItem from "./LessonDiscussionItem";
import LessonQuizItem from "./LessonQuizItem";
import LessonSessionItem from "./LessonSessionItem";
import LessonSurveyItem from "./LessonSurveyItem";
import Note from "../../../../../assets/icons/edit.svg";
import NoteG from "../../../../../assets/icons/editg.svg";
import ModelWithInput from "../../../../Shared/Components/ModelWithInput/ModelWithInput";
import { toast } from "react-toastify";

export default function LessonContentView({ role }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [plans, setPlans] = useState([]);
  const [lesson, setLesson] = useState({});
  const [isCourseClosed, setIsCourseClosed] = useState(true);
  const { courseId, chapterId, unitId, lessonId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { index: pageIndex = 0 } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getLessonPlans(lessonId, { perPage: 100 }).then((res) => {
      if (res.status == 200 && res.data.status) {
        setPlans(res.data.plans);
        setLesson(res.data.lesson);
        setIsCourseClosed(
          res.data.lesson.course_status === "Closed" ||
            res.data.lesson.course_status === "مغلقة"
        );
        setIsLoading(false);
      }
    });
  }

  return (
    <>
      {isUpdating ? (
        <div
          style={{
            height: "60vh",
          }}
        >
          <SkeletonCardOverlay />
        </div>
      ) : (
        <></>
      )}

      <div className="tw-container tw-mx-auto tw-py-8">
        {isLoading ? (
          <div
            style={{
              height: "60vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="tw-rounded-xl ">
            <div className="tw-space-y-4 tw-p-8">
              <div className="tw-flex tw-items-stretch tw-justify-between">
                <div className="tw-text-xl">{lesson.chapter?.name}</div>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="tw-flex tw-items-center tw-justify-center tw-px-8 tw-rounded-lg tw-bg-gray-200"
                >
                  <div className="tw-text-gray-500 tw-py-2">{t("back")}</div>
                </button>
              </div>
              <div
                className="tw-rounded-lg tw-border tw-border-gray-300 tw-p-3 tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row tw-items-center sm:tw-space-s-4 sm:tw-space-y-0"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={lesson.course?.img}
                  alt={lesson.course?.name}
                  className="tw-rounded-lg tw-h-40"
                />
                <div className="tw-space-y-4">
                  <div className="tw-text-xl tw-font-bold">
                    {lesson.course?.name}
                  </div>
                  <div
                    className="tw-text-gray-400"
                    dangerouslySetInnerHTML={{
                      __html: lesson.course?.description,
                    }}
                  ></div>
                </div>
              </div>
              <div
                className="tw-rounded-lg tw-border tw-border-gray-300 tw-p-4 tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-y-0 tw-items-center tw-justify-between tw-space-s-4"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="tw-flex tw-items-center tw-space-s-6">
                  <div className="tw-bg-teal-600 tw-rounded-lg tw-text-2xl tw-text-white tw-h-12 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans">
                    {pageIndex + 1}
                  </div>

                  <div className="tw-space-y-2">
                    <div className="tw-text-lg tw-font-bold">
                      {lesson.title}
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-items-center tw-space-s-4">
                  <CircularProgressBar
                    title={t("progress")}
                    percent={lesson.progress_percentage}
                    color="#1dde50"
                  />
                  <CircularProgressBar
                    title={t("watchingProgress")}
                    percent={lesson.watch_percentage}
                    color="#dede1e"
                  />
                </div>
              </div>
              {plans?.length ? (
                plans.map((plan) => (
                  <LessonPlan
                    key={plan.id}
                    courseId={courseId}
                    chapterId={chapterId}
                    unitId={unitId}
                    lessonId={lessonId}
                    plan={plan}
                    isCourseClosed={isCourseClosed}
                    fetchData={fetchData}
                    role={role}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function LessonPlan({
  courseId,
  chapterId,
  unitId,
  lessonId,
  plan,
  isCourseClosed,
  fetchData,
  role,
}) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [openId, setOpenId] = useState(null);

  const saveWatchingProgressAPI = async ({ watchable, watchable_type }) => {
    try {
      if (!watchable.include_view_per || watchable.is_watched) {
        return;
      }
      const payload = {
        course_id: courseId,
        lesson_id: lessonId,
        watchable_id: watchable.id,
        watchable_type,
      };
      setWatchingProgress(payload).then((res) => {
        fetchData();
      });
    } catch (err) {}
  };

  const [showNoteModel, setShowNoteModel] = useState(false);
  const [notable_id, setNotable_id] = useState(null);
  const [notable_type, setNotable_type] = useState(null);
  const [note_body, setNote_body] = useState(null);
  const [showNoteEditModel, setShowNoteEditModel] = useState(false);
  const [notableId, setnotableId] = useState(null);

  const addNoteHandler = (note) => {
    try {
      addNote(note, notable_id, notable_type).then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data?.msg}
            </span>
          );
          fetchData();
        }
      });
      setShowNoteModel(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error?.response?.data?.message}
        </span>
      );
      setShowNoteModel(false);
    }
  };
  const editNoteHandler = (note) => {
    try {
      editNote(note, notable_id, notableId, notable_type).then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data?.msg}
            </span>
          );
          fetchData();
        }
      });
      setShowNoteEditModel(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error?.response?.data?.message}
        </span>
      );
      setShowNoteEditModel(false);
    }
  };
  const deleteNoteHandler = (note) => {
    try {
      deleteNote(notable_id, notableId, notable_type).then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data?.msg}
            </span>
          );
          fetchData();
        }
      });
      setShowNoteEditModel(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error?.response?.data?.message}
        </span>
      );
      setShowNoteEditModel(false);
    }
  };

  const NoteIcon = ({ planable, type }) => (
    <img
      src={planable?.notes?.length > 0 ? NoteG : Note}
      alt=""
      style={{ width: 24, height: 24, cursor: "pointer", marginRight: 10 }}
      onClick={() => {
        if (planable?.notes?.length > 0) {
          setShowNoteEditModel(true);
          setNote_body(planable.notes[0].note);
          setNotable_id(planable.notes[0].id);
          setnotableId(planable.id);
        } else {
          setShowNoteModel(true);
          setNotable_id(planable.id);
        }
        setNotable_type(type);
      }}
    />
  );

  const lessonsTypes = [
    {
      id: 0,
      title: t("trainer.course.lesson.content"),
      type: "content",
      component: ({ id, planable }) => (
        <LessonContentItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          lessonId={lessonId}
          content={planable}
          onClose={() => setOpenId(null)}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "content",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
          end={() => !role && <NoteIcon planable={planable} type="content" />}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.activities"),
      type: "activity",
      component: ({ id, planable }) => (
        <LessonActivityItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          lessonId={lessonId}
          activity={planable}
          fetchData={fetchData}
          isCourseClosed={isCourseClosed}
          role={role}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "activity",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
          end={() =>
            !role && <NoteIcon planable={planable} type={"activity"} />
          }
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.sessions"),
      type: "meeting",
      component: ({ id, planable }) => (
        <LessonSessionItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          session={planable}
          isCourseClosed={isCourseClosed}
          role={role}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "session",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
          end={() => !role && <NoteIcon planable={planable} type={"meeting"} />}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.discussions"),
      type: "discussions",
      component: ({ id, planable }) => (
        <LessonDiscussionItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          discussion={planable}
          role={role}
          isCourseClosed={isCourseClosed}
          onClose={() => setOpenId(null)}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "discussion",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
          end={() =>
            !role && <NoteIcon planable={planable} type={"discussions"} />
          }
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.quiz"),
      type: "quiz",
      component: ({ id, planable }) => (
        <LessonQuizItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          quiz={planable}
          role={role}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "quiz",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.survey"),
      type: "survey",
      component: ({ id, planable }) => (
        <LessonSurveyItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          survey={planable}
          role={role}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              !role &&
              saveWatchingProgressAPI({
                watchable_type: "survey",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
  ];
  const type = lessonsTypes.find((item) => item.type == plan.type);

  return (
    <>
      {type && !type.hide && (
        <type.component key={plan.id} id={plan.id} planable={plan.planable} />
      )}
      <ModelWithInput
        openModal={showNoteModel}
        setOpenModal={setShowNoteModel}
        modalTitle={t("add_note")}
        type={"textarea"}
        isNote={true}
        buttonText={t("create")}
        handleSubmit={addNoteHandler}
      />
      <ModelWithInput
        openModal={showNoteEditModel}
        setOpenModal={setShowNoteEditModel}
        modalTitle={t("note")}
        type={"textarea"}
        isNote={true}
        textAreaValue={note_body}
        buttonText={t("edit")}
        buttonTextEdit={t("delete")}
        submitHandlerEdit={deleteNoteHandler}
        handleSubmit={editNoteHandler}
      />
    </>
  );
}

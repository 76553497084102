import { useContext, useEffect, useState } from "react";

import { arSA } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { getEventPerDay, getEventPerMonth, getEventTypes } from "../../../Services/api/event/eventProvider";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";
import "./Calender.css";
import classes from "./CalenderNew.module.css";
import Daycell from "./Daycell";
import DayEvents from "./DayEvents";

export default function CalenderNew() {
  const currentDate = moment().format("YYYY-MM-DD");
  const authContext = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(currentDate);
  //   const [value, setValue] = useState(currentDate);
  const [LoadingCalender, setLoadingCalender] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [badgesLoading, setBadgesLoading] = useState(true);
  const [LoadingEvents, setLoadingEvents] = useState(true);
  const [eventsOfDay, setEventsOfDay] = useState([]);
  const [showEvents, setShowEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const [endOfMonth, setEndOfMonth] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [startOfMonth, setStartOfMonth] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  //   console.log(arSA);
  const getEventsOfMonth = (start, end) =>
    getEventPerMonth(start, end)
      .then((res) => {
        if (res.status === 200) {
          const dateMap = res.data.data.reduce((map, item) => {
            const { date } = item;
            if (!map.has(date)) {
              map.set(moment(date).format("YYYY-MM-DD"), []);
            }
            map.get(moment(date).format("YYYY-MM-DD")).push(item);
            return map;
          }, new Map());
          setEvents(dateMap);
          setLoadingCalender(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  const getEventsOfDay = (date) => {
    setLoadingEvents(true);
    getEventPerDay(date)
      .then((res) => {
        if (res.status === 200) {
          setEventsOfDay(res.data.data);
          setLoadingEvents(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingEvents(false);
      });
  };

  useEffect(() => {
    getEventsOfDay(selected);
  }, [selected]);

  useEffect(() => {
    getEventTypes()
      .then((res) => {
        if (res.status === 200) {
          setEventTypes(res.data.data);
          // console.log(res.data);
          setBadgesLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // setLoadingCalender(false);
    getEventsOfMonth(startOfMonth, endOfMonth);
  }, [startOfMonth, endOfMonth]);

  const handleNextPrev = (value) => {
    setStartOfMonth(moment(value).format("YYYY-MM-DD"));
    setEndOfMonth(moment(value).endOf("month").format("YYYY-MM-DD"));
  };
  return (
    <>
      {LoadingCalender ? (
        <div className="container-fluid">
          <div className={classes.CalenderSection}>
            <div className={classes.CalenderCont}>
              <DayPicker
                mode="single"
                onMonthChange={handleNextPrev}
                locale={i18n.language === "ar" ? arSA : null}
                labels={{
                  weekdays: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],
                }}
                components={{
                  Day: (props) => <Daycell {...props} events={events} setSelected={setSelected} selected={selected} />,
                }}
              />
            </div>

            <>
              {LoadingEvents ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <DayEvents eventsOfDay={eventsOfDay} badgesLoading={badgesLoading} eventTypes={eventTypes} />
              )}
            </>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}

import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./SlickCarousel.css";

const CardCarousel = ({ children, slidesToShow, slidesToScroll = 3, courses }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow || 4,
    slidesToScroll: slidesToScroll,
    arrows: false,
    dots: true,
    // prevArrow: <button className="slick-prev" aria-label="Previous" />,
    // nextArrow: <button className="slick-next" aria-label="Next" />,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesToShow ? slidesToShow - 1 : 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: slidesToShow ? slidesToShow - 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div>
      <div className="uk-section" style={courses ? { maxWidth: "100%" } : {}}>
        <Slider {...settings}>{children}</Slider>
        <div style={{ textAlign: "center", marginTop: "65px" }}></div>
      </div>
    </div>
  );
};

export default CardCarousel;

import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../../../Services/Timezone";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import { joinFreeCourse } from "../../../../../Services/api/courses/courseProvider";
import Level from "../../../../../assets/icons/Subset.svg";
import calenderIcon from "../../../../../assets/icons/dateIconDetials.svg";
import Lang from "../../../../../assets/icons/languege.png";
import ArrowIcon from "../../../../../assets/icons/right-arrow-white.svg";
import Seat from "../../../../../assets/icons/seaticonDetials.svg";
import ShareIcon from "../../../../../assets/icons/shareIcon.svg";
import warning from "../../../../../assets/icons/warning-green.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import Title from "../../../../Shared/Components/Title/Title";
import WarningModal from "../../../../Shared/Components/WarningModal/WarningModal";
import SharePopup from "./SharePopup";
import classes from "./trainingCourseContent.module.css";

export default function TrainingCourseContentHeader({ setSubscription, userSCGHS, inProgram, isProgram }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const freeCourseModalRef = useRef();
  const redirectToLoginModalRef = useRef();
  const courseDetailsContext = useContext(CourseContext);
  const course = courseDetailsContext.courseDetails;
  const [showFreeCourseModal, setShowFreeCourseModal] = useState(false);
  const [showRedirectToLoginModal, setShowRedirectToLoginModal] = useState(false);
  const [isUserJoined, setIsUserJoined] = useState(false);
  const [cannotRegisterBadge, setCannotRegisterBadge] = useState(false);
  const [rejectedBadge, setRejectedBadge] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);

  useEffect(() => {
    if (course.payment_status === "Rejected") {
      setRejectedBadge(t("payment_rejected_message"));
    }
    if (!isUserJoined) {
      if (course.trainee_status === false && (course.is_joined_free || course.is_assigned || course.is_purchased)) {
        setCannotRegisterBadge(t("trainee_blocked"));
      } else if (course.is_refunded) {
        setCannotRegisterBadge(t("payment_refunded_message"));
      } else if (course.payment_status === "Waiting" && course.payment_invoice_status !== "created") {
        setCannotRegisterBadge(t("waiting_to_accept_message"));
      } else if (
        course.payment_status === "approved" &&
        moment(new Date()).isBefore(getLocaleTime(new Date(course.start_date)))
      ) {
        setCannotRegisterBadge(t(`${course.is_program ? "program" : "course"}_not_started`));
      } else if (
        course.start_register_date &&
        moment(getLocaleTime(new Date(course.start_register_date))).isAfter(moment())
      ) {
        setCannotRegisterBadge(t("registration_not_started"));
      } else if (
        course.end_register_date &&
        moment(getLocaleTime(new Date(course.end_register_date))).isBefore(moment())
      ) {
        setCannotRegisterBadge(t("registration_ended"));
      } else if (course.participators - course.number_of_users <= 0) {
        setCannotRegisterBadge(t("no_seats_available"));
      } else if (!course.registable) {
        setCannotRegisterBadge(t("prerequest_msg"));
      } else {
        setCannotRegisterBadge(false);
      }
    } else {
      setCannotRegisterBadge(false);
    }
  }, [course, userSCGHS, isUserJoined]);

  const handleOpenSharePopup = () => {
    setOpenSharePopup(true);
  };

  const handleCloseSharePopup = () => {
    setOpenSharePopup(false);
  };

  useEffect(() => {
    setIsUserJoined(
      (course.is_joined_free || course.is_assigned || course.is_purchased) &&
        (!course.is_refunded || course.is_gifted) &&
        course.trainee_status === true &&
        course.join_status?.status === "approved"
    );
  }, [course]);

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  const displayLanguage = (language) => {
    if (language === "ar") {
      return t("arabic");
    } else if (language === "en") {
      return t("english");
    }
  };

  const boxesList = [
    {
      id: 1,
      data: `${displayLevel(Number(course.level))} - ${course.number_hours} ${t("hours")}`,
      icon: Level,
    },
    ...(!inProgram
      ? [
          {
            id: 2,
            data: `${course.participators - course.number_of_users} ${t("general.seat_available")} / ${
              course.participators
            }`,
            icon: Seat,
          },
        ]
      : []),
    ...(!course.language
      ? []
      : [
          {
            id: 3,
            data: displayLanguage(course.language),
            icon: Lang,
          },
        ]),
  ];

  const joinFreeCourseAPI = () => {
    joinFreeCourse(course.id, course.is_program ? "program" : "course")
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSubscription(true);
          closeFreeCourseModal();
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t(res.data.msg)}</span>);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        closeFreeCourseModal();
      });
  };

  const openFreeCourseModal = () => {
    setShowFreeCourseModal(true);
    freeCourseModalRef.current.showModal();
  };

  const closeFreeCourseModal = () => {
    setShowFreeCourseModal(false);
    freeCourseModalRef.current.dismissModal();
  };

  const openRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(true);
    redirectToLoginModalRef.current.showModal();
  };

  const closeRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(false);
    redirectToLoginModalRef.current.dismissModal();
  };

  return (
    <div className={classes.headerContainer}>
      <div className={`container-fluid ${classes.headerContent}`}>
        <div className={classes.headerImage}>
          <img src={course.img} alt="..." className={classes.image} />
        </div>
        <div className={classes["header-container"]}>
          <div className={classes["headed-detials-mid"]}>
            <div className={classes.title}>
              <Title title={course.name} titleColor="#fff" fontSize={36} />
            </div>
            <div className={classes["star-dep-cat"]}>
              <div className={classes["flip-star"]}>
                <StarsRating rating={course.ratings_avg_stars || 0} cutStyle={classes["star-font-size"]} />
              </div>
              <p>&nbsp;| &nbsp;</p>
              <p>{`${t("department")} : ${
                i18n.language === "ar" ? course.department_name_ar : course.department_name_en
              }`}</p>
              <p>&nbsp;| &nbsp;</p>
              <p>{`${t("department_list.specialty")} : ${
                i18n.language === "ar" ? course.category.name_ar : course.category.name_en
              }`}</p>
            </div>
            <div className={classes["trainers-price"]}>
              <div className={classes["user-container"]}>
                {isProgram ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <img className={classes["user-img"]} alt="trainer img" src={course.trainer.img} />
                    </div>
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <span className={classes["trainers-name"]}>
                          {i18n.language === "ar" ? course.trainer.name_ar : course.trainer.name_en}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex" }}>
                      {course.sub_trainers?.map(
                        (trainer, index) =>
                          index < 4 && <img className={classes["user-img"]} alt="trainer img" src={trainer.img} />
                      )}
                      {course.sub_trainers.length > 4 && (
                        <div className={classes["user-img-more"]}>
                          <p>+{course.sub_trainers.length - 4}</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {course.sub_trainers.map((trainer, index) => {
                          if (index === course.sub_trainers.length - 1) {
                            return (
                              <span className={classes["trainers-name"]}>
                                {i18n.language === "ar" ? trainer.name_ar : trainer.name_en}
                              </span>
                            );
                          } else {
                            return (
                              <span className={classes["trainers-name"]}>
                                {i18n.language === "ar" ? trainer.name_ar : trainer.name_en}
                                ,&nbsp;
                              </span>
                            );
                          }
                        })}
                      </div>
                      <p className={classes["trainers-tag"]}>{t("Trainers")}</p>
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: "flex", gap: 10 }}>
                {inProgram || isUserJoined ? (
                  <div style={{ height: "60px" }}></div>
                ) : (
                  <>
                    {course?.offers && course.offers[0] ? (
                      <div className={classes["offer-badge"]}>
                        <span className={classes["offer-badge-off"]}>{t("general.off")}</span>
                        <span className={classes["offer-badge-number"]}>
                          {course.offers[0].value +
                            " " +
                            (Number(course.offers[0].mechanism) === 1 ? t("general.rs") : "%")}
                        </span>
                      </div>
                    ) : null}
                    <div className={classes["price-container"]}>
                      {course.mode === "Paid" ? (
                        <p className={classes["price_title"]}>{t("giftCourse.label.price")}</p>
                      ) : null}
                      {course?.offers?.length > 0 && course.offers[0].user_can_use_now ? (
                        <span className={classes["old-price"]}>
                          {course.price} {t("trainer.rs")}
                        </span>
                      ) : null}
                      <label className={classes.price}>
                        {course.mode === "Paid" ? (
                          <>
                            <span className={classes["price-number"]}>
                              {course?.offers[0]
                                ? Number(course.offers[0].mechanism) === 1
                                  ? Number(course.price) - Number(course.offers[0].value)
                                  : Number(course.price) - (Number(course.price) * Number(course.offers[0].value)) / 100
                                : course.price}
                            </span>{" "}
                            <span className={classes.cur}>&nbsp; {t("trainer.rs")}</span>
                          </>
                        ) : (
                          <div style={{ display: "flex", gap: 5 }}>
                            <p className={classes["price-label"]}>{t("general.free")}</p>
                            <span className={classes["price-number"]}>
                              0.00 <span className={classes.cur}>{t("trainer.rs")}</span>
                            </span>
                          </div>
                        )}
                      </label>
                    </div>
                  </>
                )}
                {cannotRegisterBadge && (
                  <div className={classes["cannot-register-badge"]}>
                    <span>{cannotRegisterBadge}</span>
                  </div>
                )}
                {rejectedBadge && (
                  <div className={classes["cannot-register-badge"]}>
                    <span>{rejectedBadge}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={classes["date-seat-hour"]}>
              {!course.self_paced && (
                <span className={classes.iconscontainer}>
                  <img src={calenderIcon} alt="" />{" "}
                  {moment(toLocalTimezone(course.start_date)).format("YYYY-MM-DD hh:mm A")}
                </span>
              )}
              {!course.program_id && (
                <span className={classes.iconscontainer}>
                  <img src={Seat} alt="" />{" "}
                  {`${course.participators - course.number_of_users} ${t("general.seat_available")} / ${
                    course.participators
                  }`}{" "}
                </span>
              )}
              <span className={classes.iconscontainer}>
                <img src={Level} alt="" />{" "}
                {`${displayLevel(Number(course.level))} - ${course.number_hours} ${t("hours")}`}{" "}
              </span>
            </div>
          </div>
        </div>
        <div style={{ flex: "1 1 auto", position: "relative" }}>
          <div className={classes["back-share"]}>
            <div className={classes["shareBackBtn"]} onClick={handleOpenSharePopup}>
              <img src={ShareIcon} alt="" />
            </div>
            <div className={classes["shareBackBtn"]} onClick={() => history.goBack()}>
              <img
                src={ArrowIcon}
                alt=""
                className={classes["arrowIcon"]}
                style={i18n.language === "en" ? { transform: "rotateY(0deg)" } : {}}
              />
            </div>
          </div>
          {openSharePopup && (
            <SharePopup handleCloseSharePopup={handleCloseSharePopup} course={course} isProgram={course.is_program} />
          )}
        </div>
      </div>

      <BasicModal ref={freeCourseModalRef}>
        {showFreeCourseModal && (
          <SharedModal
            title={t("trainer.course.enroll")}
            name={course.name}
            cancelText={t("general.cancel")}
            confirmMsg={t("trainer.course.enroll_free_course_msg")}
            confirmText={t("enroll")}
            onConfirm={joinFreeCourseAPI}
            onCancel={closeFreeCourseModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={redirectToLoginModalRef}>
        {showRedirectToLoginModal && (
          <WarningModal
            img={warning}
            label={t("general.alert")}
            description={course.is_program ? t("program_loginFirst") : t("course_loginFirst")}
            dismissText={t("general.dismiss")}
            dismissColor="#DF4B43"
            successText={t("general.login")}
            successColor="#26b3b9"
            action={() => {
              closeRedirectToLoginModal();
              history.push("/login");
            }}
            dismiss={closeRedirectToLoginModal}
          />
        )}
      </BasicModal>
    </div>
  );
}

import classes from "./progressBar.module.css";

export default function ProgressBar({ progress, title, color, icon }) {
  return (
    <div className={classes["progress-bar-container"]}>
      {icon && (
        <div className={classes["progress-icon"]}>
          <img src={icon} alt="icon" />
        </div>
      )}
      <div className={classes["progress-bar-wrapper"]}>
        <div className={classes["progress-info"]}>
          <div className={classes["progress-text"]}>{title}</div>
          <div className={classes["progress-percent"]}>{Number(progress) + "%"}</div>
        </div>
        <div className={classes["progress-bar"]}>
          <div className={classes["progress-bar-progress"] + " progress"}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: Number(progress) + "%",
                backgroundColor: color,
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import CourseCard from "../../Shared/Components/CourseCard/CourseCard";
import classes from "./trainersCourseDetails.module.css";

export default function TrainersCourseDetails({ trainerCourses, loading }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes["trainer-courses"]}>
      {!loading &&
        trainerCourses &&
        trainerCourses.map((course) =>
          course.program_id ? (
            <></>
          ) : (
            <div key={course.id} className={classes["course-card"]}>
              <CourseCard
                img={course.img}
                selfPaced={course.self_paced}
                lang={course.language}
                depName={
                  i18n.language === Lang.AR
                    ? course.department_name_ar
                    : course.department_name_en
                }
                title={course.name}
                mode={course.mode}
                price={course.price}
                date={course.start_date.split(" ")[0]}
                seat={
                  course.program_id
                    ? null
                    : `${course.participators} /${course.number_of_users}`
                }
                hour={course.number_hours}
                categoryName={course?.category?.name}
                levelbadge={
                  course.level === 1
                    ? t("levels.beginner")
                    : course.level === 2
                    ? t("levels.medium")
                    : course.level === 3
                    ? t("levels.professional")
                    : ""
                }
                handleSubmit={() =>
                  course.is_expired
                    ? history.push(
                        `/${
                          course.is_program
                            ? "training-programs"
                            : "training-courses"
                        }/${course.id}/content`
                      )
                    : history.push(
                        `/${
                          course.is_program
                            ? "training-programs"
                            : "training-courses"
                        }/${course.id}/details`
                      )
                }
                btn={t("programs.values.show_details")}
                //   userdata={true}
                //   userimg={course.trainer.img}
                //   username={course.trainer.name}
                //   userrate={3}
              />
            </div>
          )
        )}
    </div>
  );
}

import { useTranslation } from "react-i18next";
import NoEventsCalenderIcon from "../../../assets/icons/noEventsCalender.svg";
import classes from "./CalenderNew.module.css";
import EventCard from "./EventCard";

export default function DayEvents({ eventsOfDay, badgesLoading, eventTypes }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.dayEvents}>
      {eventsOfDay.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            color: "#9B9B9B",
            gap: "10px",
          }}
        >
          <img src={NoEventsCalenderIcon} alt="noEventsCalender" />
          <p>{t("no_event_msg")}</p>
        </div>
      ) : (
        <div className={classes.eventCardsContainer}>
          {eventsOfDay.map((event) => (
            <EventCard event={event} />
          ))}
        </div>
      )}
      <div className={classes.badgesCont}>
        {!badgesLoading &&
          eventTypes?.map((type) => (
            <>
              <div className={classes.badge} style={{ backgroundColor: type.color }}></div>
              <p className={classes.typeText}>{type.text}</p>
            </>
          ))}
      </div>
    </div>
  );
}

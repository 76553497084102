import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./SelectAddNew.module.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { Formik } from "formik";
import Input from "../../../Modules/Shared/Components/Input/Input";
import CustomButton from "../../../Modules/Shared/Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addFaqCategoryST,
  addFaqQuestionsST,
  editFaqQuestionST,
  getFaqCategoryTypeListST,
} from "../../../Redux/Actions/faq/faqSupportTickets.action";
import { faqSupportTicketsConstant } from "../../../Redux/Constants/faq/faqSupportTickets.constant";

const SelectAddNew = ({
  setShowModal,
  typeModal,
  title,
  edit,
  editID,
  categoryId,
  questionEN,
  questionAR,
  answerEN,
  answerAR,
  setList,
  label,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { addResponseST, addQuestionStatusST, editQuestionStatusST, typeList } =
    useSelector((state) => state.faqSupportTicketsReducer);

  const modalRef = React.useRef(null);

  const updateObjectById = (id, newObject) => {
    setList((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  useEffect(() => {
    if (addResponseST.status === 200 && addResponseST.data.status) {
      setShowModal(false);
      setList(addResponseST.data.data);
      dispatch({
        type: faqSupportTicketsConstant.GET_FAQ_QUESTION_RESET_ST,
      });
    }
  }, [addResponseST]);

  useEffect(() => {
    if (
      addQuestionStatusST?.status === 200 &&
      addQuestionStatusST?.data?.status
    ) {
      setShowModal(false);
      setList(addQuestionStatusST.data.data);
      dispatch({
        type: faqSupportTicketsConstant.GET_FAQ_QUESTION_RESET_ST,
      });
    }
  }, [addQuestionStatusST]);

  useEffect(() => {
    if (
      editQuestionStatusST?.status === 200 &&
      editQuestionStatusST?.data?.status
    ) {
      setShowModal(false);
      updateObjectById(editID, editQuestionStatusST.data.data);
      dispatch({
        type: faqSupportTicketsConstant.GET_FAQ_QUESTION_RESET_ST,
      });
    }
  }, [editQuestionStatusST]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    dispatch(getFaqCategoryTypeListST());
  }, []);

  useEffect(() => {
    setSelectData(typeList);
  }, [typeList]);

  return (
    <>
      <div className={classes["modal-overlay"]}>
        <div
          className={typeModal ? classes["modal-two-input"] : classes["modal"]}
          ref={modalRef}
        >
          <div className={classes["modal-header"]}>
            <h3>{title}</h3>
            <button
              className="close-button"
              onClick={setShowModal.bind(null, false)}
            >
              <HighlightOffIcon
                style={{ fontSize: 36, marginInline: 7, color: "#E62626" }}
              />
            </button>
          </div>

          <div>
            {typeModal ? (
              <Formik
                initialValues={{
                  ticket_type_id: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  values.ticket_type_id = +values.ticket_type_id;
                  try {
                    await dispatch(addFaqCategoryST(values));
                    setSubmitting(false);
                  } catch (err) {
                    setSubmitting(false);
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        <ul>
                          {Object.keys(err.response.data.errors).map((key) => {
                            return (
                              <li>
                                <span style={{ fontSize: 11 }}>
                                  {err.response.data.errors[key]}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.ticket_type_id) {
                    errors.ticket_type_id = t("Exhibition.errors.required");
                  }

                  if (Object.keys(errors).length === 0) {
                    setHasSubmitted(true);
                  } else {
                    setHasSubmitted(false);
                  }
                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({ errors, handleChange, handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes["m-30"]}>
                      <div className={classes["select-field"]}>
                        <label htmlFor="ticket_type_id">{label}</label>

                        <select
                          name="ticket_type_id"
                          onChange={handleChange}
                          value={values.ticket_type_id}
                          placeholder={initialValue}
                        >
                          <option value={initialValue} hidden>
                            {initialValue}
                          </option>
                          {selectData.map((option) => (
                            <option
                              value={option.id}
                              key={option.id}
                              name={option.name}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {errors && (
                          <p
                            className={`form-input-error-space ${classes["select-field-error"]}`}
                          >
                            {errors.ticket_type_id}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                    >
                      <CustomButton
                        value={t("FAQ.done")}
                        colors={"#036c77"}
                        type={"button"}
                        iconClass={"mt-2"}
                        action={handleSubmit}
                        classes={"button-width"}
                        loading={hasSubmitted}
                        disable={hasSubmitted}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  category_id: categoryId,
                  question: edit ? questionEN : "",
                  question_ar: edit ? questionAR : "",
                  answer: edit ? answerEN : "",
                  answer_ar: edit ? answerAR : "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    if (edit) {
                      dispatch(editFaqQuestionST(editID, values));
                    } else {
                      dispatch(addFaqQuestionsST(values));
                    }
                  } catch (err) {
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        <ul>
                          {Object.keys(err.response.data.errors).map((key) => {
                            return (
                              <li>
                                <span style={{ fontSize: 11 }}>
                                  {err.response.data.errors[key]}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  }
                  setSubmitting(false);
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.question) {
                    errors.question = t("Exhibition.errors.required");
                  }

                  if (!values.question_ar) {
                    errors.question_ar = t("Exhibition.errors.required");
                  }

                  if (values.question.length >= 190) {
                    errors.question = t("FAQ.errors.text190");
                  }

                  if (values.question_ar.length >= 190) {
                    errors.question_ar = t("FAQ.errors.text190");
                  }

                  if (values.answer.length >= 2500) {
                    errors.answer = t("FAQ.errors.text2500");
                  }

                  if (values.answer_ar.length >= 2500) {
                    errors.answer_ar = t("FAQ.errors.text2500");
                  }

                  if (!values.answer_ar) {
                    errors.answer_ar = t("Exhibition.errors.required");
                  }

                  if (!values.answer) {
                    errors.answer = t("Exhibition.errors.required");
                  }

                  if (Object.keys(errors).length === 0) {
                    setHasSubmitted(true);
                  } else {
                    setHasSubmitted(false);
                  }

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.question_title_in_arabic")}
                        type="text"
                        name="question_ar"
                        value={values.question_ar}
                        placeholder={t("FAQ.question_title_in_arabic")}
                        onChange={handleChange}
                        error={errors.question_ar}
                      />
                    </div>

                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.question_title_in_english")}
                        type="text"
                        name="question"
                        value={values.question}
                        placeholder={t("FAQ.question_title_in_english")}
                        onChange={handleChange}
                        error={errors.question}
                      />
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("FAQ.the_text_of_the_answer_arabic")}
                        </label>
                      </div>

                      <textarea
                        id="answer_ar"
                        name="answer_ar"
                        style={{
                          border: "1px solid rgb(201, 200, 200)",
                          display: "block",
                          width: "100%",
                          borderRadius: "6px",
                          padding: "15px",
                        }}
                        maxLength={250}
                        value={values.answer_ar}
                        onChange={handleChange}
                        placeholder={t("FAQ.the_text_of_the_answer_arabic")}
                        defaultValue={values.answer_ar}
                      />
                      {errors.answer_ar && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.answer_ar}
                        </p>
                      )}
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("FAQ.the_text_of_the_answer_english")}
                        </label>
                      </div>

                      <textarea
                        id="answer"
                        name="answer"
                        style={{
                          border: "1px solid rgb(201, 200, 200)",
                          display: "block",
                          width: "100%",
                          borderRadius: "6px",
                          padding: "15px",
                        }}
                        maxLength={250}
                        value={values.answer}
                        onChange={handleChange}
                        placeholder={t("FAQ.the_text_of_the_answer_english")}
                        defaultValue={values.answer}
                      />
                      {errors.answer && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.answer}
                        </p>
                      )}
                    </div>

                    <div
                      className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                    >
                      <CustomButton
                        value={t("FAQ.done")}
                        colors={"#036c77"}
                        type={"button"}
                        iconClass={"mt-2"}
                        action={handleSubmit}
                        classes={"button-width"}
                        loading={hasSubmitted}
                        disable={hasSubmitted}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectAddNew;

import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../../../../utils";
import Arrow from "../../../../../../assets/icons/ArrowDownBlue.svg";
import profileAvatar from "../../../../../../assets/image/profile-avatar.png";
import classes from "./UserDropDown.module.css";
export default function UserDropDown({ options, buttonContent }) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener("scroll", (event) => {
      handleClose();
    });
    return () => {
      document.removeEventListener("scroll", (event) => {
        handleClose();
      });
    };
  }, []);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Remove hover effect
          },
          "&::after": {
            backgroundColor: "transparent",
          },

          "&:active::after": {
            backgroundColor: "transparent",
          },
        }}
      >
        {localStorage.getItem("img") == "null" ? (
          <img src={profileAvatar} className={classes.admin_img} alt="" />
        ) : (
          <img
            src={localStorage.getItem("img")}
            alt=""
            className={classes.admin_img}
          />
        )}
        <p style={{ color: "#025299", fontSize: 14, marginInlineEnd: 5 }}>
          {i18n.language === Lang.AR
            ? localStorage.getItem("name_ar") !== null
              ? localStorage.getItem("name_ar").split(" ")[0].length > 12
                ? localStorage.getItem("name_ar").split(" ")[0].slice(0, 9) +
                  "..."
                : localStorage.getItem("name_ar").split(" ")[0]
              : null
            : localStorage.getItem("name_en") != null
            ? localStorage.getItem("name_en").split(" ")[0].length > 12
              ? localStorage.getItem("name_en").split(" ")[0].slice(0, 9) +
                "..."
              : localStorage.getItem("name_en").split(" ")[0]
            : null}
        </p>
        <img src={Arrow} alt="" style={{ width: 8, height: 8, marginTop: 4 }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
            style={{
              backgroundColor: "#fff",
              color: "#025299",
              fontFamily: "dinNextRegular",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

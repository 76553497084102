import React from "react";
import { useTranslation } from "react-i18next";
import {
  DOC_PDF,
  PNG_TYPE,
  acceptDocuments,
  acceptImages,
  fromKbToMb,
} from "../../../../../Services/Validator";
import Input from "../../Input/Input";
import InputFile from "../../InputFile/InputFile";
import PhoneInput from "../../PhoneInput/PhoneInput";
import style from "./bankTransfer.module.css";

function BankTransfer({
  handleChange,
  errors,
  setFieldValue,
  onSubmit,
  getTypeFile,
  userWallet,
  hasSubmitted,
}) {
  const { t } = useTranslation();

  const acceptFiles = () => {
    return `${acceptImages()},${acceptDocuments()}`;
  };

  return (
    <>
      {/* <div className={`${style["input-container"]} ${style["mt-25px"]}`}> */}
      {/* <div className={style.input}>
          <Input
            name="account_holder_name"
            label={t("Exhibition.popuppaymentaccountname")}
            placeholder={t("Exhibition.popuppaymentaccountname")}
            type="text"
            onChange={handleChange}
            error={errors.account_holder_name}
          />
        </div> */}
      {/* <div className={style.input}>
          <PhoneInput
            label={t("Exhibition.popupphone")}
            type="number"
            nameInput="phone_number"
            nameSelect="code"
            placeholder={t("Exhibition.popupphone")}
            onChange={handleChange}
            error={errors.phone_number}
            min={0}
          />
        </div> */}
      {/* </div> */}
      {/* <div className={style["input-container"]}>
        <div className={style.input}>
          <Input
            name="bank_name"
            label={t("Exhibition.popuppaymentbankname")}
            placeholder={t("Exhibition.popuppaymentbankname")}
            type="text"
            onChange={handleChange}
            error={errors.bank_name}
          />
        </div>
        <div className={style.input}>
          <Input
            name="iban_number"
            label={t("Exhibition.popuppaymentIBANname")}
            placeholder={t("Exhibition.popuppaymentIBANname")}
            type="text"
            onChange={handleChange}
            error={errors.iban_number}
          />
        </div>
      </div> */}
      {userWallet && (
        <div className={style["input-container"]}>
          <div className={style.input}>
            <Input
              name="amount"
              label={t("Exhibition.amount")}
              placeholder={t("Exhibition.amount")}
              type="number"
              onChange={handleChange}
              error={errors.amount}
              min={1}
            />
          </div>
        </div>
      )}

      {/* <div className={`${style.input} ${style["popup-font-size"]}`}>
        <InputFile
          name="payment_proof_img_name"
          type="gallery"
          accept={acceptFiles()}
          label={t("Exhibition.popuppaymentupload")}
          maxSize={
            getTypeFile
              ? `${t("Exhibition.upload_max_size")} ${Math.floor(
                  getTypeFile([PNG_TYPE, DOC_PDF])[0]?.max_size > getTypeFile([PNG_TYPE, DOC_PDF])[1]?.max_size
                    ? fromKbToMb(getTypeFile([PNG_TYPE, DOC_PDF])[0]?.max_size)
                    : fromKbToMb(getTypeFile([PNG_TYPE, DOC_PDF])[1]?.max_size)
                )} ${t("booth.megabyte")}`
              : t("Exhibition.upload_max_size") + " 10 " + t("booth.megabyte")
          }
          setFieldValue={setFieldValue}
          note={t("general.png_pdf_note")}
          onChange={handleChange}
          error={errors.bank_transfer_image}
        />
      </div> */}
      <div className={style["btn-paymentway"]}>
        <button
          type="submit"
          disabled={hasSubmitted}
          style={{
            width: "100%",
            height: 50,
            padding: "4px",
            borderRadius: "6px",
            display: "inline-block",
          }}
          className="admin_add_button btn_zoom"
          onClick={onSubmit}
        >
          {t("payments.pay")}
        </button>
      </div>
    </>
  );
}

export default BankTransfer;

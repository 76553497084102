// import Select from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PasswordInput from "../../../Components/Input/PasswordInput";
import ValidatorInput from "../../../Components/Input/ValidatorInput";
import { SpinnerContext } from "../../../Components/Spinner/SpinnerContext";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { register } from "../../../Services/api/auth/AuthProvider";
import { getCountries } from "../../../Services/api/profile/profileProvider";
import { FooterContext } from "../../../Services/api/toutorials/FooterContext";
import Trainer from "../../../assets/icons/Teacher.svg";
import Trainee from "../../../assets/icons/Trainer.svg";
import Logo from "../../../assets/image/Qassem_logo.svg";
import i18n from "../../../i18n/i18n";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";

function Register() {
  require("./register.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  const spinnerContext = useContext(SpinnerContext);
  const footerCtx = useContext(FooterContext);
  const authContext = useContext(AuthContext);

  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [nameEnError, setNameEnError] = useState("");
  const [nameArError, setNameArError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conPasswordError, setConPasswordError] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesId, setCountriesId] = useState(0);
  const [countryError, setCountryError] = useState("");
  const [role, setRole] = useState(1);
  const [roleSelected, setRoleSelected] = useState(true);
  /** TODO: */
  const history = useHistory();
  /** */

  useEffect(() => {
    footerCtx.setFooter(false);
    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  const nameEnHandleChange = (event) => {
    setNameEn(event.target.value);
  };

  const nameArHandleChange = (event) => {
    setNameAr(event.target.value);
  };

  const confirmPasswordHandleChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      spinnerContext.setShowSpinner(false);
      authContext.setIsPerformingAuthenticationOperation(false);
      setconfirmPasswordError(t("confirmPasswordError"));
    } else {
      setconfirmPasswordError("");
    }
  };

  const emailHandleChange = (email) => {
    setEmail(email);
  };

  const passwordHandleChange = (password) => {
    setPassword(password);
    if (checkPasswordValidation(password) !== null) {
      spinnerContext.setShowSpinner(false);
      authContext.setIsPerformingAuthenticationOperation(false);
      setPasswordError(checkPasswordValidation(password));
    } else {
      setPasswordError(null);
    }
  };

  function checkPasswordValidation(pass) {
    if (pass.length === 0) {
      return t("password_validation.empty");
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(pass)) {
      return t("password_validation.uppercase");
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(pass)) {
      return t("password_validation.lowercase");
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(pass)) {
      return t("password_validation.digit");
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(pass)) {
      return t("password_validation.symbol");
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(pass)) {
      return t("password_validation.long");
    }
    return null;
  }

  function validate() {
    let isValid = true;

    if (!nameEn) {
      isValid = false;
      setNameEnError(t("crud.errors.required"));
    } else if (nameEn.length > 50) {
      setNameEnError(t("crud.errors.maxlength"));
    } else {
      setNameEnError("");
    }

    if (!nameAr) {
      isValid = false;
      setNameArError(t("crud.errors.required"));
    } else if (nameAr.length > 50) {
      setNameArError(t("crud.errors.maxlength"));
    } else {
      setNameArError("");
    }

    if (!email) {
      isValid = false;
      setEmailError(t("crud.errors.required"));
    } else if (email.length > 50) {
      setEmailError(t("crud.errors.maxlength"));
    } else {
      setEmailError("");
    }

    if (!password) {
      isValid = false;
      setPasswordError(t("crud.errors.required"));
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      isValid = false;
      setConPasswordError(t("crud.errors.required"));
    } else {
      setConPasswordError("");
    }

    const checkPassword = checkPasswordValidation(password);
    if (checkPassword !== null) {
      isValid = false;
      setPasswordError(checkPassword);
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      isValid = false;
      setconfirmPasswordError(t("confirmPasswordError"));
    } else {
      setconfirmPasswordError("");
    }

    if (email && !validateEmail(email)) {
      isValid = false;
      setEmailError(t("email_format"));
    } else {
      setEmailError("");
    }

    return isValid;
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    authContext.setIsPerformingAuthenticationOperation(true);
    spinnerContext.setShowSpinner(true);
    event.preventDefault();

    if (validate()) {
      try {
        let response = await register({
          nameEn,
          nameAr,
          email,
          password,
          confirmPassword,
          role,
        });
        if (response.status === 201 && response.data.status) {
          spinnerContext.setShowSpinner(false);
          authContext.setIsPerformingAuthenticationOperation(false);
          history.push({
            pathname: "/email-verification",
            state: {
              email: email,
            },
          });
        } else {
          alert("Failure");
        }
      } catch (err) {
        if (err.response.data.errors) {
          setNameArError(err.response.data && err.response.data.errors.name_ar);
          setNameEnError(err.response.data && err.response.data.errors.name_en);
          setEmailError(err.response.data && err.response.data.errors.email);
          setPasswordError(err.response.data && err.response.data.errors.password);
          setConPasswordError(err.response.data && err.response.data.errors.password_confirmation);
          // TODO:
          setCountryError(err.response.data && err.response.data.errors.country_id);
          authContext.setIsPerformingAuthenticationOperation(false);
        } else {
          console.log(err.response.data.message);
          authContext.setIsPerformingAuthenticationOperation(false);
        }
        // authContext.setIsPerformingAuthenticationOperation(false);
      }
    } else {
      if (!validateEmail(email)) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setEmailError(t("email_format"));
      } else {
        setEmailError(null);
      }
      if (checkPasswordValidation(password) !== null) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setPasswordError(checkPasswordValidation(password));
      } else {
        setPasswordError(null);
      }
      if (countriesId === 0) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setCountryError(t("select_your_country_error"));
      } else {
        setCountryError(null);
      }
    }
    spinnerContext.setShowSpinner(false);
  };

  function handleLoading() {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  }

  let spinnerLoading = "";
  if (disabled === true) {
    spinnerLoading = <div className="lds-dual-ring"></div>;
  } else {
    spinnerLoading = "";
  }

  const handleSelection = () => {
    // console.log(role, roleSelected);
    if (role !== 3 && role !== 4) {
      return;
    }
    setRoleSelected(false);
  };

  return (
    <>
      {roleSelected ? (
        <div className="auth_forms_reg">
          <div className="auth_form_flex_col auth_form_form">
            <div className="auth_label_registar">
              <p className="auth_label">{t("RegisteraNewUser")}</p>
              <span>{t("login_note")}</span>
            </div>
            <div className="trainer_trainee_box">
              <div className="Trainer_trainee_cont" onClick={() => setRole(3)}>
                <img src={Trainer} alt="" className="Trainer_traine_icon" />
                <p>{t("selection.role.trainer")}</p>
                <div className="select_cont">
                  <div className={role === 3 ? "slected" : "unselected"}></div>
                </div>
              </div>
              <div className="Trainer_trainee_cont" onClick={() => setRole(4)}>
                <img src={Trainee} alt="" className="Trainer_traine_icon" />
                <p>{t("selection.role.trainee")}</p>
                <div className="select_cont">
                  <div className={role === 4 ? "slected" : "unselected"}></div>
                </div>
              </div>
            </div>
            <CustomButton
              value={t("next")}
              type="button"
              variant="contained"
              colors="#126d77"
              classes="auth_btn"
              action={handleSelection}
            />
          </div>
        </div>
      ) : (
        <div className="auth_forms_reg">
          <div className="auth_form_flex_col">
            <div className="auth-home-login">
              <img src={Logo} alt="" className={"logo_qassem_login"} />
            </div>
            <p className="auth_label">{t("RegisteraNewUser")}</p>
            <form onSubmit={handleSubmit} className="auth_form_flex_col auth_form_form">
              <div className="input-name-register">
                <div>
                  <Input
                    type="input"
                    value={nameEn}
                    onChange={nameEnHandleChange}
                    className="auth_input"
                    required
                    placeholder={t("nameEn")}
                    label={t("nameEn")}
                  />
                  <div className="text-danger" style={{ fontSize: "13px", height: "10px" }}>
                    {nameEnError && nameEnError}
                  </div>
                </div>
                <div>
                  <Input
                    type="input"
                    value={nameAr}
                    onChange={nameArHandleChange}
                    className="auth_input"
                    required
                    placeholder={t("nameAr")}
                    label={t("nameAr")}
                  />
                  <div className="text-danger" style={{ fontSize: "13px", height: "10px" }}>
                    {nameArError && nameArError}
                  </div>
                </div>
              </div>
              <div>
                <ValidatorInput
                  setContent={emailHandleChange}
                  validationParams="email"
                  setRequired={true}
                  type="email"
                  placeholder={t("Email")}
                  label={t("Email")}
                />
                <div className="text-danger" style={{ fontSize: "13px", height: "10px" }}>
                  {emailError && emailError}
                </div>
              </div>
              <div className="password_box">
                <label htmlFor="password" style={{ margin: 0 }}>
                  <PasswordInput setContent={passwordHandleChange} setRequired={true} />
                  <div className="text-danger" style={{ fontSize: "13px", height: "10px" }}>
                    {passwordError && passwordError}
                  </div>
                </label>
                <label htmlFor="confirmPassword" style={{ margin: 0 }}>
                  <PasswordInput setContent={confirmPasswordHandleChange} setRequired={true} confirmation />
                  <div className="text-danger" style={{ fontSize: "13px", height: "10px" }}>
                    {confirmPasswordError && confirmPasswordError}
                  </div>
                </label>
              </div>
              <CustomButton
                value={t("CreateAnAccount")}
                type="submit"
                variant="contained"
                disable={disabled}
                loading={authContext.isPerformingAuthenticationOperation}
                colors="#126d77"
                classes="auth_btn"
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;

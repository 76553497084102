import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import BurgerMenu from "../../../../assets/image/MenuQassem.png";
import Qassem_logo from "../../../../assets/image/Qassem_logo.svg";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import DynamicHeaderWithLogin from "./DynamicHeaderWithLogin";
import DynamicHeaderWithOutLogin from "./DynamicHeaderWithoutLogin/DynamicHeaderWithOutLogin";
import classes from "./Links.module.css";
import MainDrawer from "./MainDrawer/MainDrawer";

function Links({setShowSearch}) {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  document.body.dir = i18n.dir();

  const [showDrawer, setShowDrawer] = useState(false);
  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const toggleDrawerHandler = (event) => {
    event.preventDefault();
    setShowDrawer((prev) => !prev);
  };

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setShowDrawer(false);
    }, 300);
  };

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDrawer]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  return (
    <div className={classes["header_shadow"]}>
      {showDrawer && (
        <>
          <MainDrawer
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={authContext.roles}
          />
          <div className={classes.backdrop_main} onClick={onConfirm} />
        </>
      )}
      <div
        className="container-fluid"
        style={{
          padding: "0 5px",
          maxWidth: "1450px",
          paddingInlineStart: "12px",
        }}
      >
        <div className="row">
          <div className="col-12">
            <nav className={classes.mainNav}>
              <div className={classes.navbar_logo_main}>
                {width <= 1200 && (
                  <div
                    className={classes["hamburger-pointer"]}
                    onClick={toggleDrawerHandler}
                    style={{
                      backgroundColor: "#03a2b2",
                      borderRadius: "6px",
                      height: "40px",
                    }}
                  >
                    <img
                      src={BurgerMenu}
                      alt=""
                      style={{
                        transform: `${i18n.language === "ar" && "rotateY(180deg)"}`,
                        width: 40,
                        minWidth: 40,
                        height: 40,
                        minHeight: 40,
                      }}
                    />
                  </div>
                )}
                <NavLink exact to="/">
                  <img className={classes.image_logo_new} src={Qassem_logo} alt="..." />
                </NavLink>
                {width > 1200 && (
                  <>
                    <NavLink to="/" className="nav-item nav-link header_nav_links" exact>
                      {t("Main")}
                    </NavLink>
                    <NavLink to="/training-courses" className="nav-item nav-link header_nav_links">
                      {t("courses")}
                    </NavLink>
                    <NavLink to="/training-programs" className="nav-item nav-link header_nav_links">
                      {t("program")}
                    </NavLink>
                    <NavLink to="/trainers" className="nav-item nav-link header_nav_links">
                      {t("Trainers")}
                    </NavLink>
                    {!!localStorage.getItem("token") && (
                      <NavLink to="/general-surveys" className="nav-item nav-link header_nav_links">
                        {t("sidebar.label.generalSurveys")}
                      </NavLink>
                    )}
                    <NavLink to="/about-us" className="nav-item nav-link header_nav_links">
                      {t("AboutUs")}
                    </NavLink>
                    <NavLink to="/contactUs" className="nav-item nav-link header_nav_links">
                      {t("ConnectUs")}
                    </NavLink>
                    <div
                      className="nav-item nav-link header_nav_links"
                      onClick={() => changeLanguage(i18n.language === "ar" ? "en" : "ar")}
                    >
                      {i18n.language === "ar" ? "EN" : "العربية"}
                    </div>
                  </>
                )}
              </div>

              <div className={classes.flex_menu}>
                {!authContext.auth.token ? <DynamicHeaderWithLogin setShowSearch={setShowSearch} /> : <DynamicHeaderWithOutLogin setShowSearch={setShowSearch}/>}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../../../../Services/api/auth/AuthContext";
import SearchIcon from "../../../../../assets/icons/searchIconHome.svg";
import i18n from "../../../../../i18n/i18n";
import Notification from "../../../../Admin/adminHeader/Notification";
import UserDropDown from "./UserDropDwon/UserDropDown";

function DynamicHeaderWithOutLogin(props) {
  require("./DynamicHeaderWithoutLogin.css");
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  document.body.dir = i18n.dir();
  const root = history.location.pathname.split("/")[1];

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("type_login");
    localStorage.removeItem("chatId");
    localStorage.removeItem("userId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("group");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
    window.location.reload();
  };

  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : authContext.roles.includes("group-admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/group-admin"),
          },
        ]
      : authContext.roles.includes("manager")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/manager"),
          },
        ]
      : []),

    {
      label: t("selectGroups"),
      onClick: () => history.push("/my-groups"),
    },
    ...(authContext.roles.includes("admin") || authContext.roles.includes("accountant")
      ? []
      : [
          {
            label: t("account_info"),
            onClick: () => history.push("/private-profile"),
          },
        ]),
    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    {
      label: t("logout"),
      onClick: logout,
    },
  ];

  return (
    <div className="header_dinamic">
      <Notification NotAdmin={props.NotAdmin} />
      <UserDropDown options={userOptions} />
      {!root && (
        <div className="header_search" onClick={() => props.setShowSearch((prev) => !prev)}>
          <img src={SearchIcon} alt="search" />
        </div>
      )}
    </div>
  );
}

export default withRouter(DynamicHeaderWithOutLogin);

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ArrowLeft from "../../../assets/icons/arrowLeftRoundWhite.svg";
import ArrowRight from "../../../assets/icons/arrowRightRoundWhite.svg";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import classes from "./PublishSection.module.css";

export default function PublishSection() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className="container-fluid">
      <div className={classes.publishContainer}>
        <div className={classes.publishContent}>
          <div className={classes.publishText}>
            <p>{t("publish_section_title")}</p>
            <span>{t("publish_section_subtitle")}</span>
          </div>
          <div>
            <CustomButton
              type="button"
              icon={i18n.language === "ar" ? ArrowLeft : ArrowRight}
              value={t("course_publish_request")}
              classes={classes.publishButton}
              action={() => history.push("/publish-request")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

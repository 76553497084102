import { LegendToggle } from "@mui/icons-material";
import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { coursesListConstant } from "../../Constants/courses/coursesList.constants";

// GET COURSES LIST
export const fetchCoursesList = ({
  page = 1,
  perPage = 10,
  filter,
  role,
  programId,
  programCourses,
}) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_COURSES_LIST_REQUEST,
    });

    await connector
      .get(URLBuilder(env.courses_list, { role }), {
        params: {
          page,
          perPage,
          program_id: programId,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          let coursesList = response.data.data.courses.data;
          if (programCourses) {
            coursesList = coursesList
              .map((course, index) => ({ ...course, index }))
              .sort((a, b) => b.index - a.index);
          }
          dispatch({
            type: coursesListConstant.GET_COURSES_LIST_SUCCESS,
            payload: {
              courses: {
                data: coursesList,
                meta: response.data.data.courses.meta,
              },
              trainers: response.data.data.courses.trainers,
              statuses: response.data.data.courses.statuses,
              modes: response.data.data.courses.modes,
              categories: response.data.data.courses.categories,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSES_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_COURSES_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const fetchPartnerCoursesList = ({ status, type, perPage, page }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_PARTNER_COURSES_LIST_REQUEST,
    });

    await connector
      .get(URLBuilder(env.partner_courses_list, { type: type }), {
        params: {
          status: status,
          perPage: perPage,
          page: page,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response.data);
          dispatch({
            type: coursesListConstant.GET_PARTNER_COURSES_LIST_SUCCESS,
            payload: response.data[type],
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_PARTNER_COURSES_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_PARTNER_COURSES_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const fetchAllCoursesList = () => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_ALL_COURSES_LIST_REQUEST,
    });

    await connector
      .get(env.all_courses_list, {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: coursesListConstant.GET_ALL_COURSES_LIST_SUCCESS,
            payload: response.data.data.data,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_ALL_COURSES_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_ALL_COURSES_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// UPDATE COURSE STATUS
export const updateCourseStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.UPDATE_COURSE_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.update_course_visibility, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.UPDATE_COURSE_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.UPDATE_COURSE_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.UPDATE_COURSE_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

export const deleteCourse = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.DELETE_COURSE_REQUEST,
    });

    await connector
      .delete(
        URLBuilder(env.delete_course, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.DELETE_COURSE_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.DELETE_COURSE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        // console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg}
          </span>
        );
        dispatch({
          type: coursesListConstant.DELETE_COURSE_REJECTED,
          payload: err,
        });
      });
  };
};

export const addCourseIntroVideo = (data, modalStatus) => {
  return (dispatch) => {
    dispatch({ type: coursesListConstant.ADD_INTRO_VIDEO_REQUEST });

    connector
      .post(env.upload_intro_video, data)
      .then((response) => {
        dispatch({
          type: coursesListConstant.ADD_INTRO_VIDEO_SUCCESS,
          payload: response?.data,
        });
        modalStatus(false);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg}
          </span>
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg}
          </span>
        );
        dispatch({
          type: coursesListConstant.ADD_INTRO_VIDEO_REJECTED,
          payload: err,
        });
      });
  };
};

// GET PROGRAM PAYMENT LIST
export const fetchProgramPaymentList = (id) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_PROGRAM_PAYMENT_LIST_REQUEST,
    });

    await connector
      .get(URLBuilder(env.program_payment_list, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.GET_PROGRAM_PAYMENT_LIST_SUCCESS,
            payload: response.data.payments,
          });
        } else {
          console.log("err1");
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_PROGRAM_PAYMENT_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_PROGRAM_PAYMENT_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// ADD PROGRAM PAYMENT
export const addProgramPayment = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.ADD_PROGRAM_PAYMENT_REQUEST,
    });

    await connector
      .post(env.add_program_payment, payload)
      .then((response) => {
        if (response?.status === 201 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.ADD_PROGRAM_PAYMENT_SUCCESS,
            payload: response?.data,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.ADD_PROGRAM_PAYMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: coursesListConstant.ADD_PROGRAM_PAYMENT_REJECTED,
          payload: err,
        });
      });
  };
};

// UPDATE PROGRAM PAYMENT
export const updateProgramPayment = ({ id, data }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.UPDATE_PROGRAM_PAYMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.update_program_payment, { id }), data)
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: coursesListConstant.UPDATE_PROGRAM_PAYMENT_SUCCESS,
            payload: response?.data.payment,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.UPDATE_PROGRAM_PAYMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: coursesListConstant.UPDATE_PROGRAM_PAYMENT_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE PROGRAM PAYMENT
export const deleteProgramPayment = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.DELETE_PROGRAM_PAYMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.delete_program_payment, { id }))
      .then((response) => {
        console.log("response", response);
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.DELETE_PROGRAM_PAYMENT_SUCCESS,
            payload: id,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.DELETE_PROGRAM_PAYMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.DELETE_PROGRAM_PAYMENT_REJECTED,
          payload: err,
        });
      });
  };
};

// GET COURSE DETAILS
export const fetchCourseDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_COURSE_DETAILS_REQUEST,
    });

    await connector
      .get(URLBuilder(env.get_course_details, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: coursesListConstant.GET_COURSE_DETAILS_SUCCESS,
            payload: response.data.course,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSE_DETAILS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_COURSE_DETAILS_REJECTED,
          payload: err,
        });
      });
  };
};

// GET COURSE TREE
export const fetchCourseTree = (id) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_COURSE_TREE_REQUEST,
    });

    await connector
      .get(URLBuilder(env.get_course_tree, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: coursesListConstant.GET_COURSE_TREE_SUCCESS,
            payload: response.data.response,
          });
        } else if (response?.status !== 205) {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSE_TREE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        // if 205 then no content
        if (err?.response?.status !== 205) {
          dispatch({
            type: coursesListConstant.GET_COURSE_TREE_REJECTED,
            payload: err,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err?.response?.data?.message}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSE_TREE_REJECTED,
            payload: err,
          });
        }
      });
  };
};

// sendNotificationToGiftSender
export const sendNotificationToGiftSender = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_REQUEST,
    });

    console.log("id", id);
    await connector
      .post(URLBuilder(env.send_notification_to_gift_sender, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_SUCCESS,
            payload: response.data.response,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_REJECTED,
          payload: err,
        });
      });
  };
};

// GET COURSE SUBCHAPTER DETAILS
export const fetchCourseSubChapterDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_REQUEST,
    });

    await connector
      .get(URLBuilder(env.get_course_subchapter_details, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_SUCCESS,
            payload: response.data.chapter,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_COURSE_SUBCHAPTERS_DETAILS_REJECTED,
          payload: err,
        });
      });
  };
};

// GET COURSE LESSON DETAILS
export const fetchCourseLessonDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.GET_COURSE_LESSON_DETAILS_REQUEST,
    });

    await connector
      .get(URLBuilder(env.get_course_lesson_details, { id }), {})
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.GET_COURSE_LESSON_DETAILS_SUCCESS,
            payload: {
              zoomClassRoomList: response.data.data.meetings,
              lessonDiscussions: response.data.data.discussions,
              lessonContent: response.data.data.contents,
              lessonActivities: response.data.data.activities,
              lessonQuiz: response.data.data.quiz_list,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: coursesListConstant.GET_COURSE_LESSON_DETAILS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: coursesListConstant.GET_COURSE_LESSON_DETAILS_REJECTED,
          payload: err,
        });
      });
  };
};

// SAVE WATCHING PROGRESS
export const saveWatchingProgress = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.SAVE_WATCHING_PROGRESS_REQUEST,
    });

    await connector
      .post(env.save_watching_progress, payload)
      .then((response) => {
        if (response?.status === 201 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.SAVE_WATCHING_PROGRESS_SUCCESS,
            payload: payload,
          });
        } else {
          dispatch({
            type: coursesListConstant.SAVE_WATCHING_PROGRESS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        dispatch({
          type: coursesListConstant.SAVE_WATCHING_PROGRESS_REJECTED,
          payload: err,
        });
      });
  };
};
export const addNote = (data, id, model) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.ADD_NOTE_TO_LESSON_REQUEST,
    });

    await connector
      .post(URLBuilder(env.add_note, { model: model }), {
        notable_id: id,
        note: data,
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.ADD_NOTE_TO_LESSON_SUCCESS,
            payload: {
              id: id,
              note: response.data.data,
              notable_type: model,
            },
          });
        } else {
          dispatch({
            type: coursesListConstant.ADD_NOTE_TO_LESSON_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        dispatch({
          type: coursesListConstant.ADD_NOTE_TO_LESSON_REJECTED,
          payload: err,
        });
      });
  };
};
export const editNote = (data, id, lessonId, notable_type) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.EDIT_NOTE_TO_LESSON_REQUEST,
    });

    await connector
      .post(URLBuilder(env.add_note, { model: id }), {
        _method: "PUT",
        note: data,
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: coursesListConstant.EDIT_NOTE_TO_LESSON_SUCCESS,
            payload: {
              id: id,
              note: response.data.data,
              lessonId: lessonId,
              notable_type: notable_type,
            },
          });
        } else {
          dispatch({
            type: coursesListConstant.EDIT_NOTE_TO_LESSON_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        dispatch({
          type: coursesListConstant.EDIT_NOTE_TO_LESSON_REJECTED,
          payload: err,
        });
      });
  };
};
export const deleteNote = (id, lessonId, notable_type) => {
  return async (dispatch) => {
    dispatch({
      type: coursesListConstant.DELETE_NOTE_TO_LESSON_REQUEST,
    });

    await connector
      .delete(URLBuilder(env.add_note, { model: id }))
      .then((response) => {
        console.log(id, lessonId, notable_type);
        if (response?.status === 200) {
          dispatch({
            type: coursesListConstant.DELETE_NOTE_TO_LESSON_SUCCESS,
            payload: {
              id: id,
              lessonId: lessonId,
              notable_type: notable_type,
              note: [],
            },
          });
        } else {
          dispatch({
            type: coursesListConstant.DELETE_COURSE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        dispatch({
          type: coursesListConstant.DELETE_NOTE_TO_LESSON_REJECTED,
          payload: err,
        });
      });
  };
};

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ArrowLeft from "../../../assets/icons/arrowLeftRoundWhite.svg";
import ArrowRight from "../../../assets/icons/arrowRightRoundWhite.svg";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import classes from "./FollowDream.module.css";

export default function FollowDream() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div className="container-fluid" style={{ marginBottom: "50px" }}>
      <div className={classes.mainClass}>
        <p className={classes.dreams}>{t("follow_dreams")}</p>
        <CustomButton
          value={t("enroll")}
          classes={classes.btnRegister}
          icon={i18n.language === "ar" ? ArrowLeft : ArrowRight}
          action={() => (localStorage.type ? history.push("/training-courses") : history.push("/register"))}
        />
      </div>
    </div>
  );
}

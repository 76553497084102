import { useTranslation } from "react-i18next";
import calenderIcon from "../../../../assets/icons/calendar_course_card.svg";
import usersIcon from "../../../../assets/icons/users_course_card.svg";
import clockIcon from "../../../../assets/icons/clock_course_card.svg";
import eyeIcon from "../../../../assets/icons/eye_course_card.svg";
import bookIcon from "../../../../assets/icons/book_course_card.svg";
import i18n from "../../../../i18n/i18n";
import Title from "../Title/Title";
import classes from "./courseCard.module.css";

const CourseCard = ({
  img,
  title,
  price,
  date,
  seat,
  hour,
  levelbadge,
  depName,
  categoryName,
  lang,
  handleSubmit,
  expired,
  level,
  selfPaced,
  isCurrentCourses,
  progressValue = 0,
  watchValue = 0,
  mode,
  home,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classes.card}
      style={home ? { margin: "0 10px" } : { width: "100%" }}
      onClick={() => {
        // if (!expired) {
        handleSubmit();
        // }
      }}
    >
      <div className={classes["header-content"]}>
        <div className={classes["img-container"]}>
          {selfPaced && (
            <div className={classes["img-badge"]}>
              <div className={classes.self_pacedBadge}>{t("open_course")}</div>
            </div>
          )}
          {/* <div className={classes["img-badge"]}>
            {isCurrentCourses || isClosed ? null : (
              <>
                <div className={classes["price-container"]}>
                  {offer ? (
                    <span className={classes["old-price"]}>
                      {price} {t("rs")}
                    </span>
                  ) : null}
                  <label className={classes.price}>
                    {mode === "Paid" ? (
                      <>
                        {offer ? null : (
                          <p className={classes["price-label"]}>
                            {t("general.price")}
                          </p>
                        )}
                        <span className={classes["price-number"]}>
                          {offer
                            ? Number(offer.mechanism) === 1
                              ? Number(price) - Number(offer.value)
                              : Number(price) -
                                (Number(price) * Number(offer.value)) / 100
                            : price}
                        </span>{" "}
                        <span className={classes.cur}>{t("rs")}</span>
                      </>
                    ) : (
                      <>
                        <p className={classes["price-label"]}>
                          {t("general.free")}
                        </p>
                        <span className={classes["price-number"]}>
                          0.00 <span className={classes.cur}>{t("rs")}</span>
                        </span>
                      </>
                    )}
                  </label>
                </div>
                {offer && (
                  <div className={classes["offer-badge"]}>
                    <span className={classes["offer-badge-off"]}>
                      {t("general.off")}
                    </span>
                    <span className={classes["offer-badge-number"]}>
                      {offer.value +
                        " " +
                        (Number(offer.mechanism) === 1 ? t("general.rs") : "%")}
                    </span>
                  </div>
                )}
              </>
            )}
          </div> */}
          <img src={img} alt="course" className={classes.img} />
        </div>
      </div>

      <div className={classes["bottom-content"]}>
        <div className={classes.title_cat_dep}>
          <div>
            <p style={{ color: "#03A2B2", fontSize: "12px", fontWeight: 500 }}>
              {categoryName && `${categoryName} - `}
              {depName && `${depName} `}
            </p>
          </div>
          <div className={classes["title-price-container"]}>
            <Title
              title={`${title?.slice(0, 28)} ${title.length > 28 ? "..." : ""}`}
              fontSize={18}
              titleColor="#000"
            />
            {/* <div className={classes["desc-container"]}>{briefDesc}</div> */}
          </div>
        </div>
        <div className={classes.lang_level}>
          {lang && (
            <div className={classes.lang}>
              <p>
                {t("general.language")} : <span>{t(`language.${lang}`)}</span>
              </p>
            </div>
          )}
          {levelbadge && (
            <div className={classes.level}>
              <p>
                {t("level")} : <span>{levelbadge}</span>
              </p>
            </div>
          )}
        </div>

        <>
          {isCurrentCourses ? (
            <>
              <div className={classes["progress-container"]}>
                <div className={classes.progress_icon}>
                  <img src={bookIcon} alt="book" />
                </div>
                <div className={classes.progress_bar_title}>
                  <div className={classes.progress_title}>
                    <h3>{t("achivementProgress")}</h3>
                    <p>{progressValue}%</p>
                  </div>
                  <div className={classes["progress"]}>
                    <div
                      className={classes["progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${progressValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              </div>
              <div className={classes["progress-container"]}>
                <div className={classes.progress_icon}>
                  <img src={eyeIcon} alt="eye" />
                </div>
                <div className={classes.progress_bar_title}>
                  <div className={classes.progress_title}>
                    <h3>{t("watchingProgressTwo")}</h3>
                    <p>{watchValue}%</p>
                  </div>

                  <div className={classes["progress"]}>
                    <div
                      className={classes["watch-progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${watchValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>

        <div className={classes.details_price}>
          <div className={classes["date-seat-hour"]}>
            {seat && (
              <span className={classes.iconscontainer}>
                <img src={usersIcon} alt="" /> {seat}
              </span>
            )}
            <span className={classes.iconscontainer}>
              <img src={clockIcon} alt="" /> {hour}
            </span>
            {!selfPaced && (
              <span className={classes.iconscontainer}>
                <img src={calenderIcon} alt="" /> {date}
              </span>
            )}
          </div>
          <div
            className={`${classes.price} ${
              mode === "Free" && classes.freeMode
            }`}
          >
            {mode !== "Free" ? (
              <p>
                {price} <span>{t("rs")}</span>
              </p>
            ) : (
              <p>{t("free")}</p>
            )}
          </div>
        </div>
        {/* <CustomButton
          value={btn}
          colors={"#03A2B2"}
          type={"button"}
          action={() => handleSubmit()}
          disable={expired}
          classes={classes.btn}
        /> */}
      </div>
    </div>
  );
};

export default CourseCard;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";
import ArrowLeft from "../../../assets/icons/arrowLeftRoundWhite.svg";
import ArrowRight from "../../../assets/icons/arrowRightRoundWhite.svg";
import Img from "../../../assets/image/SliderPic.jpeg";
import { getAdHome } from "../../../Services/api/HomeCoursesProvider";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainSectionCarousel from "../../Shared/Components/MainSectionCarousel/MainSectionCarousel";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import classes from "./mainSection.module.css";
const MainSection = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [adList, setAdList] = useState([]);
  const [adListLoading, setAdListLoading] = useState(true);

  useEffect(() => {
    const apiCall = getAdHome;

    apiCall()
      .then((res) => {
        if (res.status && res.status === 200) {
          setAdList(i18n.language === "ar" ? res.data.data.reverse() : res.data.data);
          setAdListLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdListLoading(false);
      });
  }, []);

  return adListLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <div
        style={{
          height: "400px",
          width: "90%",
        }}
      >
        <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" skeletonHight="100" />
      </div>
    </div>
  ) : adList.length <= 1 ? (
    <div className={classes.mainSectionContainer}>
      <div className={classes.mainSectionContent}>
        <p className={classes.mainSectionSubtitle}>{adList[0]?.subtitle || t("home.main_section_subtitle")}</p>
        <h2 className={classes.mainSectionTitle}>{adList[0]?.title || t("home.main_section_title")}</h2>
        <p className={classes.mainSectionDescription}>{adList[0]?.description || t("home.home_description")}</p>
        <CustomButton
          value={adList[0]?.has_button ? adList[0]?.button_text : t("enroll")}
          colors={"rgb(3, 162, 178)"}
          icon={i18n.language === "ar" ? ArrowLeft : ArrowRight}
          classes={classes.mainSectionAction}
          action={() =>
            adList[0]?.has_button
              ? window.open(adList[0]?.url, "_blank")
              : localStorage.type
              ? history.push("/training-courses")
              : history.push("/register")
          }
        />
      </div>
      <div className={classes.mainSectionImage}>
        <img src={adList[0]?.image || Img} alt="" height={600} width={"100%"} />
      </div>
    </div>
  ) : (
    <MainSectionCarousel>
      {adList.map((ad) => (
        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <div className={classes.mainSectionContainer}>
            <div className={classes.mainSectionContent}>
              <h2 className={classes.mainSectionTitle}>{ad.title}</h2>
              <p className={classes.mainSectionDescription}>{ad.description}</p>
              <CustomButton
                value={ad.has_button ? ad.button_text : t("enroll")}
                colors={"rgb(3, 162, 178)"}
                icon={i18n.language === "ar" ? ArrowLeft : ArrowRight}
                classes={classes.mainSectionAction}
                action={() =>
                  ad.has_button
                    ? window.open(ad.url, "_blank")
                    : localStorage.type
                    ? history.push("/training-courses")
                    : history.push("/register")
                }
              />
            </div>
            <div className={classes.mainSectionImage}>
              <img src={ad.image || Img} alt="" height={600} width={"100%"} />
            </div>
          </div>
        </div>
      ))}
    </MainSectionCarousel>
  );
};

export default MainSection;

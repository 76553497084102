import { Pagination } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import classes from "../search.module.css";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import EyeIcon from "../../../../assets/icons/eye.svg";
const SearchTable = ({
  submitSearchHandler,
  tableColumns,
  tableData,
  isLoading,
  totalPages,
  activeTab,
  setCurrentPage,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const displayClassesToLongerLength = (value) => {
    if (value?.length > 150) {
      return classes["saved-search__table-item"];
    }
    return "";
  };

  const displayTableCellHandler = (type, key) => {
    switch (type) {
      case "text":
        return (value) => (
          <div
            className={displayClassesToLongerLength(value)}
            style={
              key === "phone" || key === "user_phone"
                ? { direction: "ltr" }
                : {}
            }
          >
            {value}
          </div>
        );
      case "percentage":
        return (value) => (
          <div className={displayClassesToLongerLength(value)}>{value}%</div>
        );
      case "date":
        return (value) => (
          <>
            {moment(value).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(value)}
          </>
        );
      case "rate":
        return (value) => <StarsRating rating={value || 0} />;
      default:
        return (value) => (
          <div className={displayClassesToLongerLength(value)}>{value}</div>
        );
    }
  };

  const columns = [
    {
      id: "serial",
      name: "#",
      key: "serial",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    // {
    //   id: "id",
    //   name: t("general.id"),
    //   key: "id",
    //   cell: ({ rowData }) => <>{rowData?.id}</>
    // },
  ];

  const getColumns = () => {
    tableColumns?.map((item) => {
      columns.push({
        id: item?.label,
        name: item?.label,
        key: item?.label,
        cell: ({ rowData }) =>
          rowData[item?.key]
            ? displayTableCellHandler(item?.type, item?.key)(rowData[item?.key])
            : "-",
      });
    });
    const ActionsList = [
      {
        id: "view-details",
        icon: <img src={EyeIcon} alt="" />,
        action: (id) => {
          history.push(`/${localStorage.getItem("type")}/search/preview/${id}`);
        },
        tooltip: t("general.view_details"),
      },
    ];
    if (activeTab === "users") {
      columns.push({
        id: "actions",
        name: t("general.actions"),
        key: "actions",
        cell: ({ rowData }) => (
          <TableActionsColumn actions={ActionsList} id={rowData.id} />
        ),
      });
    }
    return columns;
  };

  return (
    <>
      <MainTable
        columns={getColumns()}
        data={tableData || []}
        isLoading={isLoading}
      />
      <Pagination
        count={totalPages || 0}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        onChange={(e, page) => {
          if (setCurrentPage) {
            setCurrentPage(page);
          } else {
            submitSearchHandler(false, page);
          }
        }}
      />
    </>
  );
};

export default SearchTable;

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Qassem_logo from "../../../../../assets/image/Qassem_logo.svg";

// mui icons
import Menu from "../../../../../assets/icons/Menu1.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import classes from "./mainDrawer.module.css";

const routes = {
  profile: "/edit-profile",
  home: "/",
  AboutUs: "/about-us",
  courses: "/training-courses",
  program: "/training-programs",
  ProfessionalTrainers: "/trainers",
  ...(!!localStorage.getItem("token") ? { generalSurveys: "/general-surveys" } : {}),
  support_tickets: "/tickets",
  TermsAndConditions: "/terms-and-conditions",
  ConnectUs: "/contactUs",
  live_chat: "/livechat",
};

function MainDrawer({ dismissSideBar, setAddAnimationSide, addAnimationSide, roles }) {
  require("./sidebar.css");
  const history = useHistory();
  const { t } = useTranslation();
  let is_logged_in = localStorage.getItem("token") === null ? false : true;

  const checkRolesIncludes = (role) => {
    return roles.includes(role);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  const sideBarItems = [
    {
      title: t("Main"),
      itemId: routes["home"],
    },
    {
      title: t("AboutUs"),
      itemId: routes["AboutUs"],
    },
    {
      title: t("courses"),
      itemId: routes["courses"],
    },
    {
      title: t("program"),
      itemId: routes["program"],
    },
    {
      title: t("ProfessionalTrainers"),
      itemId: routes["ProfessionalTrainers"],
    },

    ...(is_logged_in
      ? [
          {
            title: t("sidebar.label.generalSurveys"),
            itemId: routes["generalSurveys"],
          },
        ]
      : []),
    ...(is_logged_in
      ? [
          {
            title: t("sidebar.label.support_tickets"),
            itemId: routes["support_tickets"],
          },
        ]
      : []),
    {
      title: t("TermsAndConditions"),
      itemId: routes["TermsAndConditions"],
    },
    {
      title: t("ConnectUs"),
      itemId: routes["ConnectUs"],
    },
    {
      title: i18n.language === "ar" ? "EN" : "العربية",
      itemId: "change_language",
    },
  ];

  const checkItemIdIncludes = (itemId) => {
    const itemIds = [
      routes["home"],
      routes["AboutUs"],
      routes["courses"],
      routes["program"],
      routes["ProfessionalTrainers"],
      routes["Exhibition"],
      routes["generalSurveys"],
      routes["support_tickets"],
      routes["TermsAndConditions"],
      routes["financialReport"],
      routes["ConnectUs"],
      routes["live_chat"],
      "change_language",
    ];
    return itemIds.includes(itemId);
  };

  const closeSideBar = () => {
    dismissSideBar();
  };

  useEffect(() => {
    setTimeout(() => {
      setAddAnimationSide(false);
    }, 300);
  }, [addAnimationSide]);

  return (
    <div
      className="sidebar inner-scrollbar"
      style={{
        transform: `${
          addAnimationSide && document.body.dir == "rtl"
            ? "translateX(100%)"
            : addAnimationSide && document.body.dir == "ltr"
            ? "translateX(-100%)"
            : null
        }`,
      }}
    >
      <div className="logo-sidebar">
        <NavLink exact to="/">
          <img src={Qassem_logo} alt="" className={classes["logo_Qaf"]} />
        </NavLink>
        <img src={Menu} alt="" onClick={closeSideBar} style={{ width: 50, height: 35 }} />
      </div>
      {is_logged_in && (
        <div className="sidebar-header">
          <div className="user-image-Qassim">
            <img className="user-image" src={localStorage.getItem("img")} alt="" />
          </div>
          <div className="">
            <span style={{ fontSize: 22, fontWeight: 500 }}>{t("sidebar.label.hello_on_you")}</span>
            <h4>
              {i18n.language === Lang.AR
                ? localStorage.getItem("name_ar") != null
                  ? localStorage.getItem("name_ar").split(" ")[0]
                  : null
                : localStorage.getItem("name_en") != null
                ? localStorage.getItem("name_en").split(" ")[0]
                : null}
            </h4>
          </div>
        </div>
      )}

      <Navigation
        className="nav_sidebar"
        onSelect={({ itemId }) => {
          if (checkItemIdIncludes(itemId) && itemId !== "change_language") {
            history.push(itemId);
            closeSideBar();
          } else if (checkItemIdIncludes(itemId) && itemId === "change_language") {
            changeLanguage(i18n.language === "ar" ? "en" : "ar");
          }
        }}
        items={sideBarItems}
      />
    </div>
  );
}

export default MainDrawer;

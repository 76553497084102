import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import AnalysisIcon from "../../../assets/icons/AnalysisIcon.svg";
import DevelopmentIcon from "../../../assets/icons/DevelopmentIcon.svg";
import InnerIcons from "../../../assets/icons/InnerIcons.svg";
import ManagmentIcon from "../../../assets/icons/ManagmentIcon.svg";
import Menu from "../../../assets/icons/Menu1.svg";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import SupportIcon from "../../../assets/icons/SupportIcon.svg";
import SurvayIcon from "../../../assets/icons/SurvayIcon.svg";
import SystemSettingsIcon from "../../../assets/icons/SystemSettingsIcon.svg";
import CalenderIcon from "../../../assets/icons/calendarMangement.svg";
import FiniancialIcon from "../../../assets/icons/financial-business.svg";
import Qassem_logo from "../../../assets/image/Qassem_logo.svg";

import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";

function Sidebar(props) {
  require("./sidebar.css");
  const history = useHistory();
  const { t } = useTranslation();
  const [sideBarItems, setSideBarItems] = useState([
    {
      title: t("sidebar.label.user_management"),
      itemId: "/1",
      elemBefore: () => (
        <img src={ManagmentIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("sidebar.label.user_management"),
          itemId: "/admin/roles",
        },
        {
          title: t("sidebar.label.send_messages"),
          itemId: "/admin/message/send",
        },
        // {
        //   title: t("trainerWaitingforAccept"),
        //   itemId: "/admin/pendings-trainers",
        // },
        // {
        //   title: t("sidebar.label.trainer_ratings"),
        //   itemId: "/admin/trainer-rate/list",
        // },
      ],
    },
    {
      title: t("PlatformManagement"),
      itemId: "/8",
      elemBefore: () => (
        <img src={ManagmentIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("PlatformManagementlist"),
          itemId: "/admin/PlatformManagement",
        },
      ],
    },

    {
      title: t("sidebar.label.financial_management"),
      itemId: "/2",
      elemBefore: () => (
        <img src={FiniancialIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        // {
        //   title: t("sidebar.label.coupon"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/coupon`,
        // },
        // {
        //   title: t("sidebar.label.offer"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/offer`,
        // },
        // {
        //   title: t("sidebar.label.tax"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/tax`,
        // },
        {
          title: t("sidebar.label.financial_movement"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/financial-movement`,
        },
        {
          title: t("sidebar.label.all_refunds"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/refund`,
        },
        // {
        //   title: t("sidebar.label.payment_requests_courses"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/peyment-requests-courses`,
        // },
        // {
        //   title: t("sidebar.label.payment_requests_programs"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/peyment-requests-programs`,
        // },
        // {
        //   title: t("sidebar.label.programPaymentDate"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/program-payment-date?program_payment_date=all`,
        // },
        // {
        //   title: t("Percentage_trainers_courses"),
        //   itemId: `/${
        //     localStorage.getItem("type") === "accountant"
        //       ? "accountant"
        //       : "admin"
        //   }/percentage-trainer/list`,
        // },
      ],
    },
    {
      title: t("sidebar.label.content_development"),
      itemId: "/3",
      elemBefore: () => (
        <img src={DevelopmentIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("specialties_and_departments"),
          itemId: "/admin/category",
        },
        {
          title: t("sidebar.label.courses"),
          itemId: "/admin/courses",
        },
        // {
        //   title: t("sidebar.label.department"),
        //   itemId: "/admin/department",
        // },
        {
          title: t("sidebar.label.program"),
          itemId: "/admin/program/list",
        },
        {
          title: t("sidebar.label.certificate_templates"),
          itemId: "/admin/certificateTemplates",
        },
        {
          title: t("PublishRequests"),
          itemId: "/admin/PublishRequests",
        },
      ],
    },
    {
      title: t("sidebar.label.list_of_survey"),
      itemId: "/18",
      elemBefore: () => (
        <img src={SurvayIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("survey.add"),
          itemId: "/admin/survey/add",
        },
        {
          title: t("survey.listSideBar"),
          itemId: "/admin/survey/list",
        },
        // {
        //   title: t("survey.trainerListSideBar"),
        //   itemId: "/admin/survey/trainer/list",
        // },
      ],
    },
    {
      title: t("events.events_title_sidebar"),
      itemId: "/19",
      elemBefore: () => (
        <img src={CalenderIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("events.events_title"),
          itemId: "/admin/events",
        },
        {
          title: t("Advertisements"),
          itemId: "/admin/advertisements-list",
        },
      ],
    },
    // {
    //   title: t("sidebar.label.Exhibition"),
    //   itemId: "/15",
    //   elemBefore: () => <BookmarkBorderOutlinedIcon color="#046C77" />,
    //   subNav: [
    //     {
    //       title: t("Exhibition.title_create"),
    //       itemId: "/admin/create-exhibition",
    //     },
    //     {
    //       title: t("Exhibition.Exhibition"),
    //       itemId: "/admin/exhibitions-list",
    //     },
    //   ],
    // },

    {
      title: t("sidebar.label.support"),
      elemBefore: () => (
        <img src={SupportIcon} alt="" className="icon-sidebar_qassim" />
      ),
      // title: t("sidebar.label.live_chat"),
      itemId: "/7",
      subNav: [
        {
          title: t("sidebar.label.support_tickets"),
          itemId: "/admin/tickets",
        },
        // {
        //   title: t("sidebar.label.live_chat"),
        //   itemId: "/livechat",
        // },
        // {
        //   title: t("FAQ.list_more_common_questions"),
        //   itemId: "/admin/faq-list",
        // },
        {
          title: t("FAQ.list_common_questions"),
          itemId: "/admin/faq",
        },
      ],
    },
    {
      title: t("search.search_reports"),
      elemBefore: () => (
        <img src={SearchIcon} alt="" className="icon-sidebar_qassim" />
      ),
      itemId: "/20",
      subNav: [
        {
          title: t("search.free_search"),
          itemId: "/admin/search/free",
        },
        {
          title: t("search.conduct_search"),
          itemId: "/admin/search",
        },
        {
          title: t("search.search_history"),
          itemId: "/admin/search/history",
        },
      ],
    },

    {
      title: t("sidebar.label.system_preferences"),
      itemId: "/6",
      elemBefore: () => (
        <img src={SystemSettingsIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        // {
        //   title: t("sidebar.label.enable_services"),
        //   itemId: "/admin/preferences",
        // },
        {
          title: t("sidebar.label.files_system"),
          itemId: "/admin/typefile/list",
        },
        {
          title: t("zoom.Webx_teams"),
          itemId: "/admin/teams/list",
        },
        // {
        //   title: t("sidebar.label.history_move_system"),
        //   itemId: "/admin/logs",
        // },
      ],
    },

    {
      title: t("sidebar.label.learning_analysis"),
      itemId: "/16",
      elemBefore: () => (
        <img src={AnalysisIcon} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("sidebar.label.analysis"),
          itemId: "/admin/learning-analysis",
        },
        // {
        //   title: t("sidebar.label.dashBoardAnalysis"),
        //   itemId: "/admin/analysis",
        // },
        // {
        //   title: t("sidebar.label.traineeAnalysis"),
        //   itemId: "/admin/trainee-analysis",
        // },
        // {
        //   title: t("sidebar.label.chartTraineeAnalysis"),
        //   itemId: "/admin/trainee-chapter-analysis",
        // },
        // {
        //   title: t("sidebar.label.courseAnalysis"),
        //   itemId: "/admin/course-analysis",
        // },
      ],
    },

    {
      title: t("system_preferences.page_text"),
      itemId: "/4",
      elemBefore: () => (
        <img src={InnerIcons} alt="" className="icon-sidebar_qassim" />
      ),
      subNav: [
        {
          title: t("sidebar.label.about-us"),
          itemId: "/admin/about-us-edit",
        },
        {
          title: t("sidebar.label.TermsAndConditions"),
          itemId: "/admin/terms-and-conditions",
        },
      ],
    },

    // {
    //   title: t("sidebar.report.title"),
    //   itemId: "/13",
    //   elemBefore: () => <EqualizerOutlinedIcon color="#046C77" />,
    //   subNav: [
    //     {
    //       title: t("sidebar.report.title_trainer_courses"),
    //       itemId: "/admin/report/trainer/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainer_hours"),
    //       itemId: "/admin/report/trainer/hours/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_courses"),
    //       itemId: "/admin/report/trainee-courses/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_programs"),
    //       itemId: "/admin/report/trainee-programs/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_hours"),
    //       itemId: "/admin/report/trainee/hours/list",
    //     },
    //   ],
    // },
  ]);

  useEffect(async () => {
    // if (localStorage.getItem("system_preferences")) {
    // const toBeModifiedSideBarItems = sideBarItems;
    // const preferences = JSON.parse(localStorage.getItem("system_preferences"));
    // if (preferences.support_tickets_service) {
    // toBeModifiedSideBarItems.push({
    //   title: t("sidebar.label.support_tickets"),
    //   itemId: "/5",
    //   subNav: [
    //     {
    //       title: t("sidebar.label.support_tickets"),
    //       itemId: "/admin/tickets",
    //     },
    //   ],
    // });
    // }
    // if (preferences.chat_service) {
    // toBeModifiedSideBarItems.push({
    //   title: t("sidebar.label.live_chat"),
    //   itemId: "/7",
    //   subNav: [
    //     {
    //       title: t("sidebar.label.live_chat"),
    //       itemId: "/livechat",
    //     },
    //   ],
    // });
    // }
    // setSideBarItems(toBeModifiedSideBarItems);
    // }
  }, []);

  const closeSideBar = () => {
    props.dismissSideBar();
  };

  useEffect(() => {
    setTimeout(() => {
      props.setAddAnimationSide(false);
    }, 300);
  }, [props.addAnimationSide]);

  return (
    <div
      className="sidebar inner-scrollbar"
      style={{
        transform: `${
          props.addAnimationSide && document.body.dir == "rtl"
            ? "translateX(100%)"
            : props.addAnimationSide && document.body.dir == "ltr"
            ? "translateX(-100%)"
            : null
        }`,
      }}
    >
      <div className="logo-sidebar">
        <NavLink exact to="/">
          <img src={Qassem_logo} alt="" style={{ maxWidth: "50px" }} />
        </NavLink>
        <img
          src={Menu}
          alt=""
          onClick={closeSideBar}
          style={{ width: 35, height: 35 }}
        />
      </div>

      <div className="sidebar-header">
        <div className="user-image-Qassim">
          <img
            className="user-image"
            src={localStorage.getItem("img")}
            alt=""
          />
        </div>
        <div className="">
          <span style={{ fontSize: 22, fontWeight: 500 }}>
            {t("sidebar.label.hello_on_you")}
          </span>
          <h4>
            {i18n.language === Lang.AR
              ? localStorage.getItem("name_ar") != null
                ? localStorage.getItem("name_ar").split(" ")[0]
                : null
              : localStorage.getItem("name_en") != null
              ? localStorage.getItem("name_en").split(" ")[0]
              : null}
          </h4>
        </div>
      </div>

      {localStorage.getItem("type") === "accountant" ? (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (itemId !== "/1") {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={[
            {
              title: t("sidebar.label.financial_management"),
              itemId: "/1",
              subNav: [
                // {
                //   title: t("sidebar.label.coupon"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/coupon`,
                // },
                // {
                //   title: t("sidebar.label.offer"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/offer`,
                // },
                // {
                //   title: t("sidebar.label.tax"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/tax`,
                // },
                {
                  title: t("sidebar.label.financial_movement"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/financial-movement`,
                },
                // {
                //   title: t("sidebar.label.financialReport"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/financial/report`,
                // },
                {
                  title: t("sidebar.label.all_refunds"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/refund`,
                },
                // {
                //   title: t("sidebar.label.payment_requests_courses"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/peyment-requests-courses`,
                // },
                // {
                //   title: t("sidebar.label.payment_requests_programs"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/peyment-requests-programs`,
                // },
                // {
                //   title: t("sidebar.label.refund-setting"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/refund-setting/list`,
                // },
                // {
                //   title: t("sidebar.label.userWallet"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                // ? "accountant"
                //       : "admin"
                //   }/users-wallet`,
                // },
                // {
                //   title: t("sidebar.label.bank_transfers"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/bank-transfers`,
                // },
                // {
                //   title: t("sidebar.label.programPaymentDate"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/program-payment-date?program_payment_date=all`,
                // },
                // {
                //   title: t("Percentage_trainers_courses"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/percentage-trainer/list`,
                // },
              ],
            },
          ]}
        />
      ) : localStorage.getItem("roles").includes("group-admin") ? (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (
              itemId !== "/1" &&
              itemId !== "/2" &&
              itemId !== "/3" &&
              itemId !== "/4" &&
              itemId !== "/5" &&
              itemId !== "/6" &&
              itemId !== "/7" &&
              itemId !== "/8" &&
              itemId !== "/9" &&
              itemId !== "/10" &&
              itemId !== "/11" &&
              itemId !== "/12" &&
              itemId !== "/13" &&
              itemId !== "/14" &&
              itemId !== "/15" &&
              itemId !== "/16" &&
              itemId !== "/17" &&
              itemId !== "/18" &&
              itemId !== "/19" &&
              itemId !== "/20"
            ) {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={[
            {
              title: t("sidebar.label.user_management"),
              itemId: "/1",
              elemBefore: () => (
                <img
                  src={ManagmentIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("sidebar.label.user_management"),
                  itemId: "/group-admin/roles",
                },
                {
                  title: t("sidebar.label.send_messages"),
                  itemId: "/group-admin/message/send",
                },
                // {
                //   title: t("trainerWaitingforAccept"),
                //   itemId: "/group-admin/pendings-trainers",
                // },
                // {
                //   title: t("sidebar.label.trainer_ratings"),
                //   itemId: "/group-admin/trainer-rate/list",
                // },
              ],
            },

            {
              title: t("sidebar.label.financial_management"),
              itemId: "/2",
              elemBefore: () => (
                <img
                  src={FiniancialIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("sidebar.label.financial_movement"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : localStorage.getItem("type") === "group-admin"
                      ? "group-admin"
                      : "admin"
                  }/financial-movement`,
                },
                {
                  title: t("sidebar.label.all_refunds"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : localStorage.getItem("type") === "group-admin"
                      ? "group-admin"
                      : "admin"
                  }/refund`,
                },
              ],
            },
            {
              title: t("sidebar.label.content_development"),
              itemId: "/3",
              elemBefore: () => (
                <img
                  src={DevelopmentIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("specialties_and_departments"),
                  itemId: "/group-admin/category",
                },
                {
                  title: t("sidebar.label.courses"),
                  itemId: "/group-admin/courses",
                },
                // {
                //   title: t("sidebar.label.department"),
                //   itemId: "/group-admin/department",
                // },
                {
                  title: t("sidebar.label.program"),
                  itemId: "/group-admin/program/list",
                },
                {
                  title: t("sidebar.label.certificate_templates"),
                  itemId: "/group-admin/certificateTemplates",
                },
                // {
                //   title: t("PublishRequests"),
                //   itemId: "/group-admin/PublishRequests",
                // },
              ],
            },
            {
              title: t("sidebar.label.list_of_survey"),
              itemId: "/18",
              elemBefore: () => (
                <img src={SurvayIcon} alt="" className="icon-sidebar_qassim" />
              ),
              subNav: [
                {
                  title: t("survey.add"),
                  itemId: "/group-admin/survey/add",
                },
                {
                  title: t("survey.listSideBar"),
                  itemId: "/group-admin/survey/list",
                },
                // {
                //   title: t("survey.trainerListSideBar"),
                //   itemId: "/group-admin/survey/trainer/list",
                // },
              ],
            },
            {
              title: t("events.events_sidebar"),
              itemId: "/19",
              elemBefore: () => (
                <img
                  src={CalenderIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("events.events_title"),
                  itemId: "/group-admin/events",
                },
                // {
                //   title: t("Advertisements"),
                //   itemId: "/group-admin/advertisements-list",
                // },
              ],
            },

            {
              title: t("sidebar.label.support"),
              elemBefore: () => (
                <img src={SupportIcon} alt="" className="icon-sidebar_qassim" />
              ),
              // title: t("sidebar.label.live_chat"),
              itemId: "/7",
              subNav: [
                {
                  title: t("sidebar.label.support_tickets"),
                  itemId: "/group-admin/tickets",
                },
                // {
                //   title: t("sidebar.label.live_chat"),
                //   itemId: "/livechat",
                // },
                // {
                //   title: t("FAQ.list_more_common_questions"),
                //   itemId: "/group-admin/faq-list",
                // },
                // {
                //   title: t("FAQ.list_common_questions"),
                //   itemId: "/group-admin/faq",
                // },
              ],
            },
            {
              title: t("search.search_reports"),
              elemBefore: () => (
                <img src={SearchIcon} alt="" className="icon-sidebar_qassim" />
              ),
              itemId: "/20",
              subNav: [
                {
                  title: t("search.free_search"),
                  itemId: "/group-admin/search/free",
                },
                {
                  title: t("search.conduct_search"),
                  itemId: "/group-admin/search",
                },
                {
                  title: t("search.search_history"),
                  itemId: "/group-admin/search/history",
                },
              ],
            },

            // {
            //   title: t("sidebar.label.system_preferences"),
            //   itemId: "/6",
            //   elemBefore: () => (
            //     <img
            //       src={SystemSettingsIcon}
            //       alt=""
            //       className="icon-sidebar_qassim"
            //     />
            //   ),
            //   subNav: [
            //     // {
            //     //   title: t("sidebar.label.enable_services"),
            //     //   itemId: "/group-admin/preferences",
            //     // },
            //     // {
            //     //   title: t("sidebar.label.files_system"),
            //     //   itemId: "/group-admin/typefile/list",
            //     // },
            //     // {
            //     //   title: t("zoom.Webx_teams"),
            //     //   itemId: "/group-admin/teams/list",
            //     // },
            //     {
            //       title: t("sidebar.label.history_move_system"),
            //       itemId: "/group-admin/logs",
            //     },
            //   ],
            // },

            {
              title: t("sidebar.label.learning_analysis"),
              itemId: "/16",
              elemBefore: () => (
                <img
                  src={AnalysisIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("sidebar.label.analysis"),
                  itemId: "/group-admin/learning-analysis",
                },
                // {
                //   title: t("sidebar.label.dashBoardAnalysis"),
                //   itemId: "/group-admin/analysis",
                // },
                // {
                //   title: t("sidebar.label.traineeAnalysis"),
                //   itemId: "/group-admin/trainee-analysis",
                // },
                // {
                //   title: t("sidebar.label.chartTraineeAnalysis"),
                //   itemId: "/group-admin/trainee-chapter-analysis",
                // },
                // {
                //   title: t("sidebar.label.courseAnalysis"),
                //   itemId: "/group-admin/course-analysis",
                // },
              ],
            },

            // {
            //   title: t("system_preferences.page_text"),
            //   itemId: "/4",
            //   elemBefore: () => (
            //     <img src={InnerIcons} alt="" className="icon-sidebar_qassim" />
            //   ),
            //   subNav: [
            //     {
            //       title: t("sidebar.label.about-us"),
            //       itemId: "/group-admin/about-us-edit",
            //     },
            //     {
            //       title: t("sidebar.label.TermsAndConditions"),
            //       itemId: "/group-admin/terms-and-conditions",
            //     },
            //   ],
            // },
          ]}
        />
      ) : localStorage.getItem("roles").includes("manager") ? (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (
              itemId !== "/1" &&
              itemId !== "/2" &&
              itemId !== "/3" &&
              itemId !== "/4" &&
              itemId !== "/5" &&
              itemId !== "/6" &&
              itemId !== "/7" &&
              itemId !== "/8" &&
              itemId !== "/9" &&
              itemId !== "/10" &&
              itemId !== "/11" &&
              itemId !== "/12" &&
              itemId !== "/13" &&
              itemId !== "/14" &&
              itemId !== "/15" &&
              itemId !== "/16" &&
              itemId !== "/17" &&
              itemId !== "/18" &&
              itemId !== "/19" &&
              itemId !== "/20"
            ) {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={[
            {
              title: t("sidebar.label.user_management"),
              itemId: "/1",
              elemBefore: () => (
                <img
                  src={ManagmentIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("sidebar.label.user_management"),
                  itemId: "/manager/roles",
                },
                {
                  title: t("sidebar.label.send_messages"),
                  itemId: "/manager/message/send",
                },
                // {
                //   title: t("trainerWaitingforAccept"),
                //   itemId: "/manager/pendings-trainers",
                // },
                // {
                //   title: t("sidebar.label.trainer_ratings"),
                //   itemId: "/manager/trainer-rate/list",
                // },
              ],
            },

            {
              title: t("sidebar.label.content_development"),
              itemId: "/3",
              elemBefore: () => (
                <img
                  src={DevelopmentIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("specialties_and_departments"),
                  itemId: "/manager/category",
                },
                {
                  title: t("sidebar.label.courses"),
                  itemId: "/manager/courses",
                },

                {
                  title: t("sidebar.label.program"),
                  itemId: "/manager/program/list",
                },
                {
                  title: t("sidebar.label.certificate_templates"),
                  itemId: "/manager/certificateTemplates",
                },
                {
                  title: t("PublishRequests"),
                  itemId: "/manager/PublishRequests",
                },
              ],
            },
            {
              title: t("sidebar.label.list_of_survey"),
              itemId: "/18",
              elemBefore: () => (
                <img src={SurvayIcon} alt="" className="icon-sidebar_qassim" />
              ),
              subNav: [
                {
                  title: t("survey.add"),
                  itemId: "/manager/survey/add",
                },
                {
                  title: t("survey.listSideBar"),
                  itemId: "/manager/survey/list",
                },
                // {
                //   title: t("survey.trainerListSideBar"),
                //   itemId: "/manager/survey/trainer/list",
                // },
              ],
            },
            {
              title: t("events.events_title_sidebar"),
              itemId: "/19",
              elemBefore: () => (
                <img
                  src={CalenderIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("events.events_title"),
                  itemId: "/manager/events",
                },
                {
                  title: t("Advertisements"),
                  itemId: "/manager/advertisements-list",
                },
              ],
            },

            {
              title: t("sidebar.label.support"),
              elemBefore: () => (
                <img src={SupportIcon} alt="" className="icon-sidebar_qassim" />
              ),
              // title: t("sidebar.label.live_chat"),
              itemId: "/7",
              subNav: [
                {
                  title: t("sidebar.label.support_tickets"),
                  itemId: "/manager/tickets",
                },
                // {
                //   title: t("sidebar.label.live_chat"),
                //   itemId: "/livechat",
                // },
                // {
                //   title: t("FAQ.list_more_common_questions"),
                //   itemId: "/manager/faq-list",
                // },
                // {
                //   title: t("FAQ.list_common_questions"),
                //   itemId: "/manager/faq",
                // },
              ],
            },
            {
              title: t("search.search_reports"),
              elemBefore: () => (
                <img src={SearchIcon} alt="" className="icon-sidebar_qassim" />
              ),
              itemId: "/20",
              subNav: [
                {
                  title: t("search.free_search"),
                  itemId: "/manager/search/free",
                },
                {
                  title: t("search.conduct_search"),
                  itemId: "/manager/search",
                },
                {
                  title: t("search.search_history"),
                  itemId: "/manager/search/history",
                },
              ],
            },

            // {
            //   title: t("sidebar.label.system_preferences"),
            //   itemId: "/6",
            //   elemBefore: () => (
            //     <img
            //       src={SystemSettingsIcon}
            //       alt=""
            //       className="icon-sidebar_qassim"
            //     />
            //   ),
            //   subNav: [
            //     // {
            //     //   title: t("sidebar.label.enable_services"),
            //     //   itemId: "/manager/preferences",
            //     // },
            //     // {
            //     //   title: t("sidebar.label.files_system"),
            //     //   itemId: "/manager/typefile/list",
            //     // },
            //     // {
            //     //   title: t("zoom.Webx_teams"),
            //     //   itemId: "/manager/teams/list",
            //     // },
            //     {
            //       title: t("sidebar.label.history_move_system"),
            //       itemId: "/manager/logs",
            //     },
            //   ],
            // },

            {
              title: t("sidebar.label.learning_analysis"),
              itemId: "/16",
              elemBefore: () => (
                <img
                  src={AnalysisIcon}
                  alt=""
                  className="icon-sidebar_qassim"
                />
              ),
              subNav: [
                {
                  title: t("sidebar.label.analysis"),
                  itemId: "/manager/learning-analysis",
                },
                // {
                //   title: t("sidebar.label.dashBoardAnalysis"),
                //   itemId: "/manager/analysis",
                // },
                // {
                //   title: t("sidebar.label.traineeAnalysis"),
                //   itemId: "/manager/trainee-analysis",
                // },
                // {
                //   title: t("sidebar.label.chartTraineeAnalysis"),
                //   itemId: "/manager/trainee-chapter-analysis",
                // },
                // {
                //   title: t("sidebar.label.courseAnalysis"),
                //   itemId: "/manager/course-analysis",
                // },
              ],
            },

            {
              title: t("system_preferences.page_text"),
              itemId: "/4",
              elemBefore: () => (
                <img src={InnerIcons} alt="" className="icon-sidebar_qassim" />
              ),
              subNav: [
                {
                  title: t("sidebar.label.about-us"),
                  itemId: "/manager/about-us-edit",
                },
                {
                  title: t("sidebar.label.TermsAndConditions"),
                  itemId: "/manager/terms-and-conditions",
                },
              ],
            },
          ]}
        />
      ) : (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (
              itemId !== "/1" &&
              itemId !== "/2" &&
              itemId !== "/3" &&
              itemId !== "/4" &&
              itemId !== "/5" &&
              itemId !== "/6" &&
              itemId !== "/7" &&
              itemId !== "/8" &&
              itemId !== "/9" &&
              itemId !== "/10" &&
              itemId !== "/11" &&
              itemId !== "/12" &&
              itemId !== "/13" &&
              itemId !== "/14" &&
              itemId !== "/15" &&
              itemId !== "/16" &&
              itemId !== "/17" &&
              itemId !== "/18" &&
              itemId !== "/19" &&
              itemId !== "/20"
            ) {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={sideBarItems}
        />
      )}
    </div>
  );
}

export default Sidebar;

import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";

import { Input } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import { responseSurvey } from "../../../Services/api/survey/SurveyProvider";
import Page from "../../Admin/survey/types/Page";

const is_loggedIn = localStorage.getItem("token") ? true : false;

export default function SurveyPresenter(props) {
  // require("./add.css");
  require("./survey.css");

  const { id } = useParams();

  const { t } = useTranslation();
  const [name, setName] = useState(props.survey.name || "");
  const [description, setDescription] = useState(props.survey.desctiption || "");
  const [isPublic, setIsPublic] = useState(props.survey.is_public || false);
  const [questions, setQuestions] = useState(props.questions || []);
  const [answers, setAnswers] = useState(props.answers || []);
  // copied from old implementation
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");

  useEffect(() => {
    setIsCompleted(isSurveyCompleted());
  }, [answers]);

  const updateAnswers = (questionId, updatedAnswers) => {
    // remove the questions's old answers
    const filtersAnswers = answers.filter((answer) => answer.id != questionId);
    // add the updated answers
    setAnswers([...filtersAnswers, { id: questionId, answers: updatedAnswers }]);
  };

  const getQuestionAnswers = (question) => {
    return answers.filter((answer) => answer.id == question.id);
  };

  // to convert the nested questions' object into a flat questions list
  const flattendQuestions = (questions, falttenedQuestions = []) => {
    questions.map((question, index) => {
      falttenedQuestions.push(question);
      if (question.questions?.length) {
        flattendQuestions(question.questions, falttenedQuestions);
      }
    });
    return falttenedQuestions;
  };

  const isSurveyCompleted = () => {
    // the question types have default answers or not meant to be answered (e.g. the section question)
    const optionalQuestionTypes = ["section", "text_slider", "constant_sum", "numeric_slider"];
    var questionAnswers = [];
    var completed = true;
    questions.map((question) => {
      // first level
      if (question.is_required && !optionalQuestionTypes.includes(question.type)) {
        questionAnswers = getQuestionAnswers(question);
        if (!questionAnswers.length) {
          completed = false;
        } else {
          questionAnswers.map((answer) => {
            if (!answer.answers.length) {
              completed = false;
            }
          });
        }
      }
      // second level
      question.questions?.map((level_one_question) => {
        if (level_one_question.is_required && !optionalQuestionTypes.includes(level_one_question.type)) {
          questionAnswers = [];
          questionAnswers = getQuestionAnswers(level_one_question);
          if (!questionAnswers.length) {
            completed = false;
          } else {
            questionAnswers.map((answer) => {
              if (!answer.answers.length) {
                completed = false;
              }
            });
          }
        }
        // Third level
        level_one_question.questions?.map((level_two_question) => {
          if (level_two_question.is_required && !optionalQuestionTypes.includes(level_two_question.type)) {
            questionAnswers = [];
            questionAnswers = getQuestionAnswers(level_two_question);
            if (!questionAnswers.length) {
              completed = false;
            } else {
              questionAnswers.map((answer) => {
                if (!answer.answers.length) {
                  completed = false;
                }
              });
            }
          }
          // Forth level
          return level_two_question.questions?.map((level_three_question) => {
            if (level_three_question.is_required && !optionalQuestionTypes.includes(level_three_question.type)) {
              questionAnswers = [];
              questionAnswers = getQuestionAnswers(level_three_question);
              if (!questionAnswers.length) {
                completed = false;
              } else {
                questionAnswers.map((answer) => {
                  if (!answer.answers.length) {
                    completed = false;
                  }
                });
              }
            }
          });
        });
      });
    });
    return completed;
  };

  const prepareFormData = () => {
    var formData = new FormData();
    // append guest info for public surveys
    if (isPublic && !is_loggedIn) {
      formData.append(`name`, guestName);
      formData.append(`email`, guestEmail);
    }
    // append the answers
    answers.map((answeredQuestion, index) => {
      formData.append(`questions[${index}][id]`, answeredQuestion.id);
      answeredQuestion.answers.map((answer, i) => {
        if (answer.value) {
          formData.append(`questions[${index}][answers][${i}][value]`, answer.value);
        }
        if (answer.option) {
          formData.append(`questions[${index}][answers][${i}][option_id]`, answer.option.id);
        }
        if (answer.item) {
          formData.append(`questions[${index}][answers][${i}][item_id]`, answer.item.id);
        }
      });
    });
    return formData;
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email);
  };

  const save = () => {
    // check for Recaptcha
    if (!isVerified) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.errors.recaptcha")}</span>, {
        autoClose: 3000,
      });
      return;
    }
    // check for required questions
    if (!isSurveyCompleted()) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.errors.requiredQuestions")}</span>, {
        autoClose: 3000,
      });
      return;
    }
    // checks on guest information
    if (isPublic && !is_loggedIn) {
      if (guestName == "") {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.errors.guest.name")}</span>, {
          autoClose: 3000,
        });
        return;
      }
      if (guestEmail == "" || !validateEmail(guestEmail)) {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.errors.guest.email")}</span>, {
          autoClose: 3000,
        });
        return;
      }
    }
    // start the loading overlay
    props.setIsLoading(true);
    // prepare the payload
    const payload = prepareFormData();
    // for (const pair of payload.entries()) {
    // }
    // return;
    // send the answers
    responseSurvey(payload, props.surveyId)
      .then((res) => {
        props.setIsLoading(false);
        if (res.data.errNum == 201) {
          props.setIsSubmitted(true);
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.thanksForAnswered")}</span>, {
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        props.setIsLoading(false);
        if (error.data.errNum == 400) {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data.msg}</span>);
        }
      });
  };

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(!isVerified);
    }
  };

  return (
    <>
      {(isPublic || props.isPublic) && (props.canAnswer || !props.user?.verified) ? (
        <div className={"survey-card-container"}>
          <div style={{ width: "100%" }}>
            <label for="guest_name">
              {t("survey.guestName")}
              <span className="required-question">*</span>
            </label>
            <Input
              id="guest_name"
              type="guest_name"
              style={{ width: "100%" }}
              onChange={(e) => setGuestName(e.target.value)}
              defaultValue={props.guestName}
              disabled={!props.canAnswer}
            />
          </div>
          <br />
          <div style={{ width: "100%" }}>
            <label for="guest_email">
              {t("survey.email")}
              <span className="required-question">*</span>
            </label>
            <Input
              id="guest_email"
              type="text"
              style={{ width: "100%" }}
              onChange={(e) => setGuestEmail(e.target.value)}
              defaultValue={props.guestEmail}
              disabled={!props.canAnswer}
            />
          </div>
        </div>
      ) : null}
      <div>
        {questions.map((question, index) => {
          return (
            <Page
              key={`page-index-${index}`}
              question={question}
              questionIndex={index}
              surveyMode={"presentation"}
              answers={answers}
              updateAnswers={updateAnswers}
              canAnswer={props.canAnswer}
            />
          );
        })}
      </div>

      {props.canAnswer ? (
        <>
          <div className="survey-card-container">
            <ReCAPTCHA sitekey={env.siteKey} render="explicit" onChange={verifyCallback} />
          </div>

          <div className="row" style={{ display: props.surveyMode == "presentation" ? "none" : "", marginTop: "3%" }}>
            <div className="col-md-6">
              <button
                type="button"
                // disabled={isSubmitting}
                className="admin_add_button"
                onClick={save}
                disabled={
                  !isCompleted || !isVerified || (isPublic && !is_loggedIn && (guestName == "" || guestEmail == ""))
                }
              >
                {t("survey.buttons.send")}
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

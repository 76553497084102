import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getAllAvailableCourses,
  getAllAvailablePrograms,
  getAllSpecialty,
} from "../../Services/api/HomeCoursesProvider";
import HomeIcon from "../../assets/icons/Group 3.svg";
import CourseCard from "../Shared/Components/CourseCard/CourseCard";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import HomeCoursesFilter from "./HomeCourseFilter";
import classes from "./homeCourses.module.css";

export default function HomeCourses({ isProgram }) {
  const { t, i18n } = useTranslation();
  const [availableCourses, setAvailableCourses] = useState([]);
  const [tab, setTab] = useState(null);
  const [meta, setMeta] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [filterData, setFilterData] = useState({
    name: "",
    specialty: history.location.state?.category_id || "",
    department: "",
    self_paced: history.location.state?.self_paced || "",
    mode: history.location.state?.mode || "",
    cost: "",
    level: "",
    number_hours: "",
    rate: "",
    trainer_ids: "",
    manager_ids: "",
    language: "",
    start_date: history.location.state?.start_date || "",
    end_date: "",
  });

  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };

  const getSpecialties = () => {
    getAllSpecialty()
      .then((res) => {
        if (res.status && res.status === 200) {
          setSpecialtiesList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSpecialties();
  }, []);

  const getFilterData = () => {
    const filter = { perPage: 9, page: currentPage };
    filterData.number_hours && (filter.number_hours = filterData.number_hours);
    filterData.self_paced && (filter.self_paced = filterData.self_paced);
    filterData.rate && (filter.rate = filterData.rate);
    filterData.name && (filter.name = filterData.name);
    filterData.trainer_ids && !isProgram && (filter.trainer_ids = [filterData.trainer_ids] || []);
    filterData.trainer_ids && isProgram && (filter.manager_ids = [filterData.trainer_ids] || []);

    filterData.mode && (filter.mode = filterData.mode);
    filterData.cost && (filter.cost = filterData.cost);
    filterData.specialty && (filter.category_id = filterData.specialty);
    filterData.department && (filter.section_id = filterData.department);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.language && (filter.language = filterData.language);
    filterData.level && (filter.level = filterData.level);
    return filter;
  };
  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      specialty: "",
      department: "",
      trainer_ids: "",
      mode: "",
      self_paced: "",
      number_hours: "",
      rate: "",
      cost: "",
      language: "",
      manager_ids: "",
      level: "",
      start_date: "",
      end_date: "",
    });
    setIsResetApplied(true);
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const getCoursesListHandler = () => {
    setCoursesLoading(true);
    const apiCall = isProgram ? getAllAvailablePrograms : getAllAvailableCourses;
    const filter = getFilterData();

    apiCall({ tab, filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setAvailableCourses(res.data.data);
          setMeta(res.data.meta);
          setLoading(false);
          setCoursesLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCoursesListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };
  const handleSubmit = (isReset) => {
    setCoursesLoading(true);
    const apiCall = isProgram ? getAllAvailablePrograms : getAllAvailableCourses;
    const filter = getFilterData();
    if (isReset) {
      filter.search = "";
    }
    apiCall({ tab, filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setAvailableCourses(res.data.data);
          setMeta(res.data.meta);
          setLoading(false);
          setCoursesLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {!loading ? (
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "20px" }}>
            <div className={classes["title_search"]}>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  flex: "2 1 auto",
                  alignItems: "center",
                }}
              >
                <img src={HomeIcon} alt="" onClick={() => history.push("/")} style={{ cursor: "pointer" }} />
                {isProgram ? (
                  <p className={classes["title"]}>{t("program")}</p>
                ) : (
                  <p className={classes["title"]}>{t("courses")}</p>
                )}
              </div>
            </div>
            <MainBox
              style={{
                padding: "0rem 0rem",
                borderRadius: "15px",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <HomeCoursesFilter
                filterData={filterData}
                changeHandler={changeHandler}
                resetHandler={resetHandler}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                filterHandler={getCoursesListHandler}
                resetFilterHandler={resetFilterHandler}
                isProgram={isProgram}
              />
            </MainBox>
            <MainBox
              className="border-8"
              style={{
                width: "100%",
                backgroundColor: "#f7f7f7",
                boxShadow: "none",
              }}
            >
              {coursesLoading ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className={classes["courses_card_grid"]}>
                  {availableCourses?.map((course) => (
                    <>
                      <CourseCard
                        levelbadge={displayLevel(Number(course.level))}
                        selfPaced={course.self_paced}
                        mode={course.mode}
                        price={course.price}
                        lang={course.language}
                        categoryName={course?.category?.name}
                        depName={i18n.language === "ar" ? course?.department_name_ar : course?.department_name_en}
                        img={course.img}
                        title={course.name}
                        date={course.start_date.split(" ")[0]}
                        seat={`${course.participators} /${course.number_of_users}`}
                        hour={course.number_hours}
                        handleSubmit={() => {
                          history.push(`/${isProgram ? "training-programs" : "training-courses"}/${course.id}/details`);
                        }}
                        btn={t("programs.values.show_details")}
                      />
                    </>
                  ))}
                </div>
              )}
              <Pagination
                count={meta?.last_page || 0}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                page={currentPage}
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ViewReports from "../../../assets/icons/analyze.svg";
import CertificateIcon from "../../../assets/icons/badge.svg";
import CoursesIcon from "../../../assets/icons/courses.png";
import DeleteIcon from "../../../assets/icons/deleteTable.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import editIcon from "../../../assets/icons/pen.svg";
import CloseIcon from "../../../assets/icons/remove-red.svg";
import timeIcon from "../../../assets/icons/time.png";
// import UserGroupIcon from "../../../assets/icons/user.svg";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import UserGroupIcon from "../../../assets/icons/TrainersIcons.svg";
import UserGroupIconTwo from "../../../assets/icons/users2.png";
import XlsIcon from "../../../assets/icons/xls.svg";
import { deleteCourse, updateCourseStatus } from "../../../Redux/Actions/courses/coursesList.action";
import { Lang } from "../../../utils";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./courses.module.css";
const CoursesListTable = ({
  isProgram,
  programCourses,
  programId,
  role,
  exportOneProgramHandler,
  exportOneCourseHandler,
  status,
  inProgram,
  currentPage,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [program_id, setProgram_id] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const { coursesList, coursesListLoading, deleteCourseLoading, partnerCoursesList, partnerCoursesListLoading } =
    useSelector((state) => state.coursesListReducer);

  const coursesTableActionsList = (mode, status, program_id) => {
    return [
      // ...(role !== "partner" && role !== "manager" && role !== "trainer" && !programCourses
      //   ? [
      //       {
      //         id: "partners",
      //         icon: <img src={PartnersIcon} alt="partners" />,
      //         action: (id) => {
      //           history.push(`/admin/${isProgram ? "program" : "course"}/${id}/partners`);
      //         },
      //         tooltip: t(`trainer.${isProgram ? "program" : "course"}.partners`),
      //       },
      //     ]
      //   : []),
      ...(role === "content_developer"
        ? [
            {
              id: "view-details",
              icon: <img src={EyeIcon} alt="" />,
              action: (id) => {
                history.push({
                  pathname: `/${role}/course/${id}`,
                  state: { role: role, status: status },
                });
              },
              tooltip: (name) => {
                return `${t("general.view_details")} ${name}`;
              },
              color: "#5A80FF",
            },
          ]
        : [
            {
              id: "export",
              icon: <img src={ViewReports} alt="" />,
              action: (id) => {
                openExportPopup(id);
              },
              tooltip: t("reports"),
              color: "#046c77",
            },
            ...(role !== "manager" && isProgram
              ? [
                  {
                    id: "payment",
                    icon: <img src={timeIcon} alt="payment" />,
                    action: (id) => {
                      if (mode === "Paid") {
                        history.push(`/${role}/program/${id}/payments`);
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("courses_list.cannot_view_payment")}
                          </span>
                        );
                      }
                    },
                    tooltip: t("trainer.program.payment_list"),
                    color: "#a3a9dd",
                  },
                ]
              : []),
            ...(role !== "trainer" && isProgram
              ? [
                  {
                    id: "courses",
                    icon: <img src={CoursesIcon} alt="users" />,
                    action: (id) => {
                      history.push({
                        pathname: `/${role}/program/${id}/courses`,
                        state: { role: role },
                      });
                    },
                    tooltip: t("trainer.program.courses"),
                    color: "#deb364",
                  },
                ]
              : []),
            {
              id: "trainers",
              icon: <img src={UserGroupIconTwo} alt="users" style={{ height: 20, marginTop: "-2px" }} />,
              action: (id, parentId) => {
                history.push({
                  pathname: `${
                    role === "admin"
                      ? "/admin"
                      : role === "group-admin"
                      ? "/group-admin"
                      : role === "manager"
                      ? "/manager"
                      : ""
                  }${
                    programCourses || inProgram
                      ? `/program/${
                          programCourses ? (programId ? programId : program_id) : parentId
                        }/course/${id}/trainers`
                      : isProgram
                      ? `/program/${id}/trainers`
                      : `/course/${id}/trainers`
                  }`,
                  state: { role: role },
                });
              },
              tooltip: t(`courseTrainers`),
              color: "#697593",
            },
            ...(!programCourses
              ? [
                  {
                    id: "certificates",
                    icon: <img src={CertificateIcon} alt="certificate" />,
                    action: (id) => {
                      history.push({
                        pathname: `${role !== "trainer" ? `/${role}` : ""}/trainee/${id}/${
                          isProgram || programCourses ? "program" : "course"
                        }/${status === "Closed" ? "certificates-history" : "certificates"}`,
                        state: { role: role },
                      });
                    },
                    tooltip: t("certificate.certificates"),
                    color: "#FF9947",
                  },
                ]
              : []),

            {
              id: "trainees",
              icon: <img src={UserGroupIcon} alt="users" />,
              action: (id) => {
                history.push({
                  pathname: `${role !== "trainer" ? `/${role}` : ""}${
                    programCourses
                      ? `/program/${programId ? programId : program_id}/course`
                      : isProgram
                      ? "/program"
                      : "/course"
                  }/${id}/trainees`,
                  state: { role: role },
                });
              },
              tooltip: t(`department_trainees.${isProgram ? "program_trainees" : "course_trainees"}`),
              color: "#55C76D",
            },

            // ...(!isProgram && role !== "trainer" && role !== "manager" && role !== "partner"
            //   ? [
            //       {
            //         id: "trainees-payments",
            //         icon: <img src={CoinsIcon} alt="payment" />,
            //         action: (id) => {
            //           history.push({
            //             pathname: `${role === "admin" ? "/admin" : ""}/course/${id}/trainees-payments`,
            //             state: { role: role },
            //           });
            //         },
            //         tooltip: t("trainer.course.trainees_payments"),
            //       },
            //     ]
            //   : []),
            ...(!isProgram
              ? [
                  {
                    id: "view-details",
                    icon: <img src={EyeIcon} alt="" />,
                    action: (id) => {
                      history.push({
                        pathname: `/${role}/course/${id}`,
                        state: { role: role, status: status },
                      });
                    },
                    tooltip: (name) => {
                      return `${t("general.view_details")} ${name}`;
                    },
                    color: "#5A80FF",
                  },
                ]
              : []),

            ...(role !== "partner" && role !== "trainer"
              ? [
                  {
                    id: "edit",
                    icon: <img src={editIcon} alt="" />,
                    action: (id) => {
                      history.push(
                        `/${role === "admin" ? "admin" : role === "manager" ? "manager" : "group-admin"}/${
                          programCourses ? `program/${programId}/course` : isProgram ? "program" : "course"
                        }/edit/${id}`
                      );
                    },
                    tooltip: (name) => {
                      return `${t("general.edit")} ${name}`;
                    },
                    color: "#13B5BD",
                  },
                  {
                    id: "delete",
                    icon: <img src={DeleteIcon} alt="" />,
                    action: (id, status, number_of_users, is_started) => {
                      if (status === "Draft" || (status === "Published" && number_of_users === 0)) {
                        setSelectedCourse({
                          id,
                          name:
                            role === "partner"
                              ? partnerCoursesList.find((course) => course.id === id).name
                              : coursesList.find((course) => course.id === id).name,
                        });
                        setShowDeleteModal(true);
                      } else {
                        if (status === "Closed") {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {isProgram
                                ? t("courses_list.cannot_delete_program_closed")
                                : t("courses_list.cannot_delete_course_closed")}
                            </span>
                          );
                        } else {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {isProgram
                                ? t("courses_list.cannot_delete_program")
                                : t("courses_list.cannot_delete_course")}
                            </span>
                          );
                        }
                      }
                    },
                    tooltip: (name) => {
                      return `${t("general.delete")} ${name}`;
                    },
                    color: "#e62626",
                  },
                ]
              : []),
          ]),
    ];
  };

  const updateCourseStatusHandler = (id) => {
    dispatch(
      updateCourseStatus({
        id,
      })
    );
  };

  const deleteCourseHandler = (id) => {
    dispatch(
      deleteCourse({
        id,
      })
    );
    setShowDeleteModal(false);
  };

  const displayType = (type) => {
    if (type === ".00") {
      return t("general.free");
    } else {
      return t("general.paid");
    }
  };

  const displayMode = (mode) => {
    if (mode === "Free") {
      return t("general.free");
    } else if (mode === "Paid") {
      return t("general.paid");
    }
  };

  const displayStatus = (status) => {
    if (status === "Draft") {
      return t("general.draft");
    } else if (status === "Published") {
      return t("general.published");
    } else if (status === "Closed") {
      return t("general.closed");
    } else if (status === "Archived") {
      return t("general.archived");
    }
  };

  const displayLanguage = (language) => {
    if (language === "ar") {
      return t("arabic");
    } else if (language === "en") {
      return t("english");
    }
  };

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (currentPage * 10 - 10)}</>,
      sortable: false,
    },
    {
      id: "name",
      name: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      key: "name",
      cell: ({ rowData }) => <div className={classes["courses-list__table-name"]}>{rowData?.name || "-"}</div>,
      sortable: false,
      align: "start",
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "code",
      name: t("general.code"),
      key: "code",
      cell: ({ rowData }) => <>{rowData?.code || "-"}</>,
      sortable: false,
    },
    ...(isProgram
      ? []
      : [
          {
            id: "serial_number",
            name: t("general.serial_number"),
            key: "serial_number",
            cell: ({ rowData }) => <>{rowData?.run_serial_number || "-"}</>,
            sortable: false,
          },
        ]),
    ...(!programCourses && !inProgram
      ? [
          {
            id: "status",
            name: t("general.status_Publish"),
            key: "status",
            cell: ({ rowData }) => (
              <span
                className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
                style={{
                  color:
                    rowData.status === "Published"
                      ? "#46BB3E"
                      : rowData.status === "Draft"
                      ? "#8599B6"
                      : rowData.status === "Archived"
                      ? "#8599B6"
                      : rowData.status === "Closed"
                      ? "#E22020"
                      : "normal",
                  backgroundColor:
                    rowData.status === "Published"
                      ? "#C1E2C822"
                      : rowData.status === "Draft"
                      ? "#C1CEE222"
                      : rowData.status === "Archived"
                      ? "#C1CEE222"
                      : rowData.status === "Closed"
                      ? "#E8919122"
                      : "normal",
                }}
              >
                {displayStatus(rowData?.status) || "-"}
              </span>
            ),
            sortable: false,
          },
        ]
      : []),
    ...(!programCourses && !inProgram
      ? [
          {
            id: "mode",
            name: t("general.type"),
            key: "mode",
            cell: ({ rowData }) => (
              <span
                style={{
                  color: rowData?.mode === "Free" ? "#E62626" : "#12C689",
                }}
              >
                {displayMode(rowData?.mode) || "-"}
              </span>
            ),
            sortable: false,
          },
        ]
      : []),
    ...(!programCourses && !inProgram
      ? [
          {
            id: "price",
            name: t("general.price"),
            key: "price",
            cell: ({ rowData }) => (
              <>{rowData?.mode === "Paid" ? `${rowData?.price} ${t("general.rs")}` : null || "-"}</>
            ),
            sortable: false,
          },
        ]
      : []),
    ...(!programCourses
      ? [
          {
            id: "number_of_users",
            name: t("courses_list.number_of_users"),
            key: "number_of_users",
            cell: ({ rowData }) => <>{rowData?.number_of_users} </>,
            sortable: false,
          },
        ]
      : []),
    ...(!programCourses
      ? [
          {
            id: "participators",
            name: t("courses_list.participators"),
            key: "participators",
            cell: ({ rowData }) => <>{rowData?.participators || "-"} </>,
            sortable: false,
          },
        ]
      : []),
    ...(role !== "partner" && !programCourses
      ? [
          {
            id: "creator_name",
            name: t("courses_list.creator_name"),
            key: "creator_name",
            cell: ({ rowData }) => (
              <>{i18n.language === Lang.AR ? rowData?.creator?.name_ar : rowData?.creator?.name_en || "-"}</>
            ),
            sortable: false,
          },
          {
            id: "trainer_manager",
            name: t("courses_list.trainer_manager"),
            key: "trainer_manager",
            cell: ({ rowData }) => <>{rowData?.trainer?.name || "-"} </>,
            sortable: false,
          },
        ]
      : []),
    {
      id: "specialty",
      name: t("courses_list.specialty"),
      key: "specialty",
      cell: ({ rowData }) => (
        <>{i18n.language === Lang.AR ? rowData?.category?.name_ar || "-" : rowData?.category?.name_en || "-"} </>
      ),
      sortable: false,
    },
    {
      id: "department",
      name: t("courses_list.department"),
      key: "department",
      cell: ({ rowData }) => (
        <>{i18n.language === Lang.AR ? rowData?.department_name_ar : rowData?.department_name_en || "-"} </>
      ),
      sortable: false,
    },
    {
      id: "language",
      name: t("general.language"),
      key: "language",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.language === "ar" ? "#E62626" : "#12C689",
          }}
        >
          {displayLanguage(rowData?.language) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "level",
      name: t("general.level"),
      key: "level",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData?.course_level?.value === 1
                ? "#46BB3E"
                : rowData?.course_level?.value === 2
                ? "#8599B6"
                : rowData?.course_level?.value === 3
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData?.course_level?.value === 1
                ? "#C1E2C822"
                : rowData?.course_level?.value === 2
                ? "#C1CEE222"
                : rowData?.course_level?.value === 3
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayLevel(rowData?.course_level?.value) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "number_hours",
      name: t("courses_list.number_hours"),
      key: "number_hours",
      cell: ({ rowData }) => <>{rowData?.number_hours || "-"} </>,
      sortable: false,
    },
    {
      id: "rating",
      name: t("general.rating"),
      key: "general",
      cell: ({ rowData }) => <StarsRating rating={rowData.rating || 0} />,
      sortable: false,
    },
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.start_date ? (
            <>
              {moment(rowData?.start_date).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.start_date)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.end_date ? (
            <>
              {moment(rowData?.end_date).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.end_date)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    ...(!programCourses && !inProgram
      ? [
          {
            id: "start_register_date",
            name: t("trainer.course.start_register_date"),
            key: "start_register_date",
            cell: ({ rowData }) => (
              <>
                {rowData?.start_register_date ? (
                  <>
                    {moment(rowData?.start_register_date).format("DD/MM/YYYY")}
                    <br />
                    {utcToLocal(rowData?.start_register_date)}
                  </>
                ) : (
                  "-"
                )}
              </>
            ),
            sortable: false,
          },
          {
            id: "end_register_date",
            name: t("trainer.course.end_register_date"),
            key: "end_register_date",
            cell: ({ rowData }) => (
              <>
                {rowData?.end_register_date ? (
                  <>
                    {moment(rowData?.end_register_date).format("DD/MM/YYYY")}
                    <br />
                    {utcToLocal(rowData?.end_register_date)}
                  </>
                ) : (
                  "-"
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["courses-list__table__actions"]}>
          {coursesTableActionsList(rowData.mode, rowData.status, rowData.program_id).map((action) => {
            return (
              <button
                key={action.id}
                className={classes["courses-list__table__actions-btn"]}
                title={typeof action.tooltip === "function" ? action.tooltip(rowData.name) : action.tooltip}
                onClick={() => {
                  if (action.id === "edit" && new Date(rowData.end_time) <= Date.now()) {
                    return;
                  }
                  if (action.id === "delete") {
                    action.action(rowData.id, rowData.status, rowData.number_of_users, rowData.is_started);
                    return;
                  }
                  action.action(rowData.id, rowData.program_id);
                }}
                style={action?.color ? { border: `1px solid ${action.color}` } : {}}
                disabled={action.id === "edit" && new Date(rowData.end_time) <= Date.now()}
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      ),
      sortable: false,
    },
    ...(role !== "partner" && role !== "trainer" && role !== "content_developer" && !programCourses
      ? [
          {
            id: "status",
            name: t("general.status"),
            key: "status",
            cell: ({ rowData }) => (
              <SwitchToggle
                status={rowData.is_visible}
                id={rowData.id}
                onStatusChange={() => {
                  updateCourseStatusHandler(rowData.id);
                }}
              />
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const exportPopupRef = useRef(null);
  const [exportPopup, setExportPopup] = useState(false);
  const openExportPopup = (id) => {
    setProgram_id(id);
    setExportPopup(true);
    exportPopupRef?.current?.showModal();
  };

  const closeExportPopup = () => {
    setExportPopup(false);
    exportPopupRef?.current?.dismissModal();
  };

  return (
    <>
      <MainTable
        columns={columns}
        data={role === "partner" && !programCourses ? partnerCoursesList : coursesList || []}
        isLoading={coursesListLoading || partnerCoursesListLoading}
      />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={!isProgram ? t("courses_delete_title") : t("program_delete_title")}
          name={selectedCourse.name}
          confirmMsg={t("Exhibition.Exhibition_delete_confirmMsg")}
          confirmText={t("Exhibition.confirm")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            deleteCourseHandler(selectedCourse.id);
          }}
          isLoading={deleteCourseLoading}
        />
      )}
      <BasicModal ref={exportPopupRef}>
        <div
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-8  tw-text-center tw-space-y-4"
          style={{
            borderRadius: "20px",
            backgroundColor: "#fff",
            width: "350px",
          }}
        >
          <div className="tw-flex tw-items-center tw-justify-start tw-w-full">
            <div className="tw-cursor-pointer" onClick={() => closeExportPopup()}>
              <img src={CloseIcon} alt="" className="tw-w-6 tw-h-6 tw-mr-2" />
            </div>
          </div>

          <img src={ViewReports} alt="" className="tw-w-20 tw-h-20 tw-mb-4" />

          <div className="tw-text-lg tw-font-bold tw-text-gray-700 tw-mb-4" style={{ color: "#555555" }}>
            {t("export_type")}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center" style={{ gap: "10px", width: "100%" }}>
            <button
              type="button"
              onClick={() => {
                isProgram ? exportOneProgramHandler("xlsx", program_id) : exportOneCourseHandler("xlsx", program_id);
                closeExportPopup();
              }}
              className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none -tw-top-4 export-btn"
              style={{ backgroundColor: "#ededed", width: "100%" }}
            >
              <img
                src={XlsIcon}
                alt=""
                style={{
                  backgroundColor: "#138d5f",
                  border: "none",
                  padding: 3,
                  borderRadius: "20%",
                  marginLeft: 6,
                  marginRight: 6,
                }}
              />
              {t("exprot_as_xlsx")}
            </button>
            <button
              type="button"
              onClick={() => {
                isProgram ? exportOneProgramHandler("pdf", program_id) : exportOneCourseHandler("pdf", program_id);
                closeExportPopup();
              }}
              className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none export-btn tw-mb-4"
              style={{ backgroundColor: "#ededed", width: "100%" }}
            >
              <img
                src={PdfIcon}
                alt=""
                style={{
                  backgroundColor: "#b91414",
                  border: "none",
                  padding: 3,
                  borderRadius: "20%",
                  marginLeft: 6,
                  marginRight: 6,
                }}
              />
              {t("exprot_as_pdf")}
            </button>
          </div>
        </div>
      </BasicModal>
    </>
  );
};

export default CoursesListTable;

import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import {
  exportCourseTraineesActivities,
  getActivitiesStatement,
} from "../../../../Services/api/CourseTrainerList";

export default function ActivitiesStatement({ role, isTrainee = false }) {
  require("./List.css");

  const { t } = useTranslation();
  const { courseId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [trainees, setTrainees] = useState([]);
  const [activities, setActivities] = useState([]);
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.score != undefined && currentFilter.score != null) {
      const parseValue = JSON.parse(currentFilter.score);
      currentFilter = {
        ...currentFilter,
        min_activity_score: parseValue.min,
        max_activity_score: parseValue.max,
      };

      delete currentFilter.score;
    }

    function boolToInt(value) {
      return value === "true" ? 1 : value === "false" ? 0 : null;
    }

    return currentFilter;
  }

  const exportHandler = async (type) => {
    try {
      setIsUpdating(true);
      await exportCourseTraineesActivities(type, courseId, getFilter());
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      title: t("filter"),
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            title: t(`${t("trainer.quiz.export")} PDF`),
            action: () => exportHandler("pdf"),
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            title: t(`${t("trainer.quiz.export")} Excel`),
            action: () => exportHandler("xlsx"),
          },
        ]
      : []),
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    {
      id: "name",
      name: t("student_name"),
      key: "name",
      sortable: false,
      cell: ({ rowData }) => <>{rowData.name}</>,
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => <span className="cell-font">{rowData.email}</span>,
    },
    ...activities.map((activity) => ({
      id: activity.id,
      name: activity.title,
      key: activity.id,
      cell: ({ rowData }) => {
        let answer =
          activity.users_answers.find((ans) => ans.user_id == rowData.id) ?? {};
        return (
          <span className="cell-font">
            <>
              {answer.score || answer.score === 0 ? (
                <>
                  {" "}
                  {Number(answer.score).toFixed(2)}(
                  {activity.activity_mark
                    ? Math.round(
                        (parseFloat(answer.score) /
                          parseFloat(activity.activity_mark)) *
                          10000
                      ) / 100
                    : 0}
                  %)
                </>
              ) : (
                <></>
              )}
            </>
          </span>
        );
      },
    })),
  ];

  const filterFields = [
    // name
    ...(isTrainee
      ? []
      : [
          {
            id: "name",
            component: "input",
            type: "text",
            placeholder: t("name"),
            label: t("name"),
            name: "name",
            value: filter.name ?? "",
            classes: "col-12 col-md-4",
            onReset: () =>
              filterChangeHandler({ target: { value: "", name: "name" } }),
            onchange: filterChangeHandler,
          },
          // email
          {
            id: "email",
            component: "input",
            type: "text",
            placeholder: t("email"),
            label: t("email"),
            name: "email",
            value: filter.email ?? "",
            classes: "col-12 col-md-4",
            onReset: () =>
              filterChangeHandler({ target: { value: "", name: "email" } }),
            onchange: filterChangeHandler,
          },
          // hide_empty
          {
            id: "hide_empty",
            component: "select",
            placeholder: t("hide_records_by"),
            label: t("hide_records_by"),
            initialValue: t("hide_records_by"),
            name: "hide_empty",
            value: filter.hide_empty ?? "",
            classes: "col-12 col-md-2",
            onchange: filterChangeHandler,
            options: [
              { value: 1, label: t("empty_records") },
              { value: 2, label: t("student_grade") },
            ],
          },
        ]),
    // score
    {
      id: "score",
      component: "select",
      placeholder: t("student_grade"),
      label: t("student_grade"),
      initialValue: t("student_grade"),
      name: "score",
      value: filter.score ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 60, min: 51 }),
          label: "51 - 60",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 70, min: 61 }),
          label: "61 - 70",
        },
        {
          id: "8",
          value: JSON.stringify({ max: 80, min: 71 }),
          label: "71 - 80",
        },
        {
          id: "9",
          value: JSON.stringify({ max: 90, min: 81 }),
          label: "81 - 90",
        },
        {
          id: "10",
          value: JSON.stringify({ max: 100, min: 91 }),
          label: "91 - 100",
        },
      ],
    },
    // activity_title
    {
      id: "activity_title",
      component: "input",
      type: "text",
      placeholder: t("activity_title"),
      label: t("activity_title"),
      name: "activity_title",
      value: filter.activity_title ?? "",
      classes: "col-12 col-md-4",
      onReset: () =>
        filterChangeHandler({ target: { value: "", name: "activity_title" } }),
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        setFilterPayload({});
        if (currentPage == 1) {
          refetchActivities({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        setFilterPayload(getFilter());
        if (currentPage == 1) {
          refetchActivities();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchStatement = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getActivitiesStatement(courseId, params);
        if (response.status == 200) {
          setActivities(response.data.data.activities);
          setTrainees(response.data.data.trainees);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchActivities = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchStatement(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchActivities();
  }, [currentPage, order]);

  const breadcrumbList = isTrainee
    ? [
        {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        },
        {
          id: t("Courseoutline"),
          page: t("Courseoutline"),
          pagePath: `/training-courses/${courseId}/content`,
        },
        {
          id: t("activities_scores"),
          page: t("activities_scores"),
          active: true,
        },
      ]
    : [
        {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        },

        {
          id: t("Courseoutline"),
          page: t("Courseoutline"),
          pagePath: `/${role}/course/${courseId}`,
        },
        {
          id: t("activities_scores"),
          page: t("activities_scores"),
          active: true,
        },
      ];

  return (
    <>
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead
                title={t("trainer.course.lesson.activities")}
                actions={actionsList}
              />
              {isFilter && <FilterBox fields={filterFields} />}
              <MainTable
                columns={columns}
                data={trainees}
                sortAction={sortActionHandler}
              />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

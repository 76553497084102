import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";

import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import { fetchSpecialty } from "../../../../Redux/Actions/specialties/specialtiesDetails.action";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SpecialtiesDetailsFilter from "./SpecialtiesDetailsFilter";
import SpecialtiesDetailsPreview from "./SpecialtiesDetailsPreview";
import SpecialtiesDetailsTable from "./SpecialtiesDetailsTable";
import { filterActions } from "./SpecialtiesDetailsTableAction";
import classes from "./specialtiesDetails.module.css";
import { exportSpecialtiesDetialsList } from "../../../../Services/api/SpecialtiesList";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { useLocation } from "react-router-dom";

const SpecialtiesDetails = ({ role }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { departments, specialty, specialtyMeta } = useSelector(
    (state) => state.specialtiesDetailsReducer
  );
  // console.log(specialty);

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [currentTaxFilter, setCurrentTaxFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    description: "",
    created_at: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("specialties"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin/category`
          : localStorage.getItem("type") == "manager"
          ? `/manager/category`
          : "/admin/category",
    },
    {
      id: "create_exhibition",
      page:
        (specialty || {})?.length > 0
          ? i18n.language == Lang.AR
            ? specialty?.name_ar
            : specialty?.name_en
          : location?.state?.name,
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      description: "",
      created_at: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.description && (filter.description = filterData.description);
    filterData.specialty && (filter.specialty_id = filterData.specialty);
    filterData.created_at && (filter.date = filterData.created_at);
    return filter;
  };
  const getSpecialtyHandler = async () => {
    const filter = getFilterData();
    dispatch(
      fetchSpecialty({
        id,
        page: currentPage,
        perPage: 10,
        filter,
        history,
      })
    );
    setCurrentTaxFilter(filter);
  };
  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportSpecialtiesDetialsList(id, currentTaxFilter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    if (!id) return;
    getSpecialtyHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [id, currentPage, isResetApplied]);
  console.log(specialty);

  return (
    <div className={classes["specialties-details"]}>
      <div className="container-fluid">
        {isExportLoading && <SkeletonCardOverlay />}
        <div className={classes["specialties-details__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <SpecialtiesDetailsPreview
                  title={
                    i18n.language == Lang.AR
                      ? specialty?.name_ar
                      : specialty?.name_en
                  }
                  description={
                    i18n.language == Lang.AR
                      ? specialty?.description_ar
                      : specialty?.description_en
                  }
                  descriptionLabel={t("general.description")}
                />
                {/* <DepartmentsListTableContainer /> */}

                <MainBoxHead
                  title={t("departments")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      if (role === "groupAdmin") {
                        history.push("/group-admin/department/add", {
                          specialtyId: id,
                          specialtyName:
                            specialty?.length > 0
                              ? i18n.language == Lang.AR
                                ? specialty?.name_ar
                                : specialty?.name_en
                              : location?.state?.name,
                        });
                      } else if (role === "manager") {
                        history.push("/manager/department/add", {
                          specialtyId: id,
                          specialtyName:
                            specialty?.length > 0
                              ? i18n.language == Lang.AR
                                ? specialty?.name_ar
                                : specialty?.name_en
                              : location?.state?.name,
                        });
                      } else {
                        history.push("/admin/department/add", {
                          specialtyId: id,
                          specialtyName:
                            specialty?.length > 0
                              ? i18n.language == Lang.AR
                                ? specialty?.name_ar
                                : specialty?.name_en
                              : location?.state?.name,
                        });
                      }
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <SpecialtiesDetailsFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={() => {
                      getSpecialtyHandler();
                    }}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <SpecialtiesDetailsTable role={role} />
                <Pagination
                  count={specialtyMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialtiesDetails;

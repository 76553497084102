import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { fetchTraineeCertificatesList } from "../../../Redux/Actions/certificates/trainee-certificates.action";
import { exportTraineeCertificates } from "../../../Services/api/certificate/traineeCertificatesProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import { FilterActions } from "./TraineeCertificatesActions";
import TraineeCertificatesFilter from "./TraineeCertificatesFilter";
import TraineeCertificatesTable from "./TraineeCertificatesTable";
import classes from "./traineeCertificates.module.css";

const TraineeCertificates = ({ isAdmin, isTrainer, isHistory }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id, source } = useParams();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = localStorage.getItem("type");

  const { traineeCertificatesListMeta } = useSelector(
    (state) => state.traineeCertificatesReducer
  );
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({
    user_name: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(isTrainer
      ? [
          {
            id: "content_development",
            page: t("sidebar.label.private_profile"),
            pagePath: "/private-profile",
          },
          ...(source
            ? [
                {
                  id: `${source === "program" ? "programs" : "courses"}`,
                  page: t(
                    `sidebar.label.${
                      source === "program" ? "program" : "courses"
                    }`
                  ),
                  pagePath:
                    source === "program"
                      ? `/trainer/${
                          isHistory ? "programs-history" : "programs"
                        }`
                      : `/trainer/${isHistory ? "courses-history" : "courses"}`,
                },
              ]
            : []),
          {
            id: `content_development_tax`,
            page: t("certificate.certificates"),
            pagePath: "#",
            active: true,
          },
        ]
      : [
          {
            id: "content_development",
            page: t("sidebar.label.content_development"),
            pagePath: "#",
          },
          ...(source
            ? [
                {
                  id: `${source === "program" ? "programs" : "courses"}`,
                  page: t(
                    `sidebar.label.${
                      source === "program" ? "program" : "courses"
                    }`
                  ),
                  pagePath:
                    source === "program"
                      ? `/${localStorage.getItem("type")}/program/list`
                      : `/${localStorage.getItem("type")}/courses`,
                },
              ]
            : []),
          {
            id: `content_development_tax`,
            page: t("certificate.certificates"),
            pagePath: "#",
            active: true,
          },
        ]),
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      user_name: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.user_name && (filter.user_name = filterData.user_name);
    return filter;
  };

  const getCertificatesListHandler = () => {
    const filter = getFilterData();
    dispatch(
      fetchTraineeCertificatesList({
        id,
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  useEffect(() => {
    getCertificatesListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportTraineeCertificates(id, type, filter);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["certificates"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["certificates__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("certificate.certificates")}
                  actions={FilterActions({
                    showFilter: showFilterHandler,

                    selectCertificateTemplate: isHistory
                      ? null
                      : () => {
                          history.push({
                            pathname: `${
                              role !== "trainer" ? `/${role}` : ""
                            }/certificateTemplates${
                              source === "program" ? "/program" : ""
                            }/${id}`,
                            state: { role: role },
                          });
                        },

                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <TraineeCertificatesFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getCertificatesListHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <TraineeCertificatesTable />
                <Pagination
                  count={traineeCertificatesListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeCertificates;

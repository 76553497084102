import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function certificateList() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.certificates)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function myCertificates({ page = 1, perPage = 10 }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.my_certificates, {
          params: {
            page: page,
            perPage: perPage,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
//
export function getcertificate(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.certificates_download, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function checkProgramCertificate(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.program_certificate, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getProgramCertificate(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.certificates_download_program, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getDownloadcertificate(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.certificates_download, { id: id }), {
          responseType: "blob",
          params: {
            export: "pdf",
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

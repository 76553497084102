import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NewBannerImage from "../../../assets/image/newBanner.png";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import classes from "./Banner.module.css";
export default function Banner() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div
      className={classes.BannerCont}
      style={{ backgroundImage: `url(${NewBannerImage})`, backgroundSize: "cover", backgroundPosition: "center" }}
    >
      {i18n.language === "ar" && <div className={classes.second} />}
      <div className={classes.first}>
        <p className={classes.maintext}>{t("banner_main_text")}</p>
        <CustomButton
          value={t("enroll")}
          colors={"#fff"}
          classes={classes.btnRegister}
          action={() => (localStorage.type ? history.push("/training-courses") : history.push("/register"))}
        />
      </div>
      {i18n.language === "en" && <div className={classes.second} />}
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getAllTrainers } from "../../../Services/api/HomeCoursesProvider";
import { TrainerContext } from "../../../Services/api/toutorials/trainerContext";
import { calcNumChunk, chunk } from "../../../utils";
import UserCard from "../../Shared/Components/UserCard/UserCard";
import TrainerSpinnerCard from "../../trainers/TrainerSpinnerCard";
import classes from "./trainers.module.css";

function Trainers() {
  require("./trainers.css");
  const { t, i18n } = useTranslation();
  const [trainersLoading, setTrainersLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const trainerContext = useContext(TrainerContext);
  let trainerData = [];
  const size = calcNumChunk(trainerContext.trainers.length, 4);
  trainerData = chunk(trainerContext.trainers, size);

  const trainerSpinner = [];

  for (let i = 0; i < trainerContext.initialSpinnersCarousel; i++) {
    trainerSpinner.push(<TrainerSpinnerCard key={i} />);
  }

  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAllTrainers;

    apiCall({ perPage: 10, page: 1 })
      .then((res) => {
        if (res.status && res.status === 200) {
          console.log(res);
          setTrainers(res.data.response.data);

          setTrainersLoading(true);
        }
        setTrainersLoading(true);
      })
      .catch((err) => {
        setTrainersLoading(true);
        console.log(err);
      });
  }, []);

  return (
    <section className="trainer_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="trainers">
              <div className={classes.titleCoureses}>
                <div className={classes.titleFont}>{t("ProfessionalTrainers")}</div>
              </div>
              <div className={classes.carsolDiv}>
                {trainers
                  .filter((item, index) => index < 4)
                  .map((item) => (
                    <NavLink to={`/trainers/trainer-details/${item.id}`}>
                      <UserCard
                        name={i18n.language === "en" ? item.name_en : item.name_ar}
                        rate={item.rating}
                        postionTitle={item.job_title_en}
                        description={item.description_ar}
                        img={item.img}
                        countCourses={item.countCourses}
                        countUsers={item.countUsers}
                        trainer={item}
                      />
                    </NavLink>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trainers;

import { default as URLBuilder } from "../../Services/api/UrlBuilder";
import env from "./Environment";
import connector from "./Handler";

export function exportCoursesList(data, export_type, role, isProgram, programCourses) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.courses_list, { role: role }), {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
            role: role,
            type: isProgram ? "program" : programCourses ? "courseInProgram" : "course",
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", isProgram ? `Programs.${export_type}` : `Courses.${export_type}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportPartnerCoursesList(status, export_type, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.partner_courses_list, { type: type }), {
          responseType: "blob",
          params: {
            status: status,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Partner ${type}.${export_type}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportOneProgram(export_type, id, programCourses) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.export_one_program, { id: id }), {
          responseType: "blob",
          params: {
            export: export_type,
            type: programCourses ? "courseInProgram" : "program",
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Courses" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportOneCourse(export_type, id, programCourses) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.export_one_course, { id: id }), {
          responseType: "blob",
          params: {
            export: export_type,
            type: programCourses ? "courseInProgram" : "course",
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Courses" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllCourses(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_courses, {
          params: { ...params },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllPaymentsForProgram(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.peyment_programs_setting, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

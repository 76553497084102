import { useEffect, useState } from "react";
import TrainersDetailsHeader from "./TrainerDetailsHeader/TrainerDetailsHeader";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import SkeletonSpinnerOverlay from "../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import homeIcon from "../../assets/icons/Group 3.svg";
import {
  getTrainerCourse,
  getTrainerDetailsById,
  resetTrainer,
} from "../../Redux/Actions/trainerProfile/trainerProfile.action";
import Title from "../Shared/Components/Title/Title";
import classes from "./TrainersInfoDetails.module.css";
import TrainersCourseDetails from "./TrainersCourseDetails/TrainersCourseDetails";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";

function TrainersDetails() {
  require("./TrainersInfoDetails.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { get_trainer_details, get_trainer_course, loading } = useSelector(
    (state) => state.trainerProfileReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [trainerDetails, setTrainerDetails] = useState([]);
  const [trainerCourses, setTrainerCourses] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loadMoreButton, setLoadMoreButton] = useState();
  // const [loading1, setLoading1] = useState(true);

  useEffect(() => {
    dispatch(resetTrainer());
    // setLoading1(true);
    setTrainerCourses([]);
  }, []);

  useEffect(() => {
    let params = {
      perPage: 9,
      page: currentPage,
    };
    dispatch(getTrainerDetailsById(id));
    dispatch(getTrainerCourse(id, params));
  }, [id, currentPage]);

  useEffect(() => {
    if (get_trainer_details.status === 200) {
      setTrainerDetails(get_trainer_details.data.response.trainer);
      // setMeta(get_trainer_course.data.response.meta);
    }
  }, [get_trainer_details, currentPage]);

  useEffect(() => {
    if (get_trainer_course.status === 200) {
      if (loadMoreButton) {
        setTrainerCourses((course) => [
          ...course,
          ...get_trainer_course.data.response.data,
        ]);
      } else {
        // setLoading1(true);
        setTrainerCourses(get_trainer_course.data.response.data);
      }
      setMeta(get_trainer_course.data.response.meta);
      setLoadMoreButton(false);
      // setLoading1(false);
    }
  }, [get_trainer_course]);

  return (
    <>
      {!loading ? (
        <>
          <TrainersDetailsHeader
            trainerDetails={trainerDetails}
            loading={loading}
          />

          <div
            className={
              "container-fluid" + " " + classes.training_course_content_info
            }
          >
            <div
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <img src={homeIcon} alt="" />
              <Title
                title={t("Coursesoffered")}
                fontSize={24}
                titleColor="#012327"
              />
            </div>
            {loading ? (
              <div className={classes["loading__container"]}>
                <LoadingSpinner />
              </div>
            ) : (
              <TrainersCourseDetails
                trainerCourses={trainerCourses}
                loading={loading}
              />
            )}

            <Pagination
              count={meta?.totalPages || 0}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
              page={currentPage}
            />
          </div>
        </>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

export default TrainersDetails;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AppsIcon from "../../../assets/icons/apps.svg";
import BookIcon from "../../../assets/icons/Book.svg";
import CertificateIcon from "../../../assets/icons/Certificate.svg";
import homeWallet from "../../../assets/icons/homeWallet.svg";
import TrainersIcon from "../../../assets/icons/newTrainers.png";
import PublishRequestIcon from "../../../assets/icons/publishRequest.png";
import classes from "./homeActions.module.css";

const HomeActions = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const isLoggedIn = localStorage.getItem("token");
  const role = localStorage.getItem("type");

  const actions = useMemo(
    () => [
      ...(!isLoggedIn
        ? [
            {
              title: t("NewTrainingCourses"),
              icon: BookIcon,
              action: () => {
                const element = document.getElementById("new-courses");
                element.scrollIntoView({ behavior: "smooth" });
              },
            },
            {
              title: t("Trainers"),
              icon: TrainersIcon,
              action: () => history.push("/trainers"),
            },
            {
              title: t("course_publish_request"),
              icon: PublishRequestIcon,
              action: () => history.push("/publish-request"),
            },
          ]
        : role === "trainee"
        ? [
            {
              title: t("my_current_courses"),
              icon: BookIcon,
              action: () => history.push("/private-profile/courses"),
            },
            {
              title: t("my_current_programs"),
              icon: AppsIcon,
              action: () => history.push("/private-profile/programs"),
            },
            {
              title: t("my_certificates"),
              icon: CertificateIcon,
              action: () => history.push("/private-profile/certificates"),
            },
            {
              title: t("my_wallet"),
              icon: homeWallet,
              action: () => history.push("/private-profile/wallet"),
            },
          ]
        : [
            {
              title: t("NewTrainingCourses"),
              icon: BookIcon,
              action: () => {
                const element = document.getElementById("new-courses");
                element.scrollIntoView({ behavior: "smooth" });
              },
            },
            {
              title: t("list_tickets"),
              icon: AppsIcon,
              action: () => history.push(`/tickets`),
            },
            {
              title: t("course_publish_request"),
              icon: PublishRequestIcon,
              action: () => history.push("/publish-request"),
            },
          ]),
    ],
    [t, history]
  );

  return (
    <div className="container-fluid">
      <div className={classes.homeActions}>
        {actions.map((action, index) => (
          <div key={index} className={classes.homeAction} onClick={action.action}>
            <img src={action.icon} alt={action.title} />
            <span>{action.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeActions;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BooksIcon from "../../../assets/icons/books.svg";
import BriefTrainerIcon from "../../../assets/icons/briefTrainer.svg";
import BriefImage from "../../../assets/image/briefImage.png";
import { getAllStatistics } from "../../../Services/api/HomeCoursesProvider";
import classes from "./BriefSection.module.css";

export default function BriefSection() {
  const { t, i18n } = useTranslation();
  const [statistics, setStatistics] = useState({});
  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAllStatistics;

    apiCall({ perPage: 10, page: 1 })
      .then((res) => {
        if (res.status && res.status === 200) {
          console.log(res);
          setStatistics(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className={classes.briefContainer}>
        <div className={classes.briefImage}>
          <img src={BriefImage} alt="briefImage" />
        </div>
        <div className={classes.briefContent}>
          <span>{t("brief_about")}</span>
          <h2>{t("the_university")}</h2>
          <p>{t("brief_description")}</p>
          <div className={classes.statistics}>
            <div className={classes.statisticsOne}>
              <img src={BooksIcon} alt="books" />
              <div className={classes.statisticsText}>
                <p>{statistics.courses}</p>
                <span>{t("courses")}</span>
              </div>
            </div>
            <div className={classes.statisticsTwo}>
              <img src={BriefTrainerIcon} alt="trainers" />
              <div className={classes.statisticsText}>
                <p>{statistics.trainees}</p>
                <span>{t("trainees")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

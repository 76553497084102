import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./Redux/store";
import { FooterProvider } from "./Services/api/toutorials/FooterContext";
import "./assets/style/index.css";
import "./assets/style/tailwind-output.css";
import theme from "./theme";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FooterProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </FooterProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

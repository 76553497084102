import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartmentList } from "../../../Redux/Actions/departments/departmentList.action";
import { getAllSpecialties } from "../../../Services/api/HomeCoursesProvider";
import { Lang } from "../../../utils";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const PublishRequestsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const { departmentList } = useSelector((state) => state.departmentListReducer);
  const getDepartmentsListHandler = async () => {
    dispatch(
      fetchDepartmentList({
        filter: {},
      })
    );
  };
  useEffect(() => {
    getAllSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const optionsSpecialties = res.data.data.specialties.data.map((item) => ({
            label: i18n.language === "ar" ? item.name_ar : item.name_en,

            id: item.id,
            value: item.id,
          }));
          setSpecialtiesOptions(optionsSpecialties);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartmentsListHandler();
  }, []);
  const levelsOptions = [
    {
      value: 1,
      id: 1,
      label: i18n.language == "ar" ? "مبتدئ" : "Beginner",
    },
    {
      id: 2,
      value: 2,
      label: i18n.language == "ar" ? "متوسط" : "Average",
    },
    {
      id: 3,
      value: 3,
      label: i18n.language == "ar" ? "متقدم" : "Advanced",
    },
  ];
  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: "active",
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: "inactive",
    },
  ];
  const coursesListModes = [
    { id: 1, value: 1, label: t("crud.values.paid") },
    { id: 1, value: 0, label: t("Exhibition.free") },
  ];
  const userTypes = [
    {
      id: 1,
      value: "Trainee",
      label: i18n.language == "ar" ? "متدرب" : "Trainee",
    },
    {
      id: 1,
      value: "Guest",
      label: i18n.language == "ar" ? "ضيف" : "Guest",
    },
  ];

  const filterFields = [
    {
      id: "user_name",
      component: "input",
      label: t("user_name"),
      type: "text",
      placeholder: t("user_name"),
      name: "user_name",
      value: filterData.user_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "user_email",
      component: "input",
      label: t("email"),
      type: "text",
      placeholder: t("email"),
      name: "user_email",
      value: filterData.user_email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "user_type",
      component: "select",
      label: t("user_type"),
      placeholder: t("user_type"),
      initialValue: t("user_type"),
      name: "user_type",
      value: filterData.user_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: userTypes || [],
    },
    {
      id: "title",
      component: "input",
      label: t("title"),
      type: "text",
      placeholder: t("title"),
      name: "title",
      value: filterData.title,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "speciality_id",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "speciality_id",
      value: filterData.speciality_id,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: specialtiesOptions || [],
    },
    {
      id: "department_id",
      label: t("courses_list.department"),
      component: "select",
      placeholder: t("courses_list.department"),
      initialValue: t("courses_list.department"),
      name: "department_id",
      value: filterData.department_id,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: departmentList?.length
        ? departmentList?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label: i18n.language === Lang.AR ? trainer.name_ar : trainer.name_en,
          }))
        : [],
    },

    {
      id: "available_from",
      label: t("available_from"),
      component: "datePicker",
      placeholder: t("available_from"),
      name: "available_from",
      value: filterData.available_from,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "available_to",
      label: t("available_to"),
      component: "datePicker",
      placeholder: t("available_to"),
      name: "available_to",
      value: filterData.available_to,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "level",
      label: t("level"),
      component: "select",
      placeholder: t("level"),
      initialValue: t("level"),
      name: "level",
      value: filterData.level,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: levelsOptions || [],
    },
    {
      id: "is_paid",
      label: t("general.type"),
      component: "select",
      placeholder: t("general.type"),
      initialValue: t("general.type"),
      name: "is_paid",
      value: filterData.is_paid,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListModes || [],
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default PublishRequestsFilter;

import React from "react";
import classes from "./button.module.css";
const Button = ({
  type,
  action,
  children,
  backgroundColor = "rgb(3, 162, 178)",
  color = "#ffffff",
  borderColor = "none",
}) => {
  return (
    <button
      className={classes["button"]}
      type={type}
      onClick={action}
      style={{
        backgroundColor,
        color,
        border: borderColor === "none" ? "none" : `1px solid ${borderColor}`,
      }}
    >
      {children}
    </button>
  );
};

export default Button;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ArrowIcon from "../../../assets/icons/right-arrow-white.svg";
import { finanicalDashboard } from "../../../Services/api/Financial/FinancialProvider";
import { getSurveysStatistics } from "../../../Services/api/survey/SurveyProvider";
import Title from "../../Shared/Components/Title/Title";
import CounterNumbersListTop from "./counterNumbers/CounterNumbersListTop";
import classes from "./dashboaerd.module.css";

function Dashboard(props) {
  require("./Dashboard.css");
  const { t } = useTranslation();
  let str = props?.location?.pathname;
  const history = useHistory();
  const locationStr = str.split("/");
  const [isLoading, setIsLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [courses, setCourses] = useState([]);
  const [counter, setCounter] = useState();
  const [setting, setSetting] = useState(false);
  const [countSurveys, setCountSurveys] = useState([]);
  const [countParticipants, setCountParticipants] = useState([]);
  const [countAnswerd, setCountAnswerd] = useState([]);
  const [countNotAnswerd, setCountNotAnswerd] = useState([]);
  const [doughnutChart, setDoughnutChart] = useState(
    localStorage.getItem("doughnutChart")
      ? localStorage.getItem("doughnutChart")
      : 0
  );
  const [counterNumbers, setCounterNumbers] = useState(
    localStorage.getItem("counterNumbers")
      ? localStorage.getItem("counterNumbers")
      : 0
  );
  const [barChart, setBarChart] = useState(
    localStorage.getItem("barChart") ? localStorage.getItem("barChart") : 0
  );
  const [barChartSurveys, setBarChartSurveys] = useState(
    localStorage.getItem("barChartSurveys")
      ? localStorage.getItem("barChartSurveys")
      : 0
  );

  const fetchFinancialDashboard = () => {
    finanicalDashboard()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          console.log("------->", res.data.data);
          setCounter(res.data.data);
          setCoupons(res.data.data.coupons);
          setCourses(
            res.data && res.data.data.courses,
            fetchSurveysStatistics()
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };

  const fetchSurveysStatistics = () => {
    getSurveysStatistics()
      .then((res) => {
        const newSurveysArr = Object.values(
          res.data.statistics.surveys.reduce((survey, item) => {
            survey[item.survey_id] = survey[item.survey_id]
              ? {
                  ...item,
                  total_response:
                    item.total_responses +
                    survey[item.survey_id].total_responses,
                  total_user:
                    item.total_users + survey[item.survey_id].total_users,
                }
              : item;
            return survey;
          }, {})
        );

        let countSurveys = [];
        let countParticipants = [];
        let countAnswerd = [];
        let countNotAnswerd = [];
        if (res.status && res.status == 200 && res.data.status) {
          newSurveysArr.map((item, i) => {
            item.total_not_response =
              item.total_user == undefined
                ? item.total_users - item.total_responses
                : item.total_user - item.total_response;
            countSurveys.push(item.survey_name);
            countParticipants.push(item.total_user);
            countAnswerd.push(item.total_response);
            countNotAnswerd.push(item.total_not_response);
          });
          setCountParticipants(countParticipants);
          setCountAnswerd(countAnswerd);
          setCountNotAnswerd(countNotAnswerd);
          setCountSurveys(countSurveys);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };

  useEffect(() => {
    // fetchFinancialDashboard();
  }, []);

  const counterNumbersHandel = (e) => {
    localStorage.setItem("counterNumbers", e.target.value);
    setCounterNumbers(e.target.value);
  };

  const barChartHandel = (e) => {
    localStorage.setItem("barChart", e.target.value);
    setBarChart(e.target.value);
  };

  const doughnutChartHandel = (e) => {
    localStorage.setItem("doughnutChart", e.target.value);
    setDoughnutChart(e.target.value);
  };

  const barChartSurveysHandel = (e) => {
    localStorage.setItem("barChartSurveys", e.target.value);
    setBarChartSurveys(e.target.value);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={`container-fluid ${classes.headerContent}`}>
          <div className={classes.title}>
            <Title
              title={t("admin.label.dashboard")}
              titleColor="#fff"
              fontSize={36}
            />
            {/* <p>مرحباً بك </p> */}
          </div>

          {/* <div style={{ flex: "1 1 auto" }}>
            <div className={classes["back-share"]}>
              <div className={classes["shareBackBtn"]} onClick={() => history.goBack()}>
                <img src={ArrowIcon} alt="" className={classes["arrowIcon"]} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* {localStorage.getItem("type") == "admin" ? (
        <>
          <div className="setting-admin" onClick={() => setSetting(!setting)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="animate"
            >
              <path d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z" />
            </svg>
          </div>
          {setting && (
            <div className="card-setting">
              <div
                style={{
                  width: "100%",
                }}
              >
                <div className="">{t("dashBoard.label.otherDetails")}</div>
                <div className="radio-card">
                  <div className="">
                    <input
                      type="radio"
                      value="0"
                      name="group1"
                      checked={counterNumbers == "0"}
                      onChange={counterNumbersHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.hide")}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="1"
                      name="group1"
                      checked={counterNumbers == "1"}
                      onChange={counterNumbersHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.show")}</label>
                  </div>
                </div>
              </div>
              <hr />
              <div
                style={{
                  width: "100%",
                }}
              >
                <div className="">{t("dashBoard.label.couponsShow")}</div>
                <div className="radio-card">
                  <div className="">
                    <input
                      type="radio"
                      value="0"
                      name="doughnutChart"
                      checked={doughnutChart == "0"}
                      onChange={doughnutChartHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.hide")}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="1"
                      name="doughnutChart"
                      checked={doughnutChart == "1"}
                      onChange={doughnutChartHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.show")}</label>
                  </div>
                </div>
              </div>
              <hr />
              <div
                style={{
                  width: "100%",
                }}
              >
                <div className="">{t("dashBoard.label.costsRevenue")}</div>
                <div className="radio-card">
                  <div className="">
                    <input
                      type="radio"
                      value="0"
                      name="bar1"
                      checked={barChart == "0"}
                      onChange={barChartHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.hide")}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="1"
                      name="bar1"
                      checked={barChart == "1"}
                      onChange={barChartHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.show")}</label>
                  </div>
                </div>
                <div style={{ marginTop: "10%" }} className="">
                  {t("dashBoard.label.surveysStatistics")}
                </div>
                <div className="radio-card">
                  <div className="">
                    <input
                      type="radio"
                      value="0"
                      name="bar2"
                      checked={barChartSurveys == "0"}
                      onChange={barChartSurveysHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.hide")}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="1"
                      name="bar2"
                      checked={barChartSurveys == "1"}
                      onChange={barChartSurveysHandel}
                    />
                    <label htmlFor="">{t("dashBoard.label.show")}</label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null} */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ marginTop: "-130px" }}>
            {/* <div className="admin_label">
              {locationStr[1] == "accountant"
                ? t("admin.label.accountant_label")
                : t("admin.label.admin_label")}
            </div> */}
            <>
              <CounterNumbersListTop counter={counter} isLoading={isLoading} />
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

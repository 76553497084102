import { useContext } from "react";
import { AuthContext } from "../../Services/api/auth/AuthContext";
import { CourseProvider } from "../../Services/api/courses/courseContext";
import { DepartmentProvider } from "../../Services/api/toutorials/categoryContext";
import { ClassificationProvider } from "../../Services/api/toutorials/classificationContext";
import { TrainerProvider } from "../../Services/api/toutorials/trainerContext";
import { CoursesList, Trainers } from "./";
import Banner from "./Banner/Banner";
import BriefSection from "./BriefSection/BriefSection";
import CalenderNew from "./CalenderNew/CalenderNew";
import Categories from "./categories/Categories";
import FollowDream from "./FollowDreamsSection/FollowDream";
import HomeActions from "./homeActions/HomeActions";
import MainSection from "./MainSection/MainSection";
import PublishSection from "./PublishSection/PublishSection";

function Home() {
  const authContext = useContext(AuthContext);
  // console.log(authContext.auth.token);

  return (
    <>
      <DepartmentProvider>
        <ClassificationProvider>
          <CourseProvider>
            <MainSection />
            <HomeActions />
            <BriefSection />
            <PublishSection />
            <CalenderNew />
            <Categories />
            <CoursesList />
            <Banner />
          </CourseProvider>
        </ClassificationProvider>
      </DepartmentProvider>
      <TrainerProvider>
        <Trainers />
      </TrainerProvider>
      <FollowDream />
    </>
  );
}

export default Home;

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import i18n from "../../../../../i18n/i18n";
import { getSubChapterLessons } from "../../../../../Services/api/courses/courseProvider";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CircularProgressBar from "../../TrainingCourseContent/CircularProgressBar";
import UnitLessonItem from "./UnitLessonItem";

export default function UnitContentView({ role }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [chapter, setChapter] = useState({});
  const [progress, setProgress] = useState({});
  const { courseId, chapterId, unitId } = useParams();
  const history = useHistory();
  const lessons = chapter.lessons;
  const { t } = useTranslation();

  useEffect(() => {
    getSubChapterLessons(unitId, { perPage: 100 }).then((res) => {
      if (res.status == 200 && res.data.status) {
        setChapter(res.data.chapter);
        setProgress(buildProgress(res.data.chapter, "subChapter"));
        setIsLoading(false);
      }
    });
  }, []);

  const buildProgress = (data, type) => {
    let progress = {
      user_watch_evidences_count: 0,
      user_watch_trackers_count: 0,
      user_progress_evidences_count: 0,
      user_progress_trackers_count: 0,
    };

    const calculateProgress = (progress, item) => {
      progress.user_watch_evidences_count += Number(
        item.user_watch_evidences_count
      );
      progress.user_watch_trackers_count += Number(
        item.user_watch_trackers_count
      );
      progress.user_progress_evidences_count += Number(
        item.user_progress_evidences_count
      );
      progress.user_progress_trackers_count += Number(
        item.user_progress_trackers_count
      );
    };

    if (type === "chapter") {
      calculateProgress(progress, data);
      data?.sub_chapters?.forEach((subChapter) => {
        calculateProgress(progress, subChapter);
        subChapter?.lessons?.forEach((lesson) => {
          calculateProgress(progress, lesson);
        });
      });
    } else if (type === "subChapter") {
      calculateProgress(progress, data);
      data.lessons?.forEach((lesson) => {
        calculateProgress(progress, lesson);
      });
    }

    return {
      watch_percentage:
        (Number(progress.user_watch_evidences_count) /
          Number(progress.user_watch_trackers_count)) *
          100 || 0,
      progress_percentage:
        (Number(progress.user_progress_evidences_count) /
          Number(progress.user_progress_trackers_count)) *
          100 || 0,
    };
  };

  return (
    <>
      {isUpdating ? (
        <div
          style={{
            height: "60vh",
            width: "200px",
          }}
        >
          <SkeletonCardOverlay />
        </div>
      ) : (
        <></>
      )}

      <div className="tw-container tw-mx-auto tw-py-8">
        {isLoading ? (
          <div
            style={{
              height: "60vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="tw-rounded-xl ">
            <div className="tw-space-y-4 tw-p-8">
              <div className="tw-flex tw-items-stretch tw-justify-between">
                <div className="tw-text-xl">{t("showChap")}</div>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="tw-flex tw-items-center tw-justify-center tw-px-8 tw-rounded-lg tw-bg-gray-200"
                >
                  <div className="tw-text-gray-500 tw-py-2">{t("back")}</div>
                </button>
              </div>
              <div
                className="tw-rounded-lg tw-border tw-border-gray-300 tw-p-3 tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-s-4 sm:tw-space-y-0 tw-items-center "
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={chapter.course_image}
                  alt={chapter.course_name}
                  className="tw-rounded-lg tw-h-40"
                />
                <div className="tw-flex tw-flex-col tw-space-y-4 lg:tw-flex-row lg:tw-space-s-4 lg:tw-space-y-0 tw-items-center tw-justify-between tw-grow">
                  <div className="tw-space-y-4">
                    <div className="tw-text-xl">
                      <span>{t("chaptName")}</span>
                      <span className="tw-font-bold">{chapter.name}</span>
                    </div>
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-gray-500 tw-text-sm">
                      <div className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-rounded-full tw-py-1 tw-px-4">
                        {" "}
                        {`${t("spatiality")}  ${
                          i18n.language === "ar"
                            ? chapter?.course?.category?.name_ar
                            : chapter?.course?.category?.name_en
                        }`}
                      </div>
                      <div className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-rounded-full tw-py-1 tw-px-4">{`${t(
                        "field"
                      )} ${
                        i18n.language === "ar"
                          ? chapter?.course?.department_name_ar
                          : chapter?.course?.department_name_en
                      }`}</div>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-space-s-3 tw-px-4">
                    <CircularProgressBar
                      title={t("progress")}
                      percent={progress.progress_percentage}
                      color="#1dde50"
                      style={{
                        width: "4rem",
                        height: "4rem",
                        fontWeight: "700",
                      }}
                    />
                    <CircularProgressBar
                      title={t("watchingProgress")}
                      percent={progress.watch_percentage}
                      color="#dede1e"
                      style={{
                        width: "4rem",
                        height: "4rem",
                        fontWeight: "700",
                      }}
                    />
                  </div>
                </div>
              </div>

              {lessons?.length ? (
                lessons.map((lesson, index) => (
                  <UnitLessonItem
                    key={lesson.id}
                    courseId={courseId}
                    chapterId={chapterId}
                    unitId={unitId}
                    index={index}
                    lesson={lesson}
                    role={role}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

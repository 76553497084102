import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import IconsTooltip from "../../../Components/tooltips/IconsTooltip";
import noDataIcon from "../../../assets/icons/no-dataIcon.png";
import {
  getSurveysForLessonOrCourse,
  getSurveysPublic,
} from "../../../Services/api/survey/SurveyProvider";
import HomeIcon from "../../../assets/icons/Group 3.svg";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import classes from "./survey.module.css";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
const is_loggedIn = localStorage.getItem("token") ? true : false;

export default function GeneralSurveys(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    let res = null;
    try {
      setIsLoading(true);
      if (is_loggedIn) {
        res = await getSurveysForLessonOrCourse(null, null);
      } else {
        res = await getSurveysPublic();
      }
      // stop the loader
      setIsLoading(false);
      // set the available surveys
      if (res && res.status && res.status == 200 && res.data.status) {
        setSurveys(res.data.surveys);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginTop: "40px" }}>
          <div
            style={{
              display: "flex",
              gap: "15px",
              flex: "2 1 auto",
              alignItems: "center",
              padding: "0 10px",
              marginBottom: 20,
            }}
          >
            <img
              src={HomeIcon}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />

            <p className={classes["title"]}>{t("survey.title")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <MainBox
              style={{
                backgroundColor: "#f7f7f7",
                boxShadow: "none",
                paddingTop: "0rem",
              }}
            >
              {/* <MainBoxHead title={t("survey.title")} /> */}
              {surveys.length > 0 ? (
                <div className={classes.cardsCont}>
                  {surveys.map((survey) => (
                    <div className={classes.CardCont}>
                      <h3 className={classes.surveyName}>{survey.name}</h3>
                      <div className={classes.btnCont}>
                        <button
                          className={classes.btnOne}
                          disabled={
                            !survey.is_shared ||
                            !is_loggedIn ||
                            !survey.user_answered
                          }
                          onClick={() => {
                            if (survey.user_answered) {
                              history.push({
                                pathname: `/survey/${survey.id}/statistics`,
                              });
                            }
                          }}
                        >
                          {t("view_survey_results")}
                        </button>
                        <button
                          className={classes.btnTwo}
                          disabled={survey.user_answered || !is_loggedIn}
                          onClick={() => {
                            if (!survey.user_answered) {
                              history.push({
                                pathname: `/general-surveys/${survey.id}`,
                                state: { surveyId: survey.id },
                              });
                            }
                          }}
                        >
                          {t("enter_survey")}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={classes.maintext}>
                  <img src={noDataIcon} alt="No Data" />
                  {t("survey.notFound")}
                </div>
              )}
            </MainBox>
          </div>
        </div>
      </div>
    </>
  );
}

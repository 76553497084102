import { default as URLBuilder } from "../../Services/api/UrlBuilder";
import env from "./Environment";
import connector from "./Handler";

import { exportApi } from "./Essential";

export function exportCourseTrainersList(export_type, id, role, programCourses, withManager) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.non_trainers, { id: id }), {
          responseType: "blob",
          params: {
            export: export_type,
            role: role,
            type: programCourses ? "courseInProgram" : "course",
            ...(withManager ? { isManager: true } : {}),
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Course Trainers" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getActivitiesStatement(courseId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_course_trainees_activities, {
            courseId: courseId,
          }),
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportCourseTraineesActivities(type, courseId, params = {}) {
  return exportApi(
    type,
    URLBuilder(env.get_course_trainees_activities, {
      courseId: courseId,
    }),
    params,
    "course-activities"
  );
}

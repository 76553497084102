import CounterNumbers from "./CounterNumbers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import latePayments from "../../../../assets/image/dashboardFinancial/Number-of-late-payments.png";
import refundRequests from "../../../../assets/image/dashboardFinancial/Number-of-refund-requests.png";
import bankTransfersRecorded from "../../../../assets/image/dashboardFinancial/The-number-of-bank-transfers-recorded-wait.png";
import paymentsDue from "../../../../assets/image/dashboardFinancial/The-number-of-payments-due.png";
import trainerIMG from "../../../../assets/icons/count_trainer.svg";
import traineeIMG from "../../../../assets/icons/count_trainee.svg";
import courseNum from "../../../../assets/icons/count_courses.svg";
import programNum from "../../../../assets/icons/count_program.svg";
import sectionNum from "../../../../assets/icons/count_cat.svg";
import corners from "../../../../assets/image/dashboardFinancial/number-of-corners.png";
import exhibitions from "../../../../assets/image/dashboardFinancial/Number-of-exhibitions.png";
import open from "../../../../assets/icons/openticket.svg";
import close from "../../../../assets/icons/closedticket.svg";
import activeCoupons from "../../../../assets/image/dashboardFinancial/activeCoupons.png";
import { getAdminCounters } from "../../../../Services/api/toutorials/AdminCounters";

function CounterNumbersListTop(props) {
  require("./CounterNumbersListTop.css");
  const { t } = useTranslation();
  const [adminCounter, setAdminCouter] = useState([]);

  useEffect(() => {
    getAdminCounters()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAdminCouter(res.data.counters);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, []);

  return (
    <div style={{ margin: "50px" }}>
      {!props.isLoading ? (
        <div
          style={{
            height: "34vh",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="counter_number">
          <div className="counter_number_grid">
            {localStorage.getItem("type") !== "accountant" ? (
              <>
                <CounterNumbers
                  image={trainerIMG}
                  number={adminCounter?.trainers}
                  label={t("admin.label.num_trainer")}
                  color={"#efefef"}
                  link={
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin/roles"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager/roles"
                      : "/admin/roles/trainer"
                  }
                />
                <CounterNumbers
                  image={sectionNum}
                  number={adminCounter?.departments}
                  label={t("admin.label.num_dep")}
                  color={"#efefef"}
                  link={
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin/department"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager/department"
                      : "/admin/department"
                  }
                />
                <CounterNumbers
                  image={traineeIMG}
                  number={adminCounter?.trainees}
                  label={t("admin.label.num_trainee")}
                  color={"#efefef"}
                  link={
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin/roles"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager/roles"
                      : "/admin/roles/trainee"
                  }
                />
                <CounterNumbers
                  image={courseNum}
                  number={adminCounter?.courses}
                  label={t("admin.label.num_course")}
                  color={"#efefef"}
                  link={
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin/courses"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager/courses"
                      : "/admin/courses"
                  }
                />
                <CounterNumbers
                  image={programNum}
                  number={adminCounter?.programs}
                  label={t("admin.label.num_program")}
                  color={"#efefef"}
                  link={
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin/program/list"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager/program/list"
                      : "/admin/program/list"
                  }
                />
                {/* ******************* */}
                {/* <CounterNumbers
                  image={corners}
                  number={adminCounter?.cornersExhibitions}
                  label={t("admin.label.num_corner")}
                  color={"#efefef"}
                  name="corner"
                /> */}
                {/* <CounterNumbers
                  image={exhibitions}
                  number={adminCounter?.exhibitions}
                  label={t("admin.label.num_virtual_exhibitions")}
                  color={"#efefef"}
                  link="/admin/exhibitions-list"
                /> */}
                {localStorage.getItem("type") !== "manager" && (
                  <>
                    <CounterNumbers
                      image={open}
                      number={adminCounter?.ticketsOpen}
                      label={t("admin.label.open")}
                      color={"#efefef"}
                      link={
                        localStorage.getItem("type") === "group-admin"
                          ? "/group-admin/tickets?status=new"
                          : "/admin/tickets?status=new"
                      }
                    />
                    <CounterNumbers
                      image={close}
                      number={adminCounter?.ticketsClose}
                      label={t("admin.label.close")}
                      color={"#efefef"}
                      link={
                        localStorage.getItem("type") === "group-admin"
                          ? "/group-admin/tickets?status=closed"
                          : "/admin/tickets?status=closed"
                      }
                    />
                  </>
                )}
                {/* <CounterNumbers
                  image={activeCoupons}
                  number={adminCounter?.coupons}
                  label={t("admin.label.activeCoupons")}
                  color={"#efefef"}
                  name={"activeCoupons"}
                /> */}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default CounterNumbersListTop;

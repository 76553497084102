import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import AddCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Add";
import EditCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Edit";
import CourseTeamsList from "../../Modules/Trainer/Course/CourseTeams/List";
import LessonCanvas from "../../Modules/Trainer/Course/NewLesson/Canvas/Canvas";
import TrainerSurveyManager from "../../Modules/Trainer/Survey/Add";
import List from "../../Modules/Trainer/Survey/List";
import SurveyStatistics from "../../Modules/Trainer/Survey/Statistics";
import CourseContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContent";
import LessonActivityView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonActivityView/LessonActivityView";
import LessonContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonContentView/LessonContentView";
import UnitContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/UnitContentView/UnitContentView";
import Exam from "../../Modules/trainees/Exams/Exam";

import {
  AddSurvey,
  CourseDetails,
  CoursesList,
  DepartmentsTrainees,
  DepartmentsTrainers,
} from "../admins";
import {
  AcrivitiesMark,
  Activity,
  AddCourse,
  AddEduGoals,
  AddExamWrapper,
  AddPeymentRequest,
  AddQuestionToQuestionBankWrapper,
  AssessmentAdd,
  AssessmentAnswers,
  AssessmentEdit,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
  CourseProfile,
  DiscBoard,
  // EditCourse,
  EditEduGoals,
  EduGoals,
  ExamGrades,
  ListExams,
  ListQuestionBank,
  PeymentsRequest,
  Profile,
  SortExamQuestions,
  Statistics,
  SurveyAnswers,
  SurveyParticipants,
  SurveyQuestions,
  SurveyResponse,
  SurveyView,
  TraineeAnswerList,
} from "./";

const routesTrainer = [
  { component: Profile, path: "/", exact: true },
  {
    component: () => <AddCourse role="trainer" />,
    path: "/course/add",
    exact: true,
  },
  {
    component: () => <DiscBoard role="trainer" />,
    path: "/course/:courseId/discussion-board/:id",
    exact: true,
  },
  {
    component: () => <AcrivitiesMark role="trainer" />,
    path: "/course/lesson/:id/:activityId/activities-grades",
    exact: true,
  },
  {
    component: CourseDetails,
    path: "/course/preview/:id",
    exact: true,
  },
  {
    component: () => <ActivitiesStatement role={"trainer"} />,
    path: "/course/:courseId/activities",
    exact: true,
  },
  {
    component: DepartmentsTrainees,
    path: "/category/:id/trainees",
    exact: true,
  },
  {
    component: DepartmentsTrainers,
    path: "/category/:id/trainers",
    exact: true,
  },
  // {
  //   component: () => <EditCourse role="trainer" />,
  //   path: "/course/edit/:id",
  //   exact: true,
  // },
  {
    component: () => <CourseProfile role="trainer" />,
    path: "/course/:id",
    exact: true,
  },

  {
    component: () => <CourseContentView role={"trainer"} />,
    path: "/training-courses/:id/content",
    exact: true,
  },
  {
    component: () => <UnitContentView role={"trainer"} />,
    path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId",
    exact: true,
  },
  {
    component: () => <LessonContentView role={"trainer"} />,
    path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId",
    exact: true,
  },
  {
    component: () => <LessonActivityView role={"trainer"} />,
    path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
    exact: true,
  },
  {
    component: () => (
      <CourseContentView courseFromProgram={true} role={"trainer"} />
    ),
    path: "/training-courses/:programId/course/:id/content",
    exact: true,
  },
  {
    component: (props) => (
      <Exam {...props} quizable={`chapter`} role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/exam/:id",
    exact: true,
  },
  {
    component: () => <LessonCanvas isTrainer={true} />,
    path: "/course/lesson/:id",
    exact: true,
  },
  {
    component: () => <EduGoals isTrainer={true} role={"trainer"} />,
    path: "/course/educational-goals/:id",
    exact: true,
  },
  {
    component: () => <AddEduGoals isTrainer={true} role={"trainer"} />,
    path: "/course/educational-goals/add/:id",
    exact: true,
  },
  {
    component: () => <EditEduGoals isTrainer={true} role={"trainer"} />,
    path: "/course/educational-goals/edit/:id",
    exact: true,
  },
  {
    component: TrainerSurveyManager,
    path: "/survey/:targetCourseId/add",
    exact: true,
  },
  {
    component: TrainerSurveyManager,
    path: "/survey/:targetCourseId/edit/:id",
    exact: true,
  },
  {
    component: () => <PeymentsRequest role="trainer" />,
    path: "/payments-requests",
    exact: true,
  },
  {
    component: () => <AddPeymentRequest role="trainer" />,
    path: "/payments-requests/add",
    exact: true,
  },
  {
    component: () => (
      <CoursesList role="trainer" status="Closed" notInProgram={true} />
    ),
    path: "/courses-history",
    exact: true,
  },
  {
    component: () => (
      <CoursesList
        role="trainer"
        status="Published"
        notClosed={false}
        notInProgram={true}
      />
    ),
    path: "/courses",
    exact: true,
  },
  {
    component: () => (
      <CoursesList
        inProgram={true}
        role="trainer"
        status="Closed"
        programCourses={true}
      />
    ),
    path: "/programs-history",
    exact: true,
  },
  {
    component: () => (
      <CoursesList
        inProgram={true}
        role="trainer"
        status=""
        notClosed={true}
        programCourses={true}
      />
    ),
    path: "/programs",
    exact: true,
  },
  {
    component: TrainerSurveyManager,
    path: "/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <LessonCanvas role={"trainer"} isTrainer={true} />,
    path: "/course/:courseId/lesson/:id",
    exact: true,
  },
  {
    component: (props) => (
      <div className="tw-px-4">
        <SurveyView
          role={"trainer"}
          isTrainer={true}
          preview={true}
          editor={true}
        />
      </div>
    ),
    path: "/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => (
      <div className="tw-px-4">
        <SurveyView
          role={"trainer"}
          isTrainer={true}
          preview={true}
          editor={true}
        />
      </div>
    ),
    path: "/course/:targetCourseId/survey/preview/:id",
    exact: true,
  },
  {
    component: TrainerSurveyManager,
    path: "/survey/:targetCourseId/lesson/:targetLessonId/add",
    exact: true,
  },
  {
    component: TrainerSurveyManager,
    path: "/survey/:targetCourseId/lesson/:targetLessonId/edit/:id",
    exact: true,
  },
  {
    component: (props) => (
      <div className="tw-px-4">
        <SurveyView
          role={"trainer"}
          isTrainer={true}
          preview={true}
          editor={true}
        />
      </div>
    ),
    path: "/survey/:targetCourseId/lesson/:targetLessonId/preview/:id",
    exact: true,
  },
  {
    component: (props) => <List isTrainer={true} role={"trainer"} />,
    path: "/survey/list",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyQuestions {...props} isTrainer role={"trainer"} />
    ),
    path: "/survey/:id/questions",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyAnswers {...props} isTrainer role={"trainer"} />
    ),
    path: "/survey/:surveyId/questions/:questionId/answers",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyParticipants role="trainer" isTrainer={true} />
    ),
    path: "/survey/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse isTrainer={true} />,
    path: "/survey/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => <List {...props} role={"trainer"} />,
    path: "/course/:targetCourseId/survey/list",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"trainer"} />,
    path: "/course/:targetCourseId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"trainer"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"trainer"} />,
    path: "/course/:targetCourseId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"trainer"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView
        role={"trainer"}
        isTrainer={true}
        preview={true}
        editor={true}
      />
    ),
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/preview/:id",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"lesson"} role={"trainer"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"chapter"} role={"trainer"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: SurveyStatistics,
    path: "/survey/statistics/:id",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <ListExams quizable={"chapter"} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exams",
    exact: true,
  },
  {
    component: () => {
      return <AssessmentsList quizable={"chapter"} role={"trainer"} />;
    },
    path: "/course/:courseId/chapter/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => {
      return <ListExams quizable={"lesson"} />;
    },
    path: "/course/:id/lesson/:quizable_id/exams",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement role={"trainer"} />,
    path: "/course/:courseId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement />,
    path: "/course/:courseId/assessments",
    exact: true,
  },
  {
    component: () => {
      return <AssessmentsList quizable={"lesson"} role={"trainer"} />;
    },
    path: "/course/:courseId/lesson/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAdd quizable={"chapter"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAdd quizable={"lesson"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => (
      <AssessmentEdit quizable={"chapter"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentEdit quizable={"lesson"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"chapter"} role={"trainer"} isTrainer />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"lesson"} role={"trainer"} isTrainer />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAnswers quizable={"chapter"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAnswers quizable={"lesson"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"chapter"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"lesson"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => (
      <AssessmentPreview quizable={"chapter"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => (
      <AssessmentPreview quizable={"lesson"} isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <AddExamWrapper quizable={"chapter"} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exam/add",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <AddExamWrapper quizable={"lesson"} />
        </>
      );
    },
    path: "/course/:id/lesson/:quizable_id/exam/add",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <AddExamWrapper quizable={"chapter"} editMode={true} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exam/edit/:exam_id",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <AddExamWrapper quizable={"lesson"} editMode={true} />
        </>
      );
    },
    path: "/course/:id/lesson/:quizable_id/exam/edit/:exam_id",
    exact: true,
  },
  {
    component: () => (
      <AddQuestionToQuestionBankWrapper isTrainer role={"trainer"} />
    ),
    path: "/course/:courseId/question-bank/add",
    exact: true,
  },
  {
    component: () => (
      <AddQuestionToQuestionBankWrapper
        isTrainer
        editMode={true}
        role={"trainer"}
      />
    ),
    path: "/course/:courseId/question-bank/edit/:id",
    exact: true,
  },
  {
    component: () => <ListQuestionBank isTrainer role={"trainer"} />,
    path: "/course/:courseId/question-bank",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <ExamGrades quizable={"lesson"} />
        </>
      );
    },
    path: "/course/:id/lesson/:quizable_id/exam/:exam_id/grades",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <ExamGrades quizable={"chapter"} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exam/:exam_id/grades",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <TraineeAnswerList quizable={"lesson"} />
        </>
      );
    },
    path: "/course/:id/lesson/:quizable_id/exam/:exam_id/grades/:trainee_id/response/:response_id",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <TraineeAnswerList quizable={"chapter"} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exam/:exam_id/grades/:trainee_id/response/:response_id",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <SortExamQuestions quizable={"lesson"} />
        </>
      );
    },
    path: "/course/:id/lesson/:quizable_id/exam/:exam_id/sort",
    exact: true,
  },
  {
    component: () => {
      return (
        <>
          <SortExamQuestions quizable={"chapter"} />
        </>
      );
    },
    path: "/course/:id/chapter/:quizable_id/exam/:exam_id/sort",
    exact: true,
  },
  {
    component: () => <CourseTeamsList role={"trainer"} />,
    path: "/course/:courseId/teams",
    exact: true,
  },
  {
    component: () => <AddCourseTeam role={"trainer"} />,
    path: "/course/:courseId/teams/add",
    exact: true,
  },
  {
    component: () => <EditCourseTeam role={"trainer"} />,
    path: "/course/:courseId/teams/:id/edit",
    exact: true,
  },
  {
    component: Statistics,
    path: "/exam/statistics/:id",
    exact: true,
  },
  {
    component: Statistics,
    path: "/course/:id/chapter/:quizable_id/exam/:exam_id/statistics/:id",
    exact: true,
  },
  {
    component: Statistics,
    path: "/course/:id/lesson/:quizable_id/exam/:exam_id/statistics/:id",
    exact: true,
  },
  {
    component: () => <Activity isTrainer={true} />,
    path: "/list/activity/:id",
    exact: true,
  },
  {
    component: (props) => <LearningAnalysis {...props} role={"trainer"} />,
    path: "/learning-analysis",
    exact: true,
  },
];

export default routesTrainer;

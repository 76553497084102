import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchSystemUsersList } from "../../../Redux/Actions/systemUsers/systemUsersList";
import { getAllSpecialties } from "../../../Services/api/HomeCoursesProvider";
import { Lang } from "../../../utils";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const CoursesListFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  isProgram,
  role,
  programCourses,
  inProgram,
  status,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { coursesListTrainers, coursesListCategories } = useSelector((state) => state.coursesListReducer);
  const { systemUsersList } = useSelector((state) => state.systemUsersListReducer);
  const { departmentList } = useSelector((state) => state.departmentListReducer);
  const [trainersList, setTrainersList] = useState([]);
  const [managersList, setManagersList] = useState([]);

  useEffect(() => {
    getAllSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const optionsSpecialties = res.data.data.specialties.data.map((item) => ({
            label: i18n.language === "ar" ? item.name_ar : item.name_en,

            id: item.id,
            value: item.id,
          }));
          setSpecialtiesOptions(optionsSpecialties);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    dispatch(
      fetchSystemUsersList({
        page: currentPage,
        perPage: 10000,
      })
    );
  }, []);

  useEffect(() => {
    if (systemUsersList?.length) {
      const trainers = systemUsersList.filter((user) => user.roles.map((role) => role.name).includes("trainer"));
      const managers = systemUsersList.filter(
        (user) =>
          user.roles.map((role) => role.name).includes("manager") ||
          user.roles.map((role) => role.name).includes("admin") ||
          user.roles.map((role) => role.name).includes("group-admin")
      );
      setTrainersList(trainers);
      setManagersList(managers);
    }
  }, [systemUsersList]);

  const coursesListModes = [
    { id: 1, value: "Paid", label: t("crud.values.paid") },
    { id: 1, value: "Free", label: t("Exhibition.free") },
  ];
  const coursesListStatuses = [
    {
      label: t("general.active"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: 0,
    },
  ];
  const languageOptions = [
    {
      label: t("arabic"),
      id: 1,
      value: "ar",
    },
    {
      label: t("english"),
      id: 2,
      value: "en",
    },
  ];
  const levelOptions = [
    {
      label: t("levels.beginner"),
      id: 1,
      value: 1,
    },
    {
      label: t("levels.medium"),
      id: 2,
      value: 2,
    },
    {
      label: t("levels.advanced"),
      id: 3,
      value: 3,
    },
  ];
  const statusPublishOptions = [
    {
      label: t("general.published"),
      id: 1,
      value: "Published",
    },
    {
      label: t("general.draft"),
      id: 2,
      value: "Draft",
    },
    {
      label: t("general.closed"),
      id: 3,
      value: "Closed",
    },
    // {
    //   label: t("general.archived"),
    //   id: 4,
    //   value: "Archived",
    // },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      type: "text",
      placeholder: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    // {
    //   id: "description",
    //   component: "input",
    //   label: t("courses_list.description"),
    //   type: "text",
    //   placeholder: t("courses_list.description"),
    //   name: "description",
    //   value: filterData.description,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   onReset: resetHandler,
    // },

    ...(role !== "trainer" && role !== "content_developer" && role !== "admin"
      ? [
          {
            id: "owner",
            label: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            component: "select",
            placeholder: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            initialValue: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            name: "owner",
            value: filterData.owner,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: trainersList?.length
              ? trainersList?.map((trainer) => ({
                  id: trainer.id,
                  value: trainer.id,
                  label: trainer.name,
                }))
              : [],
          },
        ]
      : []),
    ...(role === "admin"
      ? [
          {
            id: "trainer_ids",
            label: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            component: "select",
            placeholder: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            initialValue: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
            name: "trainer_ids",
            value: filterData.trainer_ids,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: trainersList?.length
              ? trainersList?.map((trainer) => ({
                  id: trainer.id,
                  value: trainer.id,
                  label: trainer.name,
                }))
              : [],
          },

          ...(!programCourses
            ? [
                {
                  id: "manager_id",
                  label: t(`courses_list.${isProgram ? "manager_name" : "manager_name"}`),
                  component: "select",
                  placeholder: t(`courses_list.${isProgram ? "manager_name" : "manager_name"}`),
                  initialValue: t(`courses_list.${isProgram ? "manager_name" : "manager_name"}`),
                  name: "manager_id",
                  value: filterData.manager_id,
                  classes: "col-12 col-md-6 col-xl-4",
                  onchange: changeHandler,
                  options: managersList?.length
                    ? managersList?.map((manager) => ({
                        id: manager.id,
                        value: manager.id,
                        label: manager.name,
                      }))
                    : [],
                },
              ]
            : []),
          ...(!programCourses
            ? [
                {
                  id: "created_by",
                  label: t(`general.created_by`),
                  component: "select",
                  placeholder: t(`general.created_by`),
                  initialValue: t(`general.created_by`),
                  name: "created_by",
                  value: filterData.created_by,
                  classes: "col-12 col-md-6 col-xl-4",
                  onchange: changeHandler,
                  options: managersList?.length
                    ? managersList?.map((manager) => ({
                        id: manager.id,
                        value: manager.id,
                        label: manager.name,
                      }))
                    : [],
                },
              ]
            : []),
        ]
      : []),
    ...(!programCourses && !inProgram
      ? [
          {
            id: "mode",
            label: t("general.type"),
            component: "select",
            placeholder: t("general.type"),
            initialValue: t("general.type"),
            name: "mode",
            value: filterData.mode,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: coursesListModes,
          },
        ]
      : []),
    // {
    //   id: "cost",
    //   component: "input",
    //   label: t("general.cost"),
    //   type: "text",
    //   placeholder: t("general.cost"),
    //   name: "cost",
    //   value: filterData.cost,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   onReset: resetHandler,
    // },
    {
      id: "specialty",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "specialty",
      value: filterData.specialty,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: specialtiesOptions,
    },
    {
      id: "department",
      label: t("courses_list.department"),
      component: "select",
      placeholder: t("courses_list.department"),
      initialValue: t("courses_list.department"),
      name: "department",
      value: filterData.department,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: departmentList?.length
        ? departmentList?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label: i18n.language === Lang.AR ? trainer.name_ar : trainer.name_en,
          }))
        : [],
    },
    {
      id: "code",
      component: "input",
      label: t("general.code"),
      type: "text",
      placeholder: t("general.code"),
      name: "code",
      value: filterData.code,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    ...(!isProgram
      ? [
          {
            id: "run_serial_number",
            component: "input",
            label: t("general.serial_number"),
            type: "text",
            placeholder: t("general.serial_number"),
            name: "run_serial_number",
            value: filterData.run_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),

    {
      id: "language",
      label: t("general.language"),
      component: "select",
      placeholder: t("general.language"),
      initialValue: t("general.language"),
      name: "language",
      value: filterData.language,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: languageOptions,
    },
    {
      id: "level",
      label: t("general.level"),
      component: "select",
      placeholder: t("general.level"),
      initialValue: t("general.level"),
      name: "level",
      value: filterData.level,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: levelOptions,
    },
    ...(!programCourses && !status
      ? [
          {
            id: "status",
            label: t("general.status_Publish"),
            component: "select",
            placeholder: t("general.status_Publish"),
            initialValue: t("general.status_Publish"),
            name: "status",
            value: filterData.status,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: statusPublishOptions,
          },
        ]
      : []),
    {
      id: "start_date",
      component: "input",
      label: t("general.search_start_date"),
      type: "date",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "end_date",
      component: "input",
      label: t("general.search_end_date"),
      type: "date",
      placeholder: t("general.search_end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    ...(role === "trainer" || role === "content_developer" || programCourses
      ? []
      : [
          {
            id: "is_visible",
            label: t("general.status"),
            component: "select",
            placeholder: t("general.status"),
            initialValue: t("general.status"),
            name: "is_visible",
            value: filterData.is_visible,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: coursesListStatuses,
          },
        ]),

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default CoursesListFilter;

import { useState, useEffect } from "react";
import { downloadFileFromLesson } from "../../../../../Services/api/courses/courseProvider";
import DownloadIcon from "../../../../../assets/icons/download3.svg";

import classes from "./ContentView.module.css";
import { useTranslation } from "react-i18next";
function PdfView({ question }) {
  const { t } = useTranslation();
  const handleDownload = () => {
    const type = "contnet";
    downloadFileFromLesson(
      question?.title,
      question?.extension,
      question?.id,
      type
    );
  };

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  return (
    <div>
      <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>

      {width <= 768 ? (
        <div
          className={classes["downloadFile"]}
          style={{
            marginBottom: "30px",
          }}
        >
          <button
            className={classes["file-download"]}
            type="button"
            onClick={() => handleDownload()}
          >
            <img
              src={DownloadIcon}
              alt="Download Files"
              className={classes["downloadicon"]}
              style={{
                background: "#29b3b81f",
                borderRadius: "10px",
                padding: "15px 15px",
                marginBottom: "10px",
              }}
            />
            <span style={{ fontWeight: "bold" }}>{t("download_files")}</span>
          </button>
        </div>
      ) : (
        <iframe
          src={question.download}
          style={{ width: "100%" }}
          height="500"
          frameborder="0"
          allowfullscreen
          embedded={true}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          title="PDF Preview"
        ></iframe>
      )}
    </div>
  );
}

export default PdfView;

import classes from "./PaymentStatus.module.css";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
export default function PaymentStatus() {
  const { t, i18n } = useTranslation();
  const { state, search } = useLocation();
  const history = useHistory();
  const status = new URLSearchParams(search).get("status");
  const type = new URLSearchParams(search).get("type");
  const id = new URLSearchParams(search).get("id");
  const program_id = new URLSearchParams(search).get("program_id");
  const is_first_payment = new URLSearchParams(search).get("is_first_payment");

  return (
    <div div className="container-fluid" style={{ marginTop: "3rem" }}>
      <MainBox>
        <div className="tw-flex tw-items-center tw-mb-4">
          <button
            className={classes["activities-list__back-button"]}
            onClick={() =>
              history.push(
                type === "program"
                  ? is_first_payment == 1
                    ? `/training-programs/${program_id}/details`
                    : `/training-courses/${program_id}/content`
                  : type === "user"
                  ? `/private-profile/wallet`
                  : `/training-courses/${id}/details`
              )
            }
          >
            <i
              className={`fas fa-chevron-${
                i18n.language === Lang.AR ? "right" : "left"
              } tw-text-black tw-mt-2`}
            ></i>
            <span>
              {type === "program"
                ? t("go_to_program")
                : type === "user"
                ? t("go_to_wallet")
                : t("go_to_course")}
            </span>
          </button>
        </div>
        <div className={classes.maintext}>
          {status === "paid"
            ? t("payment_successful")
            : status === "inactive"
            ? t("payment_rejected")
            : t("paymant_waiting")}
        </div>
      </MainBox>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getAllSpecialties } from "../../../../Services/api/HomeCoursesProvider";
import Button from "../Button/Button";
import classes from "./headerSearch.module.css";

const HeaderSearch = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [filterData, setFilterData] = useState({
    mode: "",
    self_paced: "",
    category_id: "",
    start_date: "",
  });
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);

  useEffect(() => {
    getAllSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const optionsSpecialties = res.data.data.specialties.data.map((item) => ({
            label: i18n.language === "ar" ? item.name_ar : item.name_en,
            departments: item.departments,
            id: item.id,
            value: item.id,
          }));
          setSpecialtiesOptions(optionsSpecialties);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const coursesListModes = [
    { id: 1, value: "Paid", label: t("crud.values.paid") },
    { id: 1, value: "Free", label: t("Exhibition.free") },
  ];
  const coursesListSelf_paced = [
    {
      label: t("general.self_paced"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.not_self_paced"),
      id: 2,
      value: 0,
    },
  ];

  const changeHandler = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const filterHandler = () => {
    history.push({
      pathname: "/training-courses",
      state: { ...filterData },
    });
  };

  const filterFields = [
    {
      id: "mode",
      label: t("general.type"),
      component: "select",
      placeholder: t("general.type"),
      initialValue: t("general.type"),
      name: "mode",
      value: filterData.mode,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      options: coursesListModes,
    },
    {
      id: "self_paced",
      label: t("general.courseType"),
      component: "select",
      placeholder: t("general.courseType"),
      initialValue: t("general.courseType"),
      name: "self_paced",
      value: filterData.self_paced,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      options: coursesListSelf_paced,
    },
    {
      id: "category_id",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "category_id",
      value: filterData.category_id,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      options: specialtiesOptions,
    },
    {
      id: "start_date",
      component: "input",
      label: t("general.search_start_date"),
      type: "date",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-1",
      action: filterHandler,
    },
  ];

  const checkField = (field) => {
    switch (field.component) {
      case "input":
        return (
          <input
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            onChange={field.onchange}
            value={field.value}
          />
        );
      case "select":
        return (
          <select name={field.name} onChange={field.onchange} value={field.value}>
            <option value="">{field.initialValue}</option>
            {field.options.map((option) => (
              // <option key={option.value} value={option.value}>
              <option key={option.value} value={field.id === "country" ? option.id : option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "button":
        return (
          <Button
            type={field.type}
            action={field.action}
            backgroundColor={field.backgroundColor}
            color={field.color}
            borderColor={field.borderColor}
          >
            {field.value}
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <div className={classes["filter-box"]}>
      <div className="container-fluid">
        <div className={`row ${classes["filter-box__fields"]} ${classes["align-end"]}`}>
          {filterFields.map((field) => (
            <div key={field.id} className={`${field.classes} ${classes["filter-box__field"]}`}>
              <label htmlFor="">{field.label}</label>
              <div className={`${classes["filter-box_field_item"]}`}>{checkField(field)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderSearch;

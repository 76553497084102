import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from "react-router-dom";
import { toast } from "react-toastify";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import withRoot from "../../../../Hoc/withRoot";
import RouteInterceptor from "../../../../Interceptors/RouteInterceptor";
import Fund from "../../../../Modules/Admin/Financial/fund/fund";
import PeymentRequestsCoursesPrograms from "../../../../Modules/Admin/Financial/peymentRequestsCourses/PeymentRequestsCoursesPrograms";
import Payments from "../../../../Modules/Payments/Payments";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { getProfileInfo } from "../../../../Services/api/profile/profileProvider";
import NotificationSound from "../../../../assets/sounds/notification.mp3";
import {
  CouponDetails,
  CreateCoupon,
  EditCoupon,
} from "../../../../routes/admins";
import {
  AboutUs,
  ContactUs,
  ForgotPassword,
  HomeCourses,
  PrivacyAndPolicy,
  TermsAndConditions,
  Trainers,
  TrainingCoursesDetailsProvider,
} from "../../../../routes/main";
import handleRoutesPaths from "../../../../routes/main/HandlePaths";
import Coupons from "../../../Admin/Coupon/Coupons/Coupons";
import Dashboard from "../../../Admin/Dashboard/Dashboard";
import FAQListEndUser from "../../../Admin/FAQ/FAQListEndUser";
import PercentageTrainersList from "../../../Admin/Financial/PercentageTrainers/PercentageTrainersList";
import ReportFinancial from "../../../Admin/Financial/Report/Report";
import TotalIncomeReport from "../../../Admin/Financial/Report/TotalIncomeReport";
import Offers from "../../../Admin/Financial/offers/Offers";
import AddOffers from "../../../Admin/Financial/offers/add/Add";
import EditOffers from "../../../Admin/Financial/offers/edit/Edit";
import ProgramPaymentListFinancial from "../../../Admin/Financial/programPaymentDate/ProgramPaymentList";
import RefundList from "../../../Admin/Financial/refund/RefundList";
import EditRefundSetting from "../../../Admin/Financial/refundSetting/Edit/Edit";
import RefundSettingList from "../../../Admin/Financial/refundSetting/List";
import addRefundSetting from "../../../Admin/Financial/refundSetting/add/Add";
import Tax from "../../../Admin/Financial/tax/Tax";
import AddTax from "../../../Admin/Financial/tax/add/Add";
import EditTax from "../../../Admin/Financial/tax/edit/Edit";
import UserWallet from "../../../Admin/Financial/userWallet/UserWallet";
import WalletDetails from "../../../Admin/Financial/userWallet/walletDetails/walletDetails";
import AddTicket from "../../../Admin/Tickets/Add/Add";
import ListTickets from "../../../Admin/Tickets/List/List";
import ViewTicket from "../../../Admin/Tickets/View/View";
import AdminHeader from "../../../Admin/adminHeader/AdminHeader";
import BankTransfers from "../../../Admin/bankTransfers/bankTransfers";
import { default as SurveyQuestions } from "../../../Admin/survey/questions/List";
import AllRefunds from "../../../AllRefunds/AllRefunds";
import MainAuth from "../../../Auth/MainAuth";
import Certificates from "../../../Certificates/Certificates";
import EmailVerificationRoutes from "../../../EmailVerification/Routes";
import FAQUser from "../../../FAQ_s/FAQ_User";
import FinancialReport from "../../../FinancialReport/FinancialReport";
import InvoiceList from "../../../InvoiceList/InvoiceList";
import AllPrograms from "../../../Programs/AllProgram/AllProgram";
import purchasingOperations from "../../../PurchasingOperations/PurchasingOperations";
import CoursesOffered from "../../../Trainer/CoursesOffered/CoursesOffered";
import OverView from "../../../Trainer/OverView/OverView";
import TrainerReport from "../../../Trainer/TrainerReport/TrainerReport";
import TrainerInfoDetails from "../../../TrainersInfoDetails/TrainersInfoDetails";
import Wallet from "../../../Wallet/Wallet";
import PublishRequestsAdd from "../../../home/PublishRequests/PublishRequestsAdd";
import Home from "../../../home/homePage";
import TraineeReport from "../../../trainees/TraineeReport/TraineeReport";
import PrivateProfile from "../../../trainees/privateProfile/privateProfile";
import SurveyView from "../../../trainees/survey/Survey";
import Surveys from "../../../trainees/survey/Surveys";
import Admin from "../Admin/Admin";
import GroupAdmin from "../GroupAdmin/GroupAdmin";

import Trainer from "../Trainer/Trainer";
import TrainingManager from "../TrainingManager/TrainingManager";
import AccountantProvider from "./../accountant/AccountantContext";
import HeaderSearch from "./HeaderSearch";
import Links from "./Links";

function MainHeader() {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const { pusher } = usePusherContext();

  useEffect(() => {
    const channel = pusher.subscribe(
      `ticket-channel-${localStorage.getItem("userId")}`
    );
    channel.bind("App\\Events\\TicketEvent", (data) => {
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`ticket-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(
      `qassem-comment-channel-${localStorage.getItem("userId")}`
    );
    channel.bind("App\\Events\\CommentEvent", (data) => {
      console.log("comment event", data);
      let audio = new Audio(NotificationSound);
      audio.play();
      toast.warning(
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {i18n.language === "en" ? data.message_en : data.message_ar}
        </span>
      );
    });

    return () => {
      pusher.unsubscribe(`comment-channel-${localStorage.getItem("userId")}`);
    };
  }, []);

  const authContext = useContext(AuthContext);
  const [completedProfile, setCompletedProfile] = useState();
  const isLoggedIn = localStorage.getItem("token") === null ? false : true;
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(false);

  const root = history.location.pathname.split("/")[1];

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const scrolled = winScroll;

    if (document.getElementById("tajah-target-header")) {
      if (scrolled >= 111) {
        document
          .getElementById("tajah-target-header")
          .classList.add("navbar-fixed-top");
      } else {
        document
          .getElementById("tajah-target-header")
          .classList.remove("navbar-fixed-top");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  useEffect(() => {
    if (completedProfile === undefined && isLoggedIn) {
      getProfileInfo()
        .then((res) => {
          if (res.status && res.status === 201 && res.data.status) {
            localStorage.setItem(
              "completed_profile",
              res.data.response.updatePercentage === 100 ? "true" : "false"
            );
            setCompletedProfile(
              res.data.response.updatePercentage === 100 ? true : false
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [window.location.pathname, isLoggedIn]);

  useEffect(() => {
    if (
      isLoggedIn &&
      completedProfile !== undefined &&
      localStorage.getItem("completed_profile") !== "true" &&
      window.location.pathname !== "/edit-profile"
    ) {
      history.push("/edit-profile");
    }
  }, [isLoggedIn, completedProfile, window.location.pathname]);

  return (
    <>
      {(root !== "login" && root !== "register") ||
      localStorage.getItem("token") ? (
        !["admin", "accountant", "group-admin", "manager"].includes(root) ? (
          <Links setShowSearch={setShowSearch} />
        ) : (
          <AdminHeader roles={authContext.roles} />
        )
      ) : null}
      {showSearch && !root && (
        <HeaderSearch />
        // <div className="headerSearchContainer">
        //   <div className="container-fluid">
        //     <form className="headerSearchForm row">
        //       <div className="col-12 col-md-6 col-lg-3 headerSearchField" onSubmit={(e) => e.preventDefault()}>
        //         <Input
        //           type="text"
        //           placeholder={t("searchPlaceholder")}
        //           className="headerSearchInput"
        //           onChange={() => {}}
        //           onKeyPress={() => {}}
        //         />
        //       </div>
        //       <div className="col-12 col-md-6 col-lg-3 headerSearchField">
        //         <Input
        //           type="text"
        //           placeholder={t("searchPlaceholder")}
        //           className="headerSearchInput"
        //           onChange={() => {}}
        //           onKeyPress={() => {}}
        //         />
        //       </div>
        //       <div className="col-12 col-md-6 col-lg-3 headerSearchField">
        //         <Input
        //           type="text"
        //           placeholder={t("searchPlaceholder")}
        //           className="headerSearchInput"
        //           onChange={() => {}}
        //           onKeyPress={() => {}}
        //         />
        //       </div>
        //       <div className="col-12 col-md-6 col-lg-2 headerSearchField">
        //         <Input
        //           type="text"
        //           placeholder={t("searchPlaceholder")}
        //           className="headerSearchInput"
        //           onChange={() => {}}
        //           onKeyPress={() => {}}
        //         />
        //       </div>
        //       <div className="col-12 col-lg-1 headerSearchField">
        //         <CustomButton colors="#036c77" type="button" value={t("searchbtn")} classes="headerSearchButton" />
        //         {/* TODO: change the type */}
        //       </div>
        //     </form>
        //   </div>
        // </div>
      )}
      <Switch>
        <Route path="/" exact component={Home} />

        {authContext.roles.includes("admin") ? (
          <RouteInterceptor
            path="/admin"
            exact={false}
            component={Admin}
            show={true}
          />
        ) : null}
        {authContext.roles.includes("group-admin") ? (
          <RouteInterceptor
            path="/group-admin"
            exact={false}
            component={GroupAdmin}
            show={true}
          />
        ) : null}
        {authContext.roles.includes("manager") ? (
          <RouteInterceptor
            path="/manager"
            exact={false}
            component={TrainingManager}
            show={true}
          />
        ) : null}

        {authContext.roles.includes("trainer") && (
          <RouteInterceptor
            path="/trainer"
            exact={false}
            component={Trainer}
            show={true}
          />
        )}
        {authContext.roles.includes("trainer") && (
          <Route path="/overview" exact component={OverView} />
        )}
        {authContext.roles.includes("trainer") && (
          <Route path="/offered-courses" exact component={CoursesOffered} />
        )}
        {authContext.roles.includes("trainer") && (
          <Route path="/trainer-report" exact component={TrainerReport} />
        )}

        {authContext.roles.includes("trainee") ? (
          <Route path="/trainee-report" exact component={TraineeReport} />
        ) : null}

        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route
              path="/purchasing-operations"
              exact
              component={purchasingOperations}
            />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/all-refunds" exact component={AllRefunds} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/invoice-list" exact component={InvoiceList} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/financial-report" exact component={FinancialReport} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/wallet" exact component={Wallet} />
          )}
        {!authContext.roles.includes("admin") &&
          !authContext.roles.includes("accountant") &&
          !authContext.roles.includes("trainer") && (
            <Route path="/certificates" exact component={Certificates} />
          )}

        <RouteInterceptor
          path="/verify"
          exact={false}
          component={EmailVerificationRoutes}
          show={true}
        />
        {isLoggedIn ? (
          <>
            <Switch>
              {handleRoutesPaths.map((route, index) => (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={index}
                />
              ))}
              <Route path={"/ticket/view/:id"} exact component={ViewTicket} />

              <Route
                path={"/tickets"}
                exact
                component={() => <ListTickets isPublic={true} />}
              />

              <Route path={"/ticket/add"} exact component={AddTicket} />

              {!authContext.roles.includes("accountant") ? (
                <Redirect to={"/private-profile"} />
              ) : null}
            </Switch>
            {authContext.roles.includes("accountant") ? (
              <AccountantProvider>
                <Switch>
                  <Route
                    exact={true}
                    path={"/accountant"}
                    component={Dashboard}
                  />
                  <Route
                    component={CreateCoupon}
                    path="/accountant/coupon/add"
                    exact={true}
                  />
                  <Route
                    component={EditCoupon}
                    path="/accountant/coupon/edit/:id"
                    exact={true}
                  />
                  <Route
                    component={Coupons}
                    path="/accountant/coupon"
                    exact={true}
                  />
                  <Route
                    component={CouponDetails}
                    path="/accountant/coupon/:id"
                    exact={true}
                  />
                  <Route
                    component={AddOffers}
                    path="/accountant/offer/add"
                    exact={true}
                  />
                  <Route
                    component={EditOffers}
                    path="/accountant/offer/edit/:id"
                    exact={true}
                  />
                  <Route
                    component={Offers}
                    path="/accountant/offer"
                    exact={true}
                  />
                  <Route
                    component={AddTax}
                    path="/accountant/tax/add"
                    exact={true}
                  />
                  <Route
                    component={EditTax}
                    path="/accountant/tax/edit/:id"
                    exact={true}
                  />
                  <Route component={Tax} path="/accountant/tax" exact={true} />
                  <Route
                    component={ReportFinancial}
                    path="/accountant/financial/report"
                    exact={true}
                  />
                  <Route
                    component={RefundList}
                    path="/accountant/refund"
                    exact={true}
                  />
                  <Route
                    component={BankTransfers}
                    path="/accountant/bank-transfers"
                    exact={true}
                  />
                  <Route
                    component={ProgramPaymentListFinancial}
                    path="/accountant/program-payment-date"
                    exact={true}
                  />
                  <Route
                    component={PercentageTrainersList}
                    path="/accountant/percentage-trainer/list"
                    exact={true}
                  />
                  <Route
                    component={PeymentRequestsCoursesPrograms}
                    path="/accountant/peyment-requests-courses"
                    exact={true}
                  />
                  <Route
                    component={() => (
                      <PeymentRequestsCoursesPrograms isProgram={true} />
                    )}
                    path="/accountant/peyment-requests-programs"
                    exact={true}
                  />
                  <Route
                    component={() => <Payments type="financial" />}
                    path="/accountant/financial-movement"
                    exact={true}
                  />
                  <Route
                    component={Fund}
                    path="/accountant/fund"
                    exact={true}
                  />

                  <Route
                    exact={true}
                    path={"/accountant/users-wallet"}
                    component={UserWallet}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/users-wallet/details/:id"}
                    component={WalletDetails}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/financial/total-income-report"}
                    component={TotalIncomeReport}
                  />

                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/list"}
                    component={RefundSettingList}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/add"}
                    component={addRefundSetting}
                  />
                  <Route
                    exact={true}
                    path={"/accountant/refund-setting/edit/:id"}
                    component={EditRefundSetting}
                  />
                </Switch>
              </AccountantProvider>
            ) : null}
          </>
        ) : (
          <Switch>
            <Route path={"/register"} exact={true} component={MainAuth} />
            <Route
              path={"/login"}
              exact={true}
              component={
                localStorage.getItem("token") ? PrivateProfile : MainAuth
              }
            />
            <Route
              path={"/email-verification"}
              exact={true}
              component={MainAuth}
            />
            <Route path={"/trainers"} exact={true} component={Trainers} />
            <Route
              path={"/training-courses"}
              exact={true}
              component={HomeCourses}
            />
            <Route
              path={"/training-programs"}
              exact={true}
              component={() => <HomeCourses isProgram />}
            />
            <Route path={"/"} exact={true} component={Home} />
            <Route
              path={"/publish-request"}
              exact={true}
              component={() => <PublishRequestsAdd role="guest" />}
            />
            <Route path={"/contactUs"} exact={true} component={ContactUs} />
            <Route path={"/about-us"} exact={true} component={AboutUs} />
            <Route path={"/contactUs"} exact={true} component={ContactUs} />
            {!!localStorage.getItem("token") && (
              <Route
                path={"/general-surveys"}
                exact={true}
                component={Surveys}
              />
            )}
            <Route
              path={"/survey/:id/statistics"}
              exact={true}
              component={(props) => <SurveyQuestions {...props} isPublic />}
            />
            {!!localStorage.getItem("token") && (
              <Route
                path={"/general-surveys/:id"}
                exact={true}
                component={(props) => <SurveyView general={true} />}
              />
            )}
            <Route
              path="/survey/:id/exhibition/:targetExhibitionId"
              exact={true}
              component={(props) => <SurveyView general={true} />}
            />
            <Route
              path={"/survey/:id/course/:courseId"}
              exact={true}
              component={SurveyView}
            />

            <Route
              path={"/terms-and-conditions"}
              exact={true}
              component={TermsAndConditions}
            />
            <Route
              path={"/privacy-and-policy"}
              exact={true}
              component={PrivacyAndPolicy}
            />
            <Route
              path={"/training-courses/:id/details"}
              exact={true}
              component={TrainingCoursesDetailsProvider}
            />
            <Route
              path={"/training-programs/:id/details"}
              exact={true}
              component={() => (
                <TrainingCoursesDetailsProvider isProgram={true} />
              )}
            />
            <Route
              path={"/training-courses/:id/profile"}
              exact={true}
              children={<Redirect to={`/login`} />}
            />
            <Route
              path={"/show-exhibition/unity-iframe"}
              exact={true}
              children={<Redirect to={`/login`} />}
            />
            <Route
              path={"/forgot-password"}
              exact={true}
              component={ForgotPassword}
            />
            <Route
              path={"/trainers/trainer-details/:id"}
              exact={true}
              component={TrainerInfoDetails}
            />

            <Route path={"/faq/faq-list"} exact={true} component={FAQUser} />
            <Route
              path={"/tickets/faq-list"}
              exact={true}
              component={FAQListEndUser}
            />
            <Route path={"/all-program"} exact={true} component={AllPrograms} />
          </Switch>
        )}

        <Redirect to="/page-not-found" />
      </Switch>
    </>
  );
}

export default withRouter(withRoot(MainHeader));

import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  assignCertificateTemplate,
  fetchCertificatesTemplatesList,
  unassignCertificateTemplate,
} from "../../../Redux/Actions/certificates/certificates-template.action";
import { exportCertificatesTemplate } from "../../../Services/api/certificate/certificateTemplateProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./CertificateTemplates.module.css";
import { filterActions } from "./CertificateTemplatesActions";
import CertificateTemplatesFilter from "./CertificateTemplatesFilter";
import CertificateTemplatesTable from "./CertificateTemplatesTable";

function CertificateTemplates(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = localStorage.getItem("type");

  const {
    certificatesMeta,
    assignCertificatesTemplateLoading,
    selectedCertificate,
  } = useSelector((state) => state.certificatesTemplateReducer);

  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    description: "",
    status: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(localStorage.getItem("type") === "trainer"
      ? [
          {
            id: "content_development",
            page: t("sidebar.label.private_profile"),
            pagePath: "/private-profile",
          },
          ...(id
            ? [
                {
                  id: "content_development",
                  page: t("sidebar.label.courses"),
                  pagePath: `/${role}/courses`,
                },
              ]
            : []),
          {
            id: `content_development_tax`,
            page: t("certificate.certificates"),
            pagePath: `/trainee/${id}/course/certificates`,
          },
          {
            id: `content_development_tax`,
            page: t("sidebar.label.certificate_templates"),
            pagePath: "#",
            active: true,
          },
        ]
      : [
          {
            id: "content_development",
            page: t("sidebar.label.content_development"),
            pagePath: "#",
          },
          ...(id
            ? [
                {
                  id: "content_development",
                  page: t("certificate.certificates"),
                  pagePath: `/${role}/trainee/${id}/${
                    props.isProgram ? "program" : "course"
                  }/certificates`,
                },
              ]
            : []),
          {
            id: `content_development_tax`,
            page: t("sidebar.label.certificate_templates"),
            pagePath: "#",
            active: true,
          },
        ]),
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      description: "",
      status: "",
    });
    setIsResetApplied(true);
  };

  // const exportHandler = async (type) => {
  //   setIsExportLoading(true);
  //   try {
  //     const filter = getFilterData();
  //     await exportCertificatesTemplates(type,filter);
  //     setIsExportLoading(false);
  //   } catch (e) {
  //     setIsExportLoading(false);
  //     toast.error(
  //       <span style={{ fontSize: 13, fontWeight: "bold" }}>
  //         {t("failed_fetching")}
  //       </span>
  //     );
  //   }
  // };

  const assignCertificateHandler = (certificateId) => {
    if (selectedCertificate === certificateId) {
      dispatch(unassignCertificateTemplate({ certificateId, id: id }));
    } else {
      dispatch(
        assignCertificateTemplate({
          certificateId,
          id: id,
        })
      );
    }
  };

  const previewCertificateHandler = async (certificateId) => {
    setIsExportLoading(true);
    try {
      await exportCertificatesTemplate(certificateId);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.description && (filter.description = filterData.description);
    filterData.status && (filter.status = filterData.status);
    return filter;
  };

  const getCertificatesTemplatesListHandler = () => {
    const filter = getFilterData();
    if (id) {
      filter.id = id;
    }
    dispatch(
      fetchCertificatesTemplatesList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  useEffect(() => {
    getCertificatesTemplatesListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  return (
    <div className={classes["tax-list"]}>
      {(isExportLoading || assignCertificatesTemplateLoading) && (
        <SkeletonCardOverlay skeletonWidth="100" />
      )}
      <div className="container-fluid">
        <div className={classes["tax-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("sidebar.label.certificate_templates")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    [props.isAdmin && "addAction"]: () => {
                      history.push({
                        pathname: `${
                          role === "admin" ? "/admin" : ""
                        }/certificateTemplates${id ? `/${id}` : ""}/add`,
                        state: { role },
                      });
                    },
                    exportPdf: () => {
                      // exportHandler("pdf");
                    },
                    exportExcel: () => {
                      // exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <CertificateTemplatesFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getCertificatesTemplatesListHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <CertificateTemplatesTable
                  assignCertificateHandler={assignCertificateHandler}
                  previewCertificateHandler={previewCertificateHandler}
                  courseId={id || null}
                  role={role}
                  currentPage={currentPage}
                />
                <Pagination
                  count={certificatesMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificateTemplates;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import Pdf from "./../../../../assets/image/rating/pdf.png";
import Xls from "./../../../../assets/image/rating/xls.png";
import {
  exportRoles,
  getRoles,
} from "../../../../Services/api/Roles/RolesProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { toast } from "react-toastify";
import EditRole from "../Edit";
import ReactPaginate from "react-paginate";
import { userActive } from "../../../../Services/api/toutorials/AdminUserProvider";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";

function ListRoles(props) {
  require("./List.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [rolesModal, setRolesModal] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [meta, setMeta] = useState({});
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const location = useLocation();

  const [loginTypes, setLoginTypes] = useState([
    { text: "Facebook", value: "facebook" },
    { text: "Twitter", value: "twitter" },
    { text: "LinkedIn", value: "linkedin" },
    { text: "Google", value: "google" },
    { text: "Normal", value: "normal" },
    { text: "SSO", value: "sso" },
  ]);
  const [statuses, setStatuses] = useState([
    { text: "Inactive", value: 0 },
    { text: "Active", value: 1 },
  ]);
  const [filter, setFilter] = useState({});

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const fetchRoles = (params = null) => {
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getRoles(params);
        if (response.status == 200) {
          setMeta(response.data.response.Users.meta);
          setRoles(response.data.response.AllRoles);
          setUsers(response.data.response.Users.data);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      // await fetchRoles(filter);
      if (location.state) {
        console.log("location.state", location.state.status);
        if (location.state.status == "trainer") {
          let filterValues = { role: 3 };
          setFilter((filter) => ({ ...filter, role: 3 }));
          await fetchRoles(filterValues);
        } else if (location.state.status == "trainee") {
          let filterValues = { role: 4 };
          setFilter((filter) => ({ ...filter, role: 4 }));
          await fetchRoles(filterValues);
        } else {
          await fetchRoles(filter);
        }
      } else {
        await fetchRoles(filter);
      }
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          40
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        parseInt(
          document.getElementsByClassName("tajah_table_vertical_movement")[0]
            .offsetHeight
        ).toString() + "px";
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      reAlignElementsHeights(showFilters);
    }
  }, [showFilters]);

  const rolesEditHandle = (id) => {
    setRolesModal(!rolesModal);
    setUserInfo(
      users.filter((user) => {
        return user.id == id;
      })
    );
  };

  const reAlignElementsHeights = (isFilterShown) => {
    if (!isFilterShown) {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          40
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        parseInt(
          document.getElementsByClassName("tajah_table_vertical_movement")[0]
            .offsetHeight
        ).toString() + "px";
    } else {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_fade_change")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          80
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(
            document.getElementsByClassName("tajah_table_fade_change")[0]
              .offsetHeight
          ) +
          40
        ).toString() + "px";
    }
  };

  const activationHandling = async (id) => {
    // setIsPerformingBackgroundOperation(true);
    try {
      let res = await userActive(id);
      if (res.status && res.status == 200 && res.data.status) {
        await fetchRoles(filter);
        // setIsPerformingBackgroundOperation(false);
      }
    } catch (e) {
      // setIsPerformingBackgroundOperation(false);
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {e.response.data.msg}
          </span>
          {e.response.data.errors ? (
            <ul>
              {Object.keys(e.response.data.errors).map((key) => {
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>
                      {e.response.data.errors[key]}
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </>
      );
    }
  };

  useEffect(() => {
    setCurrentUser(users && users);
  }, [users]);

  const handlePageClick = (event) => {
    const toBeUpdatedFilters = filter;
    toBeUpdatedFilters.page = event.selected + 1;
    setFilter(toBeUpdatedFilters);

    fetchRoles(toBeUpdatedFilters);
  };

  return (
    <>
      {rolesModal && (
        <>
          <div
            className="backdrop"
            style={{
              position: "fixed",
            }}
            onClick={() => {
              setRolesModal(false);
            }}
          />
          <EditRole
            roles={roles}
            users={userInfo}
            onClick={rolesEditHandle}
            fetchRoles={() => fetchRoles(filter)}
            setIsUpdating={setIsUpdating}
          />
        </>
      )}
      <div
        className="container-fluid"
        id={"content-section"}
        style={{
          position: "relative",
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div className="row" id="content-header-bar">
          <div className="col-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="admin_label">
                <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                <p style={{ padding: "0 5px" }}>/</p>
                {t("roles")}
              </div>

              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              ></div>
            </div>
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading == false ? (
          <div
            id="content-body-section"
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            {isPerformingBackgroundOperation ? (
              <SkeletonCardOverlay borderRadius={10} top={40} />
            ) : null}
            <div
              className={"tajah_table_fade_change"}
              style={{
                opacity: showFilters ? 1 : 0,
              }}
            >
              <Formik
                initialValues={{
                  role: "",
                  status: "",
                  type_login: "",
                  last_login: "",
                  start_register_date: "",
                  end_register_date: "",
                  email: "",
                  name: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    // setIsPerformingBackgroundOperation(true);
                    setFilter(values);
                    await fetchRoles(values);
                    reAlignElementsHeights(showFilters);
                    // setIsPerformingBackgroundOperation(false);
                  } catch (e) {
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {e.response.data.msg}
                        </span>
                        {e.response.data.errors ? (
                          <ul>
                            {Object.keys(e.response.data.errors).map((key) => {
                              return (
                                <li>
                                  <span style={{ fontSize: 11 }}>
                                    {e.response.data.errors[key]}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                      </>
                    );
                  }
                }}
                validateOnChange={false}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} class={"table_bank_tran"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        width: "90%",
                        borderBottomWidth: 1,
                        borderBottomColor: "rgba(0,0,0,0.3)",
                        borderBottomStyle: "solid",
                        height: 40,
                      }}
                    >
                      <h5>{t("Filter")}</h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "90%",
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginLeft: document.body.dir == "rtl" ? 10 : 0,
                          marginRight: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("crud.placeholders.name")}</label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          className="admin_filter_input"
                          onChange={(e) => {
                            setFieldValue("name", e.target.value);
                          }}
                          placeholder={t("crud.placeholders.name")}
                          value={values.name}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <label>{t("crud.placeholders.select.role")}</label>
                        <Select
                          name="role"
                          id="role"
                          options={roles}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          onChange={(item) => {
                            if (item) {
                              setFieldValue("role", item.id);
                            } else {
                              setFieldValue("role", null);
                            }
                          }}
                          placeholder={t("crud.placeholders.select.role")}
                        />
                      </div>

                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("crud.placeholders.select.status")}</label>
                        <Select
                          name="status"
                          id="status"
                          options={statuses}
                          getOptionLabel={(option) => option.text}
                          getOptionValue={(option) => option.value}
                          isClearable={true}
                          onChange={(item) => {
                            if (item) {
                              setFieldValue("status", item.value);
                            } else {
                              setFieldValue("status", null);
                            }
                          }}
                          placeholder={t("crud.placeholders.select.status")}
                        />
                      </div>

                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>
                          {t("crud.placeholders.select.login_type")}
                        </label>
                        <Select
                          name="type_login"
                          id="type_login"
                          options={loginTypes}
                          getOptionLabel={(option) => option.text}
                          getOptionValue={(option) => option.value}
                          isClearable={true}
                          onChange={(item) => {
                            if (item) {
                              setFieldValue("type_login", item.value);
                            } else {
                              setFieldValue("type_login", null);
                            }
                          }}
                          placeholder={t("crud.placeholders.select.login_type")}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "90%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <div className={"tajah_filter_item_label_actionable"}>
                          <div>
                            <label>
                              {t("crud.placeholders.select.last_login")}
                            </label>
                          </div>
                          <div className={"tajah_filter_item_label_action"}>
                            {values.last_login ? (
                              <div
                                className={
                                  "tajah_filter_item_label_action_clear"
                                }
                                onClick={() => {
                                  setFieldValue("last_login", "");
                                }}
                              >
                                <i
                                  className="fas fa-times"
                                  style={{ color: "white", fontSize: 10 }}
                                ></i>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <DatePicker
                          value={values.last_login}
                          disabled={
                            values.start_register_date ||
                            values.end_register_date != ""
                              ? true
                              : false
                          }
                          onChange={(date) =>
                            setFieldValue(
                              "last_login",
                              formatDate(new Date(date))
                            )
                          }
                          placeholderText={t(
                            "crud.placeholders.select.last_login"
                          )}
                          className="admin_filter_input"
                          dateFormat={"yyyy-MM-dd"}
                        />
                      </div>

                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <div className={"tajah_filter_item_label_actionable"}>
                          <div>
                            <label>
                              {t(
                                "crud.placeholders.select.start_register_date"
                              )}
                            </label>
                          </div>
                          <div className={"tajah_filter_item_label_action"}>
                            {values.start_register_date ? (
                              <div
                                className={
                                  "tajah_filter_item_label_action_clear"
                                }
                                onClick={() => {
                                  setFieldValue("start_register_date", "");
                                }}
                              >
                                <i
                                  className="fas fa-times"
                                  style={{ color: "white", fontSize: 10 }}
                                ></i>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <DatePicker
                          value={values.start_register_date}
                          disabled={values.last_login != "" ? true : false}
                          onChange={(date) =>
                            setFieldValue(
                              "start_register_date",
                              formatDate(new Date(date))
                            )
                          }
                          placeholderText={t(
                            "crud.placeholders.select.start_register_date"
                          )}
                          className="admin_filter_input"
                          dateFormat={"yyyy-MM-dd"}
                        />
                      </div>

                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <div className={"tajah_filter_item_label_actionable"}>
                          <div>
                            <label>
                              {t("crud.placeholders.select.end_register_date")}
                            </label>
                          </div>
                          <div className={"tajah_filter_item_label_action"}>
                            {values.end_register_date ? (
                              <div
                                className={
                                  "tajah_filter_item_label_action_clear"
                                }
                                onClick={() => {
                                  setFieldValue("end_register_date", "");
                                }}
                              >
                                <i
                                  className="fas fa-times"
                                  style={{ color: "white", fontSize: 10 }}
                                ></i>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <DatePicker
                          value={values.end_register_date}
                          disabled={values.last_login != "" ? true : false}
                          onChange={(date) =>
                            setFieldValue(
                              "end_register_date",
                              formatDate(new Date(date))
                            )
                          }
                          placeholderText={t(
                            "crud.placeholders.select.end_register_date"
                          )}
                          className="admin_filter_input"
                          dateFormat={"yyyy-MM-dd"}
                        />
                      </div>

                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("crud.placeholders.email_search")}</label>
                        <input
                          id="email"
                          type="text"
                          name="email"
                          className="admin_filter_input"
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                          }}
                          placeholder={t("crud.placeholders.email_search")}
                          value={values.email}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "90%",
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{
                          width: "100%",
                        }}
                        type="submit"
                        className="admin_add_button"
                      >
                        {t("crud.placeholders.submit")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            <div
              className="table_bank_tran tajah_table_vertical_movement"
              style={{
                top: showFilters
                  ? parseInt(
                      document.getElementsByClassName(
                        "tajah_table_fade_change"
                      )[0].offsetHeight
                    ) + 40
                  : 0,
              }}
            >
              <div className="tajah_card_title_container">
                <div>{t("roles")}</div>
                <div className={"tajah_card_title_container_actions"}>
                  <button
                    style={{ borderRadius: 10, padding: 10, borderWidth: 0 }}
                    className={
                      showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"
                    }
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  >
                    <IconTooltips
                      title={t("Filter")}
                      content={
                        <i
                          className={
                            "fas fa-filter tajah_ticket_list_table_filter_icon"
                          }
                        />
                      }
                    />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      borderWidth: 0,
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportRoles(clearEmptyProp(filter), "xlsx");
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadExcel")}
                      content={<img src={Xls} style={{ width: 25 }} />}
                    />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      borderWidth: 0,
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportRoles(clearEmptyProp(filter), "pdf");
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadPdf")}
                      content={<img src={Pdf} style={{ width: 25 }} />}
                    />
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("crud.list.name")}</th>
                      <th scope="col">{t("crud.list.email")}</th>
                      <th scope="col">{t("crud.list.jobs")}</th>
                      <th scope="col">{t("crud.list.status")}</th>
                      <th scope="col">{t("crud.list.type_login")}</th>
                      <th scope="col">{t("crud.list.edit")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUser &&
                      currentUser.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              {document.body.dir == "rtl"
                                ? item.name_ar
                                : item.name_en}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              {item.roles.map((roleItem) => {
                                return roleItem.name + " ";
                              })}
                            </td>
                            <td scope="col">
                              <span
                                style={{
                                  color:
                                    item.status != 1 ? "#D20101" : "#019501",
                                }}
                              >
                                {item.status === 1
                                  ? t("crud.values.active")
                                  : t("crud.values.inactive")}
                              </span>
                            </td>
                            <td scope="col">{item.type_login.text}</td>

                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <button
                                  // className={"btn btn-primary"}
                                  style={{
                                    backgroundColor: "white",
                                    borderWidth: 0,
                                    // width: 25,
                                    // backgroundColor: "#006d77",
                                    // borderColor: "#006d77",
                                  }}
                                  onClick={() => rolesEditHandle(item.id)}
                                >
                                  {/* <i   style={{
                                                        fontSize: 20,
                                                        color:'black'
                                                    }} className="fal fa-edit"></i> */}
                                  <IconTooltips
                                    title={t("block.label.edit")}
                                    content={
                                      <i
                                        style={{ fontSize: 20, color: "black" }}
                                        className="fal fa-edit"
                                      ></i>
                                    }
                                  />
                                </button>
                                <button
                                  // className={"btn"}
                                  style={{
                                    backgroundColor: "white",
                                    borderWidth: 0,
                                    // width: 25,
                                    // backgroundColor: item.status === 1 ? "#dc3545" : "#006d77",
                                  }}
                                  onClick={() => activationHandling(item.id)}
                                >
                                  <IconTooltips
                                    title={
                                      item.status === 1
                                        ? t("crud.values.active")
                                        : t("crud.values.inactive")
                                    }
                                    content={
                                      item.status === 1 ? (
                                        <i
                                          className={"fal fa-minus-circle"}
                                          style={{
                                            fontSize: 20,
                                            color: "black",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          className={"fal fa-check-circle"}
                                          style={{
                                            fontSize: 20,
                                            color: "black",
                                          }}
                                        ></i>
                                      )
                                    }
                                  />
                                  {/* <i
                                                            className={item.status === 1 ? "fal fa-minus-circle" : "fal fa-check-circle"}
                                                            style={{
                                                                fontSize: 20,
                                                                color:'black'
                                                            }}
                                                        ></i> */}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <ReactPaginate
                  nextLabel={t("next")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={meta.totalPages}
                  previousLabel={t("previous")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default ListRoles;

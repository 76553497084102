import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getTraineesReg({ id, type, page, perPage, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_trainee_reg, { id: id, type: type }), {
          params: {
            page: page,
            perPage: perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainees(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.non_trainees, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function approveJoinRequest(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.approve_join_request, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function rejectJoinRequest(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.reject_join_request, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateTraineeStatus(id, course_id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(env.update_trainee_status, {
            id: id,
            course_id: course_id,
          }),
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainers(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.non_trainers, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getPartners(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_partners, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function assignTrainers(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.assign_trainer, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function assignContentDeveloper(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.assign_content_developer, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function assignTrainees(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.assign_trainee, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function assignPartners(type, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.assign_partner, { type: type }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addTrainerDues(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_trainer_dues, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

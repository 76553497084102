import { useTranslation } from "react-i18next";
import Cat from "../../../../assets/icons/categoryiCON.svg";
import classes from "./CategoryCard.module.css";

export default function CategoryCard({ title, image }) {
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.CategoryCard}>
      <div className={classes.icon_cont}>
        <img src={image || Cat} alt={title} />
      </div>
      <div className={classes.title_arrow}>
        {/* <img src={Arrow} alt="" className={classes.ArrowCard} /> */}
        <p> {title}</p>
      </div>
    </div>
  );
}

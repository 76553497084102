import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllSpecialties, getAllTrainersMangers } from "../../Services/api/HomeCoursesProvider";
import { Lang } from "../../utils";
import FilterBox from "../Shared/Components/FilterBox/FilterBox";
import classes from "./homeCourses.module.css";

const HomeCoursesFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  isProgram,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(
    history.location.state?.self_paced || history.location.state?.mode || history.location.state?.start_date
      ? true
      : false
  );
  const [loading, setLoading] = useState(true);

  const [trainersList, setTrainersList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    getAllSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const optionsSpecialties = res.data.data.specialties.data.map((item) => ({
            label: i18n.language === "ar" ? item.name_ar : item.name_en,
            departments: item.departments,
            id: item.id,
            value: item.id,
          }));
          setSpecialtiesOptions(optionsSpecialties);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    getAllTrainersMangers({ roleId: isProgram ? "2" : "3" })
      .then((res) => {
        // console.log(res);

        if (res.status && res.status === 200) {
          isProgram ? setManagersList(res.data.data) : setTrainersList(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const coursesListModes = [
    { id: 1, value: "Paid", label: t("crud.values.paid") },
    { id: 1, value: "Free", label: t("Exhibition.free") },
  ];
  const coursesListSelf_paced = [
    {
      label: t("general.self_paced"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.not_self_paced"),
      id: 2,
      value: 0,
    },
  ];
  const languageOptions = [
    {
      label: t("arabic"),
      id: 1,
      value: "ar",
    },
    {
      label: t("english"),
      id: 2,
      value: "en",
    },
  ];
  const levelOptions = [
    {
      label: t("levels.beginner"),
      id: 1,
      value: 1,
    },
    {
      label: t("levels.medium"),
      id: 2,
      value: 2,
    },
    {
      label: t("levels.advanced"),
      id: 3,
      value: 3,
    },
  ];
  const rateOptions = [
    {
      label: 1,
      id: 1,
      value: 1,
    },
    {
      label: 2,
      id: 2,
      value: 2,
    },
    {
      label: 3,
      id: 3,
      value: 3,
    },
    {
      label: 4,
      id: 4,
      value: 4,
    },
    {
      label: 5,
      id: 5,
      value: 5,
    },
  ];

  const filterFieldsAdvanced = [
    {
      id: "name",
      component: "input",
      label: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      type: "text",
      placeholder: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "specialty",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "specialty",
      value: filterData.specialty,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: (e) => {
        changeHandler(e);
        setDepartmentList(specialtiesOptions.find((specialty) => specialty.id == e.target.value).departments);
      },
      options: specialtiesOptions,
    },
    {
      id: "department",
      label: t("courses_list.department"),
      component: "select",
      placeholder: t("courses_list.department"),
      initialValue: t("courses_list.department"),
      name: "department",
      value: filterData.department,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: departmentList?.length
        ? departmentList?.map((department) => ({
            id: department.id,
            value: department.id,
            label: i18n.language === Lang.AR ? department.name_ar : department.name_en,
          }))
        : [],
    },

    {
      id: "trainer_ids",
      label: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
      component: "select",
      placeholder: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
      initialValue: t(`courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`),
      name: "trainer_ids",
      value: filterData.trainer_ids,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: isProgram
        ? managersList?.length
          ? managersList?.map((trainer) => ({
              id: trainer.id,
              value: trainer.id,
              label: trainer.name,
            }))
          : []
        : trainersList?.length
        ? trainersList?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label: trainer.name,
          }))
        : [],
    },

    {
      id: "mode",
      label: t("general.type"),
      component: "select",
      placeholder: t("general.type"),
      initialValue: t("general.type"),
      name: "mode",
      value: filterData.mode,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListModes,
    },
    ...(isProgram
      ? []
      : [
          {
            id: "self_paced",
            label: t("general.courseType"),
            component: "select",
            placeholder: t("general.courseType"),
            initialValue: t("general.courseType"),
            name: "self_paced",
            value: filterData.self_paced,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: coursesListSelf_paced,
          },
        ]),
    {
      id: "number_hours",
      label: t("general.hourCount"),
      component: "input",
      type: "number",
      placeholder: t("general.hourCount"),
      initialValue: t("general.hourCount"),
      name: "number_hours",
      value: filterData.number_hours,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "rate",
      label: t("general.rate"),
      component: "select",
      placeholder: t("general.rate"),
      initialValue: t("general.rate"),
      name: "rate",
      value: filterData.rate,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: rateOptions,
    },

    {
      id: "cost",
      component: "input",
      label: t("general.cost"),
      type: "number",
      placeholder: t("general.cost"),
      name: "cost",
      value: filterData.cost,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "language",
      label: t("general.language"),
      component: "select",
      placeholder: t("general.language"),
      initialValue: t("general.language"),
      name: "language",
      value: filterData.language,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: languageOptions,
    },
    {
      id: "level",
      label: t("general.level"),
      component: "select",
      placeholder: t("general.level"),
      initialValue: t("general.level"),
      name: "level",
      value: filterData.level,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: levelOptions,
    },

    {
      id: "start_date",
      component: "input",
      label: t("general.start_date"),
      type: "date",
      placeholder: t("general.start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "end_date",
      component: "input",
      label: t("general.end_date"),
      type: "date",
      placeholder: t("general.end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "rest",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        setDepartmentList([]);
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "rgb(3, 162, 178)",
      borderColor: "rgb(3, 162, 178)",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
    {
      id: "advanceSearch",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.hide"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        setAdvanceSearch(false);
      },
      //   backgroundColor: "transparent",
      //   color: "rgb(3, 162, 178)",
      //   borderColor: "rgb(3, 162, 178)",
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      type: "text",
      placeholder: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "specialty",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "specialty",
      value: filterData.specialty,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: (e) => {
        changeHandler(e);
        setDepartmentList(specialtiesOptions.find((specialty) => specialty.id == e.target.value).departments);
      },
      options: specialtiesOptions,
    },
    {
      id: "rest",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        setDepartmentList([]);
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "rgb(3, 162, 178)",
      borderColor: "rgb(3, 162, 178)",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
    {
      id: "advanceSearch",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.advanceSearch"),
      classes: `col-12 col-md-6 col-xl-2 ${classes.advanceSearch}`,
      action: () => {
        setAdvanceSearch(true);
      },
      //   backgroundColor: "transparent",
      //   color: "rgb(3, 162, 178)",
      //   borderColor: "rgb(3, 162, 178)",
    },
  ];

  return <>{advanceSearch ? <FilterBox fields={filterFieldsAdvanced} /> : <FilterBox fields={filterFields} />}</>;
};

export default HomeCoursesFilter;

import React, { useEffect, useState } from "react";
import classes from "./Groups.module.css";
import MainBox from "../Shared/Components/MainBox/MainBox";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../assets/icons/Group 3.svg";
import { getMyPlatform } from "../../Services/api/PlatformManagment/PlatformManagmentProvider";
import GroupCard from "./GroupCard/GroupCard";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
export default function Groups() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [myGroups, setMyGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loading, setloading] = useState(false);

  const handleSubmit = () => {
    localStorage.setItem("group", selectedGroup);
    const type = localStorage.getItem("type");
    if (type === "admin") {
      history.push("/admin");
    } else if (type === "group-admin") {
      history.push(`/group-admin`);
    } else if (type === "manager") {
      history.push(`/manager`);
    } else if (type === "accountant") {
      history.push(`/accountant`);
    } else {
      history.push("/private-profile");
    }
    window.location.reload();
  };
  useEffect(() => {
    setSelectedGroup(Number(localStorage.getItem("group")));
  }, [myGroups]);

  useEffect(() => {
    setloading(true);
    getMyPlatform(10000)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          setMyGroups(res.data.data);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  }, []);
  return (
    <div className="container-fluid">
      <div className={`row ${classes["main-box"]}`}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flex: "2 1 auto",
            alignItems: "center",
          }}
        >
          <img
            src={HomeIcon}
            alt=""
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          />

          <p className={classes["title"]}>{t("MyGroups")}</p>
        </div>
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
          }}
        >
          <CustomButton
            action={handleSubmit}
            value={t("ok")}
            colors={"#03a2b2"}
            type={"button"}
            classes={classes.btn}
          />
        </div>
      </div>
      <MainBox
        style={{
          alignItems: "center",
          backgroundColor: "#f7f7f7",
          boxShadow: "none",
          paddingTop: "0rem",
        }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.cardsCont}>
              {myGroups.map((group) => (
                <GroupCard
                  group={group}
                  setSelectedGroup={setSelectedGroup}
                  selectedGroup={selectedGroup}
                />
              ))}
            </div>
          </div>
        )}
      </MainBox>{" "}
    </div>
  );
}

import { useEffect, useState } from "react";
import { AddQuestionToQuestionBankProvider } from "../../../../../Providers/AddQuestionToQuestionBankProvider";
import QuestionForm from "../../../../Admin/Assessments/Form/Part/QuestionForm";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
  getQuestionTypes,
  getQuestion,
  editQuestionToQuestionBank,
  addQuestionToQuestionBank,
} from "../../../../../Services/api/exams/ExamsProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import { useHistory } from "react-router";

function AddQuestionToQuestionBankWrapper({ role }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { courseId, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [question, setQuestion] = useState({});

  const fetchQuestionTypes = () => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestionTypes()
        .then((res) => {
          setQuestionTypes(res.data.types);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });

    return myPromise;
  };

  const fetchQuestionData = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestion(id)
        .then((res) => {
          setQuestion(res.data.Question);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });

    return myPromise;
  };

  useEffect(async () => {
    await fetchQuestionTypes();
    if (id) await fetchQuestionData(id);
    setIsLoading(false);
  }, []);

  function breadcrumbList() {
    let breadcrumbList = [];

    breadcrumbList = [
      {
        id: "home",
        page: <img src={HomeIcon} alt="" />,
        pagePath: "/",
      },
    ];

    breadcrumbList = [
      ...breadcrumbList,
      {
        id: t("trainer.quiz.question_bank"),
        page: t("trainer.quiz.question_bank"),
        pagePath: `/${role}/course/${courseId}/question-bank`,
      },
    ];
    if (id) {
      breadcrumbList.push({
        id: id,
        page: t("edit"),
        active: true,
      });
    } else {
      breadcrumbList.push({
        id: t("TypeFile.add"),
        page: t("TypeFile.add"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  return (
    <div className="container-fluid">
      {isLoading || (id && !question.id) ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12 sm:tw-px-2 tw-py-8">
              {/*TODO*/}
              <Breadcrumb list={breadcrumbList()} />
            </div>
          </div>
          <div
            className="tw-bg-white tw-shadow tw-rounded tw-py-8 tw-px-4 tw-space-y-8"
            style={{ marginBottom: "2rem" }}
          >
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                {id ? t("edit_question") : t("add_question")}
              </div>
              {/* <button
                type="button"
                onClick={() => {
                  setFieldValue("active", !values.active);
                }}
                className={`tw-relative tw-w-20 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                  values.active
                    ? "tw-bg-teal-800 tw-text-white tw-pl-7"
                    : "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
                }`}
              >
                <div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
                  {t(
                    !values.active ? "Exhibition.inactive" : "Exhibition.active"
                  )}
                </div>
                <div
                  className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                    values.active ? "tw-left-2" : "tw-right-2"
                  } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                ></div>
              </button> */}
            </div>
            <QuestionForm
              courseId={courseId}
              onSubmit={async (values) => {
                let payload = {
                  ...values,
                  course_objectives: values.course_objectives.map(
                    (item) => item.id
                  ),
                  options: values.options.map((item) => {
                    let temp = { ...item };
                    delete temp.image;
                    return temp;
                  }),
                  standards: values.standards.map((item) => item.id),
                  parent_id: courseId,
                };
                if (id) {
                  await editQuestionToQuestionBank(payload, id);
                } else {
                  await addQuestionToQuestionBank(payload);
                }
                history.goBack();
              }}
              questionTypes={questionTypes}
              questionData={
                id
                  ? {
                      id: question?.id ?? "",
                      type_id: question?.type_id ?? "",
                      type: question?.type ?? "",
                      title: question?.title?.replace(/<[^>]*>?/gm, "") ?? "",
                      text: question?.text ?? "",
                      media: question.media ? question.media : "",
                      degree: question?.degree ?? "",
                      level: question?.level ?? "",
                      course_objectives: question?.course_objectives
                        ? question.course_objectives
                        : [],
                      standards: question?.standards ? question.standards : [],
                      feedback: question?.feedback ?? "",
                      options: question?.options ? question.options : [],
                      answers: [],
                    }
                  : null
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AddQuestionToQuestionBankWrapper;

import classes from "./CalenderNew.module.css";
import Img from "../../../assets/image/EventDefault.png";
import moment from "moment";
import { utcToLocal } from "../../../utils/utcToLocal";
import DateIcon from "../../../assets/icons/dateIcon.svg";
import LocationIcon from "../../../assets/icons/location1.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export default function EventCard({ event }) {
  const { t, i18n } = useTranslation();
  const [readMore, setreadMore] = useState(false);
  return (
    <div className={classes.eventCard}>
      <div className={`${classes.type} ${classes[event.type.value]}`}></div>
      <img src={event.cover || Img} alt="" className={classes.eventImg} />
      <div className={classes.eventContent}>
        <h3 className={classes.eventTitle}>{event.title}</h3>
        {event.description.length <= 150 ? (
          <p className={classes.eventdesc}>{event.description}</p>
        ) : readMore ? (
          <p className={classes.eventdesc}>
            {event.description}
            <span
              className={classes.readMore}
              onClick={() => setreadMore(false)}
              style={{ marginInlineStart: "5px" }}
            >
              {t("read_less")}
            </span>
          </p>
        ) : (
          <p className={classes.eventdesc}>
            {event.description.slice(0, 150)} ...
            <span
              className={classes.readMore}
              onClick={() => setreadMore(true)}
            >
              {t("read_more")}
            </span>
          </p>
        )}

        <div className={classes.eventDetails}>
          <span className={classes.iconscontainer}>
            <img src={DateIcon} alt="" style={{ marginTop: "-3px" }} />{" "}
            <p className={classes.iconText}>
              {moment(event.date).format("YYYY-MM-DD")}
              {utcToLocal(event.date)}
            </p>
          </span>
          <span className={classes.iconscontainer}>
            <img src={LocationIcon} alt="" />
            <p className={classes.iconText}>{event.location}</p>
          </span>
        </div>
      </div>
    </div>
  );
}

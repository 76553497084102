import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import SearchIcon from "../../../../assets/icons/searchIconHome.svg";
import UserIcon from "../../../../assets/icons/userBlue.svg";
import i18n from "../../../../i18n/i18n";

function DynamicHeaderWithLogin({ setShowSearch }) {
  const { t } = useTranslation();
  const history = useHistory();
  const root = history.location.pathname.split("/")[1];

  document.body.dir = i18n.dir();
  return (
    <div className="header_dinamic">
      <NavLink className="header_btn_color login_as_trainer header_nav_link" to="/login">
        <span className="header_btn">
          <img src={UserIcon} alt="user" />
          {t("loginAsTrainer")}
        </span>
      </NavLink>
      <a
        className="header_btn_color login_as_trainee header_nav_link"
        href={`${process.env.REACT_APP_SERVER_PATH}saml2/main/login`}
      >
        <span className="header_btn">
          <img src={UserIcon} alt="user" />
          {t("loginAsTrainee")}
        </span>
      </a>
      {!root && (
        <div className="header_search" onClick={() => setShowSearch((prev) => !prev)}>
          <img src={SearchIcon} alt="search" />
        </div>
      )}
    </div>
  );
}

export default DynamicHeaderWithLogin;

import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import classes from "./sharePopup.module.css";

const SharePopup = ({ handleCloseSharePopup, course, isProgram }) => {
  const { t, i18n } = useTranslation();
  const ref = useRef();

  const shareSettings = {
    title: t("qassim"),
    url: window.location.href.replace("localhost:3000", process.env.REACT_APP_FRONT_BASE_URL),
    description:
      i18n.language === "en"
        ? `${localStorage.getItem("name_en")} has shared a ${isProgram ? "program" : "course"} (${
            course.name
          }) with you.

${course.brief_description}

Please click here to view the ${isProgram ? "program" : "course"}`
        : `${localStorage.getItem("name_ar")} قام بمشاركة ${isProgram ? "برنامج" : "دورة"} (${course.name}) معك.

${course.brief_description}

يرجى النقر هنا لعرض ال${isProgram ? "برنامج" : "دورة"}`,
  };

  const shareList = [
    {
      name: "Facebook",
      icon: FacebookIcon,
      component: (props) => <FacebookShareButton {...props} />,
    },
    {
      name: "Twitter",
      icon: TwitterIcon,
      component: (props) => (
        <TwitterShareButton
          {...props}
          title={`${shareSettings.title}
        
${shareSettings.description}`}
        />
      ),
    },
    {
      name: "Linkedin",
      icon: LinkedinIcon,
      component: (props) => (
        <LinkedinShareButton
          {...props}
          title={shareSettings.title}
          summary={shareSettings.description}
          source={shareSettings.url}
        />
      ),
    },
    {
      name: "Whatsapp",
      icon: WhatsappIcon,
      component: (props) => (
        <WhatsappShareButton
          {...props}
          title={`${shareSettings.title}

${shareSettings.description}`}
        />
      ),
    },
    {
      name: "Email",
      icon: EmailIcon,
      component: (props) => (
        <EmailShareButton {...props} subject={shareSettings.title} body={shareSettings.description} />
      ),
    },
  ];

  // if click outside the popup, close the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleCloseSharePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleCloseSharePopup]);

  return (
    <div className={classes.sharePopup} ref={ref}>
      <div className={classes.sharePopupContent}>
        {shareList.map((share, index) => {
          return (
            <share.component key={index} url={shareSettings.url} quote={shareSettings.description}>
              <share.icon size={42} round />
            </share.component>
          );
        })}
      </div>
    </div>
  );
};

export default SharePopup;

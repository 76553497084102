import { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import HomeIcon from "../../../../assets/icons/home.svg";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import warning from "../../../../assets/icons/warning-green.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen.svg";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import SunEditor from "suneditor-react";
import toLocalTimezone, { toServerTime } from "../../../../Services/Timezone";
import { getAllUsers } from "../../../../Services/api/Roles/RolesProvider";
import { getCourseInfo } from "../../../../Services/api/courses/courseProvider";
import {
  courseBlock,
  lessonBlock,
} from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import {
  getCountries,
  getSpecialties,
} from "../../../../Services/api/profile/profileProvider";
import {
  addSurvey,
  deleteSurveyQuestion,
  getExhibitionListSurvies,
  getSurvey,
  updateSurvey,
} from "../../../../Services/api/survey/SurveyProvider";
import i18n from "../../../../i18n/i18n";
import { Lang, hasExtension } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import FilesDragAndDropInput from "../../../Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import SelectComponent from "../../../Shared/Components/Select/Select";
import SelectWithSearch from "../../../Shared/Components/SelectWithSearch/SelectWithSearch";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import WarningModal from "../../../Shared/Components/WarningModal/WarningModal";
import { SurveyView } from "../../../trainees/survey/Survey";
import QuestionTypes, { JustPreview, getQuestionType } from "../QuestionTypes";

function MemuIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="grid_1_" data-name="grid (1)" transform="translate(-2 -2)">
        <rect
          id="Rectangle_100"
          data-name="Rectangle 100"
          width="7"
          height="7"
          transform="translate(3 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_101"
          data-name="Rectangle 101"
          width="7"
          height="7"
          transform="translate(14 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_102"
          data-name="Rectangle 102"
          width="7"
          height="7"
          transform="translate(14 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_103"
          data-name="Rectangle 103"
          width="7"
          height="7"
          transform="translate(3 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function PlusIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="Group_105" data-name="Group 105" transform="translate(-347 -498)">
        <g id="plus-square" transform="translate(344.698 495.698)">
          <rect
            id="Rectangle_19"
            data-name="Rectangle 19"
            width="22"
            height="22"
            rx="2"
            transform="translate(3.302 3.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            y2="10"
            transform="translate(14.302 9.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x2="10"
            transform="translate(9.302 14.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
}

function CloseIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="10"
          cy="10"
          r="10"
          transform="translate(2 2)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_35"
          data-name="Line 35"
          x1="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_36"
          data-name="Line 36"
          x2="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function CalendarIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 22"
    >
      <g id="calendar_9_" data-name="calendar (9)" transform="translate(-2 -1)">
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="18"
          height="18"
          rx="2"
          transform="translate(3 4)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_1"
          data-name="Line 1"
          y2="4"
          transform="translate(16 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          y2="4"
          transform="translate(8 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x2="18"
          transform="translate(3 10)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export default function SurveyManager({ role, fromTrainer = false }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showQuestionModel, setShowQuestionModel] = useState(null);
  const [changeSurveyWarning, setChangeSurveyWarning] = useState(null);
  const [survey, setSurvey] = useState({});
  const [previewSurvey, setPreviewSurvey] = useState({});
  const [preview, setPreview] = useState(false);
  const [exhibitions, setExhibitions] = useState([]);
  const [course, setCourse] = useState({});
  const [lessonsBlock, setLessonsBlock] = useState([]);
  const [users, setUsers] = useState([]);
  const [userFetching, setUserFetching] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteQuestion, setDeleteQuestion] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const questionModalRef = useRef();

  const history = useHistory();
  const { t } = useTranslation();

  const { id, targetCourseId, targetLessonId } = useParams();

  const formikRef = useRef();

  const [surveyTypes, setSurveyTypes] = useState([
    {
      id: 1,
      name: "general",
      label: "General",
      label_ar: t("survey.audienceType.all"),
    },
    // {
    //   id: 2,
    //   name: "exhibition",
    //   label: "Exhibition",
    //   label_ar: t("survey.audienceType.exhibition"),
    // },
    {
      id: 3,
      name: "course",
      label: "Course",
      label_ar: t("survey.audienceType.course"),
    },
    {
      id: 4,
      name: "lesson",
      label: "Lesson",
      label_ar: t("survey.audienceType.lesson"),
    },
  ]);

  const [toRoles, setToRoles] = useState(
    role == "trainer"
      ? [
          {
            name: t("survey.select.option.trainee"),
            value: "trainee",
          },
        ]
      : [
          {
            name: t("survey.select.option.all"),
            value: "",
          },
          {
            name: t("survey.select.option.trainee"),
            value: "trainee",
          },
          {
            name: t("survey.select.option.trainer"),
            value: "trainer",
          },
          {
            name: t("survey.select.option.trainer_trainee"),
            value: "trainer_trainee",
          },
        ]
  );

  const dependencyTypes = [
    {
      name: t("survey.select.option.during_course"),
      value: "during_course",
    },
    // {
    //   name: t("survey.select.option.after_course"),
    //   value: "after_course",
    // },
    {
      name: t("survey.select.option.before_certificate"),
      value: "before_certificate",
    },
    // {
    //   name: t("survey.select.option.after_certificate"),
    //   value: "after_certificate",
    // },
  ];

  const [group, setgroup] = useState(0);
  useEffect(() => {
    setgroup(Number(localStorage.getItem("group")));
    getExhibitionListSurvies()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setExhibitions(res.data.data.exhibitions.data);
        }
      })
      .catch((err) => {});

    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);
          setCountriesOptions(
            res.data.countries.map((country) => {
              return {
                id: country.id,
                value: country.id,
                label: Lang == "en" ? country.name : country.name_ar,
              };
            })
          );
        }
      })
      .catch((err) => {});

    getSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const specialties = res.data.data.specialties.data;
          const departments = specialties
            .map((specialty) => specialty.departments)
            .flat();
          setDepartmentsOptions([
            {
              id: "department",
              value: "0",
              label: t("department"),
            },
            ...departments.map((department) => {
              return {
                id: department.id,
                value: department.id,
                label: Lang == "en" ? department.name : department.name_ar,
              };
            }),
          ]);
        }
      })
      .catch((err) => {});

    if (targetCourseId) {
      getCourseInfo(targetCourseId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCourse(res.data.course);
            fetchLessons(targetCourseId);

            // fetch users
            if (id == null || id == undefined) {
              fetchUsers({
                surveyType: 3,
                course_id: targetCourseId,
                role: role == "trainer" ? "trainee" : "",
              });
            }
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (id != null && id != undefined) {
      setIsLoading(true);
      getSurvey(id)
        .then((res) => {
          console.log("res :", res);

          if (res.status && res.status == 200 && res.data.status) {
            const survey = res.data.survey;
            setSurvey({
              ...survey,
              responses_count: parseInt(survey.responses_count),
            });
            fetchUsers(getFormInitialValues(survey));
            if (survey.course_id) fetchLessons(survey.course_id);
            setIsLoading(false);
          } else {
            throw res;
          }
        })
        .catch((err) => {});
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const courseObjectivesOptions = (search) =>
    new Promise((resolve) => {
      courseBlock({ search }).then((res) => {
        resolve(res.data.courses);
      });
    });

  const fetchLessons = (id) => {
    lessonBlock(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setLessonsBlock(res.data.lessons);
        }
      })
      .catch((err) => {});
  };

  const reorderItems = (from, to, list) => {
    var unOrderedQuestions = [...list];
    var sourceItem = unOrderedQuestions.splice(from, 1)[0];
    unOrderedQuestions.splice(to, 0, sourceItem);
    return unOrderedQuestions;
  };

  const SortableItem = SortableElement(
    ({ item, itemIndex, remove, values, errors, setFieldValue }) => {
      return (
        <div
          className={`${
            survey.responses_count ? "tw-pb-4" : "tw-pb-10"
          } tw-ps-12`}
        >
          <QuestionItem
            prefix={`questions.${itemIndex}.`}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            setValues={(values) =>
              setFieldValue(`questions.${itemIndex}`, values)
            }
            item={item}
            itemIndex={itemIndex}
            disabled={!!survey.responses_count}
            onEdit={() => {
              setShowQuestionModel({
                type: item.type,
                questionData: item,
                onSubmit: (question) => {
                  setFieldValue(`questions.${itemIndex}`, question);
                },
              });
            }}
            onDelete={async () => {
              try {
                setDeleteQuestion({
                  item: item,
                  onApprove: async () => {
                    if (item.id) {
                      var response = await deleteSurveyQuestion(item.id);
                      if (response.status == 200) {
                        toast.success(
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            {t("survey.messages.deleteQuestion")}
                          </span>
                        );
                      } else {
                        throw response;
                      }
                    }

                    remove(itemIndex);
                  },
                });
              } catch (error) {
                toast.error(
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    {t("failed_deleting")}
                  </span>
                );
              }
            }}
          />
        </div>
      );
    }
  );

  const SortableList = SortableContainer(
    ({ questions, push, insert, remove, values, errors, setFieldValue }) => {
      return (
        <div>
          {questions.map((item, itemIndex) => (
            <div key={itemIndex} className="tw-relative">
              <div className="tw-h-10 tw-w-10 tw-overflow-hidden tw-absolute tw-start-2 tw-top-0 tw-flex tw-items-center tw-text-gray-400 tw-text-lg">
                {itemIndex + 1}-
              </div>
              <SortableItem
                values={values.questions[itemIndex]}
                errors={errors}
                setFieldValue={setFieldValue}
                item={item}
                index={itemIndex}
                itemIndex={itemIndex}
                push={push}
                remove={remove}
              />
              {survey.responses_count ? (
                <></>
              ) : (
                <div className="tw-absolute tw-z-10 tw-h-10 tw-flex tw-flex-col tw-justify-center tw-bottom-0 tw-inset-x-0">
                  <div className="tw-relative">
                    <hr className="tw-border-dashed" />
                    <button
                      type="button"
                      onClick={() =>
                        setShowQuestionModel({
                          after: itemIndex,
                          onSubmit: (
                            question,
                            after = questions.length - 1
                          ) => {
                            insert(after + 1, question);
                          },
                        })
                      }
                      className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
                    >
                      <div className="tw-h-4 tw-w-4">
                        <PlusIcon />
                      </div>
                      <div>{t("add_question")}</div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  );

  function getRoles(role) {
    return role == ""
      ? []
      : role == "trainer_trainee"
      ? ["trainer", "trainee"]
      : [role];
  }

  useEffect(() => {
    if (questionModalRef?.current) {
      if (showQuestionModel || changeSurveyWarning || deleteQuestion) {
        questionModalRef.current.showModal();
      } else {
        questionModalRef.current.dismissModal();
      }
    }
  }, [
    showQuestionModel,
    changeSurveyWarning,
    deleteQuestion,
    questionModalRef,
  ]);

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setUsers([]);
      let response = await getAllUsers({
        includes: values.except_users ? [] : values.users,
        roles: getRoles(values.role),
        course:
          values.surveyType == 3 || values.surveyType == 4
            ? values.course_id
            : null,
        search: values.search ? values.search : null,
      });
      if (response.status == 200) {
        setUsers(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
      setUserFetching(false);
    }
  };

  function getFormInitialValues(survey) {
    return {
      name: survey?.name ?? "",
      description: survey?.description ?? "",
      thanks_message:
        survey?.thanks_message ?? t("survey.default_thanks_message"),
      surveyType: survey.exhibition_id
        ? 2
        : survey.course_id
        ? survey.lesson_id
          ? 4
          : 3
        : targetLessonId
        ? 4
        : targetCourseId
        ? 3
        : 1,
      logo: survey?.logo ?? "",
      is_disabled: survey?.is_disabled ?? false,
      // is_public: survey?.is_public ?? false,
      is_shared: survey?.is_shared ?? false,
      except_users: survey?.except_users ?? false,
      include_add: false,
      one_page: survey?.one_page ?? false,
      include_view_per: survey.include_view_per ?? false,
      include_achivement_per: survey.include_achivement_per ?? false,
      start_time: survey?.start_time
        ? toLocalTimezone(survey?.start_time)
        : role == "trainer"
        ? ""
        : toLocalTimezone(new Date()),
      end_time: survey?.end_time
        ? toLocalTimezone(survey?.end_time)
        : role == "trainer"
        ? ""
        : toLocalTimezone(new Date()),
      course_id: survey?.course_id ?? (targetCourseId ? targetCourseId : ""),
      lesson_id: survey?.lesson_id ?? (targetLessonId ? targetLessonId : ""),
      exhibition_id: survey?.exhibition_id ?? "",
      dependency_type: survey?.dependency_type
        ? survey?.dependency_type
        : targetLessonId
        ? "after_lesson"
        : "",
      role: survey?.roles
        ? survey.roles.length == 1
          ? survey.roles[0].name
          : survey.roles.length == 0
          ? ""
          : "trainer_trainee"
        : role == "trainer"
        ? "trainee"
        : "",
      questions: survey?.questions ? survey.questions : [],
      users: survey?.users ? survey.users.map((user) => user.id) : [],
      search: "",
      filter_users_by_gender: "0",
      filter_users_by_country_id: "0",
      filter_users_by_department_id: "0",
      filter_users_by_nationality_id: "0",
      group: group,
    };
  }
  function breadcrumbList() {
    let breadcrumbList = [];
    let lesson = {};

    breadcrumbList.push({
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    });

    if (targetCourseId) {
      breadcrumbList.push({
        id: "course",
        page: course?.name,
        pagePath: `/${role}${
          course?.program_id ? `/program/${course.program_id}` : ``
        }/course/${course?.id}`,
      });
    }
    if (targetLessonId) {
      lesson = lessonsBlock.find((i) => i.id == targetLessonId);
      breadcrumbList.push({
        id: "lesson",
        page: lesson?.name,
        pagePath: `/${role}/course/${course?.id}/lesson/${lesson?.id}`,
      });
    }

    breadcrumbList.push({
      id: t("survey.title"),
      page: t("survey.title"),
      pagePath: targetCourseId
        ? `/${role}/course/${course?.id}/survey/list`
        : `/${role}/survey/list`,
    });

    if (id) {
      breadcrumbList.push({
        id: "edit",
        page: survey.name,
        active: true,
      });
    } else {
      breadcrumbList.push({
        id: t("TypeFile.add"),
        page: t("TypeFile.add"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  const genderOptions = [
    {
      id: "gender",
      value: "0",
      label: t("gender"),
    },
    {
      id: "male",
      value: "male",
      label: t("male"),
    },
    {
      id: "female",
      value: "female",
      label: t("female"),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        {isSubmitting && <SkeletonCardOverlay skeletonWidth="100" />}
        {isLoading ? (
          <div
            style={{
              height: "65vh",
              width: "100%",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 sm:tw-px-2 tw-py-8">
                {/*TODO*/}
                <Breadcrumb list={breadcrumbList()} />
              </div>
            </div>
            {preview ? (
              <div>
                <SurveyView
                  survey={previewSurvey}
                  role={role}
                  preview={true}
                  editor={true}
                />
                <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4 tw-py-8">
                  <button
                    type="button"
                    onClick={() => setPreview(false)}
                    className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                  >
                    {t("back")}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={`tw-mb-4 ${preview ? "tw-hidden" : ""}`}>
              <Formik
                ref={formikRef}
                initialValues={getFormInitialValues(survey)}
                onSubmit={async (values, { setErrors }) => {
                  setIsSubmitting(true);
                  // setErrors({});
                  // payload preperation section
                  var data = {};
                  data.group = values.group;
                  data.is_disabled = values.is_disabled ? 1 : 0;
                  // data.include_view_per = values.include_view_per ? 1 : 0;
                  // data.include_achivement_per = values.include_achivement_per ? 1 : 0;
                  data.include_view_per =
                    values.surveyType == 3 &&
                    values.dependency_type == "during_course" &&
                    values.include_view_per
                      ? 1
                      : 0;
                  data.include_achivement_per =
                    values.surveyType == 3 &&
                    values.dependency_type == "during_course" &&
                    values.include_achivement_per
                      ? 1
                      : 0;

                  if (values.surveyType == 1) {
                    data.end_time = toServerTime(values.end_time);
                  }

                  if (!survey.responses_count) {
                    data.name = values.name;
                    data.description = values.description;
                    data.thanks_message = values.thanks_message;
                    if (values.logo) data.logo = values.logo;
                    data.surveyType = values.surveyType;
                    // data.is_public = values.is_public && (values.surveyType == 1 || values.surveyType == 2) ? 1 : 0;
                    data.is_shared = values.is_shared ? 1 : 0;
                    data.one_page = values.one_page ? 1 : 0;

                    data.roles =
                      values.surveyType === 4
                        ? getRoles("trainee")
                        : getRoles(values.role);
                    data.users = values.users;

                    // if not public, and users === [] then add filters
                    if (data.roles.length == 0) {
                      data.filter_users_by_gender =
                        values.filter_users_by_gender !== "0"
                          ? values.filter_users_by_gender === "male"
                            ? 1
                            : 2
                          : null;
                      data.filter_users_by_country_id =
                        values.filter_users_by_country_id !== "0"
                          ? values.filter_users_by_country_id
                          : null;
                      data.filter_users_by_department_id =
                        values.filter_users_by_department_id !== "0"
                          ? values.filter_users_by_department_id
                          : null;
                      data.filter_users_by_nationality_id =
                        values.filter_users_by_nationality_id !== "0"
                          ? values.filter_users_by_nationality_id
                          : null;
                    }

                    // data.except_users = values.except_users ? 1 : 0;
                    data.except_users = 0;

                    if (values.surveyType == 1) {
                      data.start_time = toServerTime(values.start_time);
                    }
                    if (values.surveyType == 2)
                      data.exhibition_id = values.exhibition_id;
                    if (values.surveyType == 3 || values.surveyType == 4) {
                      data.course_id = values.course_id;
                      if (values.surveyType == 4) {
                        data.lesson_id = values.lesson_id;
                        data.dependency_type = "after_lesson";
                      } else {
                        data.lesson_id = "";
                        data.dependency_type = values.dependency_type;
                      }
                    }
                    // dependency is required only when survey is based on a course
                    data.questions = values.questions.map((item, index) => ({
                      ...item,
                      order: index,
                      is_required: item.is_required ? 1 : 0,
                      // options: item.options.filter(o => !o.id)
                    }));
                  }
                  try {
                    var response;
                    if (!id) {
                      response = await addSurvey(data);
                      if (response.status === 201 && response.data.status) {
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("survey.messages.add")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    } else {
                      // delete data.include_view_per;
                      // delete data.include_achivement_per;
                      response = await updateSurvey(id, data);
                      if (response.status === 200 && response.data.status) {
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("survey.messages.edit")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    }
                    if (targetLessonId) {
                      history.push(
                        `/${role}/course/${course?.id}/lesson/${targetLessonId}`
                      );
                    } else if (targetCourseId) {
                      history.push(
                        `/${role}/course/${targetCourseId}/survey${
                          fromTrainer ? "/trainer" : ""
                        }/list`
                      );
                    } else {
                      history.push(
                        `/${role}/survey${fromTrainer ? "/trainer" : ""}/list`
                      );
                    }
                  } catch (err) {
                    if (err.response.data.errors)
                      setErrors(err.response.data.errors);
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        {err.response.data.errors && (
                          <ul>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>
                                      {err.response.data.errors[key]}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}
                      </>
                    );
                  } finally {
                    setIsSubmitting(false);
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.group) {
                    errors.group = t("crud.errors.required");
                  }

                  if (values.surveyType == 1) {
                    if (
                      !values.end_time ||
                      new Date(values.end_time) <= new Date(values.start_time)
                    ) {
                      errors.end_time = t("survey.errors.endTime");
                    }
                  }
                  if (!survey.responses_count) {
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }

                    if (values.logo?.name) {
                      if (
                        !hasExtension(values.logo.name, [
                          ".png",
                          ".jpg",
                          ".jpeg",
                          ".gif",
                        ])
                      ) {
                        errors.logo =
                          "الرجاء ارفاق ملف PNG او JPG او JPEG او GIF فقط";
                      } else if (values.logo.size > 5242880) {
                        errors.logo = "حجم الصورة لا يجب ان يتجاوز 5MG";
                      }
                    }

                    if (values.surveyType == 1) {
                      // validate start time
                      if (
                        !values.start_time ||
                        new Date(values.start_time) < Date.now()
                      ) {
                        errors.start_time = t("survey.errors.startTime");
                      }
                    } else if (values.surveyType == 2) {
                      if (!values.exhibition_id) {
                        errors.exhibition_id = t("crud.errors.required");
                      }
                    } else if (values.surveyType == 3) {
                      if (!values.course_id) {
                        errors.course_id = t("crud.errors.required");
                      }
                      if (!values.dependency_type) {
                        errors.dependency_type = t("crud.errors.required");
                      }
                    } else if (values.surveyType == 4) {
                      if (!values.course_id) {
                        errors.course_id = t("crud.errors.required");
                      }
                      if (!values.lesson_id) {
                        errors.lesson_id = t("crud.errors.required");
                      }
                    }
                    if (values.questions && values.questions.length) {
                      let questions = [];
                      values.questions.forEach((question, index) => {
                        let options = [];
                        let items = [];
                        let item_options = [];
                        question.options?.forEach((option, optionIndex) => {
                          let err = "";
                          if (!option.value) err = t("crud.errors.required");
                          else if (option.value?.name) {
                            if (
                              !hasExtension(option.value.name, [
                                ".png",
                                ".jpg",
                                ".jpeg",
                                ".gif",
                              ])
                            ) {
                              err =
                                "الرجاء ارفاق ملف PNG او JPG او JPEG او GIF فقط";
                            } else if (option.value.size > 5242880) {
                              err = "حجم الصورة لا يجب ان يتجاوز 5MG";
                            }
                          }
                          if (err)
                            options[optionIndex] = {
                              value: err,
                            };
                        });
                        question.items?.forEach((item, itemIndex) => {
                          if (!item.name)
                            items[itemIndex] = {
                              name: t("crud.errors.required"),
                            };
                        });
                        question.item_options?.forEach((item, itemIndex) => {
                          if (!item.name)
                            item_options[itemIndex] = {
                              name: t("crud.errors.required"),
                            };
                        });
                        if (
                          options.length ||
                          items.length ||
                          item_options.length ||
                          !question.name
                        ) {
                          questions[index] = { options, items, item_options };
                          if (!question.name)
                            questions[index].name = t("crud.errors.required");
                        }
                      });
                      if (questions.length) {
                        errors.questions = questions;
                      }
                    } else {
                      errors.questions = t("crud.errors.required");
                    }
                  }
                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  setFieldValue,
                  setValues,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <BasicModal ref={questionModalRef}>
                        {deleteQuestion ? (
                          <SharedModal
                            icon={DeleteIcon}
                            title={t("trainer.quiz.modal.delete.label")}
                            name={deleteQuestion.item.name}
                            confirmMsg={t(
                              "trainer.quiz.modal.delete.description"
                            )}
                            confirmText={t("trainer.quiz.modal.delete.accept")}
                            cancelText={t("Exhibition.cancel")}
                            onCancel={() => {
                              setDeleteQuestion(null);
                            }}
                            onConfirm={() => {
                              deleteQuestion.onApprove();
                              setDeleteQuestion(null);
                            }}
                            isLoading={false}
                          />
                        ) : changeSurveyWarning ? (
                          <WarningModal
                            img={warning}
                            label={t("Exhibition.popupwarninglabel")}
                            description={t(
                              `survey.popupwarning.${changeSurveyWarning.name}`
                            )}
                            dismissText={t("Exhibition.popupdismiss")}
                            dismissColor="#DF4B43"
                            successText={t("Exhibition.popupsuccess")}
                            successColor="#26b3b9"
                            action={() => {
                              if (changeSurveyWarning.name == "surveyType") {
                                const newValues = {
                                  ...values,
                                  surveyType: changeSurveyWarning.value,
                                  dependency_type:
                                    changeSurveyWarning.value == 4
                                      ? "after_lesson"
                                      : "",
                                  role:
                                    changeSurveyWarning.value == 4
                                      ? "trainee"
                                      : values.role,
                                  users: [],
                                  search: "",
                                };
                                setValues(newValues);
                                fetchUsers(newValues);
                              } else if (changeSurveyWarning.name == "role") {
                                const newValues = {
                                  ...values,
                                  role: changeSurveyWarning.value,
                                  users: [],
                                  search: "",
                                };
                                setValues(newValues);
                                if (changeSurveyWarning.value != "")
                                  fetchUsers(newValues);
                              }
                              // else if (changeSurveyWarning.name == "is_public") {
                              //   setFieldValue("is_public", changeSurveyWarning.value);
                              // }

                              setChangeSurveyWarning(null);
                            }}
                            dismiss={() => setChangeSurveyWarning(null)}
                          />
                        ) : showQuestionModel?.type ? (
                          <QuestionWarpper
                            onClose={() =>
                              setShowQuestionModel((old) =>
                                showQuestionModel.questionData
                                  ? null
                                  : {
                                      ...old,
                                      type: null,
                                    }
                              )
                            }
                            onSubmit={(question) => {
                              showQuestionModel.onSubmit(
                                question,
                                showQuestionModel.after
                              );
                              setShowQuestionModel(null);
                            }}
                            type={showQuestionModel.type}
                            questionData={showQuestionModel.questionData}
                          ></QuestionWarpper>
                        ) : showQuestionModel ? (
                          <QuestionMenu
                            onClose={() => setShowQuestionModel(null)}
                            onSelect={(type) =>
                              setShowQuestionModel((old) => ({ ...old, type }))
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </BasicModal>
                      <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                            {id
                              ? t("survey.editSurvey")
                              : t("survey.labels.newSurvey")}
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setFieldValue("is_disabled", !values.is_disabled);
                            }}
                            className={`tw-relative tw-w-20 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                              !values.is_disabled
                                ? "tw-bg-teal-800 tw-text-white tw-pl-7"
                                : "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
                            }`}
                          >
                            <div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
                              {t(
                                values.is_disabled
                                  ? "Exhibition.inactive"
                                  : "Exhibition.active"
                              )}
                            </div>
                            <div
                              className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                                !values.is_disabled ? "tw-left-2" : "tw-right-2"
                              } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                            ></div>
                          </button>
                        </div>
                        {!preview ? (
                          <div className="tw-space-y-4">
                            <div>
                              <label className="tw-block mb-0">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("survey.name")}
                                </div>

                                <Field
                                  name="name"
                                  disabled={survey.responses_count}
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  placeholder={t("survey.name")}
                                />
                              </label>
                              <ErrorMessage
                                name={`name`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                            {/* description field */}
                            <div>
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("survey.description")}
                              </div>

                              <SunEditor
                                placeholder={t("survey.description")}
                                value={values.description}
                                defaultValue={values.description}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "description",
                                      value: e,
                                    },
                                  });
                                }}
                                setOptions={{
                                  height: 150,
                                  rtl: i18n.language === "ar" ? true : false,
                                  buttonList: [
                                    [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "strike",
                                      "align",
                                      "horizontalRule",
                                      "list",
                                      "lineHeight",
                                      "codeView",
                                    ],
                                  ],
                                }}
                                disable={survey.responses_count}
                                setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                              />
                            </div>
                            {/* thanks message field */}
                            <div>
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("survey.thanks_message")}
                              </div>

                              {/* <CKEditor
                                editor={ClassicEditor}
                                disabled={survey.responses_count}
                                className="tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded"
                                data={values.thanks_message}
                                onReady={(editor) => {
                                  setFieldValue(
                                    "thanks_message",
                                    editor?.getData()
                                  );
                                  editor.plugins.get(
                                    "FileRepository"
                                  ).createUploadAdapter = function (loader) {
                                    return new UploadAdapter(loader);
                                  };
                                }}
                                config={{
                                  simpleUpload: {
                                    uploadUrl:
                                      "http://tajah-tebx.test/api/ckeditor/image-upload",
                                    withCredentials: true,

                                    // Headers sent along with the XMLHttpRequest to the upload server.
                                    headers: {
                                      // 'X-CSRF-TOKEN': 'CSRF-Token',
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "token"
                                      )}`,
                                    },
                                  },
                                }}
                                onChange={(event, editor) => {
                                  setFieldValue(
                                    "thanks_message",
                                    editor.getData()
                                  );
                                }}
                              /> */}
                              <SunEditor
                                placeholder={t("survey.thanks_message")}
                                value={values.thanks_message}
                                defaultValue={values.thanks_message}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "thanks_message",
                                      value: e,
                                    },
                                  });
                                }}
                                setOptions={{
                                  height: 150,
                                  rtl: i18n.language === "ar" ? true : false,
                                  buttonList: [
                                    [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "strike",
                                      "align",
                                      "horizontalRule",
                                      "list",
                                      "lineHeight",
                                      "codeView",
                                    ],
                                  ],
                                }}
                                disable={survey.responses_count}
                                setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                              />
                            </div>
                            <div>
                              <FilesDragAndDropInput
                                id={"logo"}
                                label={t("logo")}
                                placeholder={t("logo")}
                                buttonTitle={t("add_logo")}
                                accept={".png,.jpg,.jpeg,.gif"}
                                name={"logo"}
                                error={errors.logo}
                                value={values.logo}
                                disabled={survey.responses_count}
                                onChange={(files) =>
                                  files[0] &&
                                  setFieldValue("logo", files[0], true)
                                }
                              />

                              {values.logo ? (
                                <div className="tw-inline-flex tw-max-w-full tw-items-center tw-space-s-4 tw-bg-gray-100 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-mt-2 tw-w-auto">
                                  <div className="tw-grow tw-min-w-0 tw-flex tw-items-center tw-space-s-2">
                                    {errors.logo ? (
                                      <div className="tw-max-w-full tw-text-xs tw-text-red-700 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                                        {errors.logo}
                                      </div>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            values.logo.name
                                              ? URL.createObjectURL(values.logo)
                                              : values.logo
                                          }
                                          className="tw-h-8 tw-aspect-square tw-rounded-full"
                                        />

                                        <div className="tw-max-w-full tw-text-teal-700 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                                          {values.logo.name
                                            ? values.logo.name
                                            : values.logo.split("/").at(-1)}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  {survey.responses_count ? (
                                    <></>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        setFieldValue("logo", "", true)
                                      }
                                      type="button"
                                      className="tw-text-gray-600 tw-w-5 tw-h-5 tw-shrink-0"
                                    >
                                      <CloseIcon />
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            {values.surveyType == 1 ? (
                              <>
                                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                                  <div>
                                    <label className="tw-block">
                                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                        {t("start_date")}
                                      </div>

                                      <div className="tw-relative">
                                        <DatePicker
                                          popperProps={{
                                            strategy: "fixed",
                                          }}
                                          disabled={survey.responses_count}
                                          selected={new Date(values.start_time)}
                                          defaultValue={Date.parse(
                                            values.start_time
                                          )}
                                          onChange={(date) => {
                                            setFieldValue("start_time", date);
                                            if (date > values.end_time)
                                              setFieldValue("end_time", "");
                                          }}
                                          showTimeSelect
                                          placeholderText={t(
                                            "survey.start_time"
                                          )}
                                          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                          showTime={{ use12Hours: true }}
                                          dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                          minDate={new Date()}
                                          minTime={
                                            values.start_time &&
                                            new Date(
                                              new Date(
                                                values.start_time
                                              ).toDateString()
                                            ) >
                                              new Date(
                                                new Date().toDateString()
                                              )
                                              ? undefined
                                              : Date.now()
                                          }
                                          maxTime={
                                            new Date(
                                              new Date(
                                                values.start_time
                                              ).toDateString()
                                            ) >
                                            new Date(new Date().toDateString())
                                              ? undefined
                                              : new Date().setHours(
                                                  23,
                                                  59,
                                                  0,
                                                  0
                                                )
                                          }
                                        />
                                        <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                      </div>
                                    </label>

                                    <ErrorMessage
                                      name={`start_time`}
                                      component="div"
                                      className="tw-text-xs tw-text-red-700 tw-h-4"
                                    />
                                  </div>

                                  <div>
                                    <label className="tw-block">
                                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                        {t("end_date")}
                                      </div>

                                      <div className="tw-relative">
                                        <DatePicker
                                          popperProps={{
                                            strategy: "fixed",
                                          }}
                                          selected={new Date(values.end_time)}
                                          defaultValue={Date.parse(
                                            values.end_time
                                          )}
                                          onChange={(date) =>
                                            setFieldValue("end_time", date)
                                          }
                                          showTimeSelect
                                          placeholderText={t("survey.end_time")}
                                          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                          showTime={{ use12Hours: true }}
                                          dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                          minDate={
                                            values.start_time
                                              ? Date.parse(values.start_time)
                                              : new Date()
                                          }
                                          minTime={
                                            values.start_time
                                              ? values.end_time &&
                                                new Date(
                                                  new Date(
                                                    values.end_time
                                                  ).toDateString()
                                                ) >
                                                  new Date(
                                                    new Date(
                                                      values.start_time
                                                    ).toDateString()
                                                  )
                                                ? undefined
                                                : Date.parse(values.start_time)
                                              : values.end_time &&
                                                new Date(
                                                  new Date(
                                                    values.end_time
                                                  ).toDateString()
                                                ) >
                                                  new Date(
                                                    new Date().toDateString()
                                                  )
                                              ? undefined
                                              : Date.now()
                                          }
                                          maxTime={
                                            (values.start_time &&
                                              values.end_time &&
                                              new Date(
                                                new Date(
                                                  values.end_time
                                                ).toDateString()
                                              ) >
                                                new Date(
                                                  new Date(
                                                    values.start_time
                                                  ).toDateString()
                                                )) ||
                                            (!values.start_time &&
                                              values.end_time &&
                                              new Date(
                                                new Date(
                                                  values.end_time
                                                ).toDateString()
                                              ) >
                                                new Date(
                                                  new Date().toDateString()
                                                ))
                                              ? undefined
                                              : new Date().setHours(
                                                  23,
                                                  59,
                                                  0,
                                                  0
                                                )
                                          }
                                        />
                                        <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                      </div>
                                    </label>

                                    <ErrorMessage
                                      name={`end_time`}
                                      component="div"
                                      className="tw-text-xs tw-text-red-700 tw-h-4"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("survey_type")}
                                </div>

                                <Select
                                  isDisabled={
                                    targetCourseId ||
                                    targetLessonId ||
                                    survey.responses_count
                                  }
                                  placeholder={t("survey_type")}
                                  defaultValue={surveyTypes.find(
                                    (item) => item.id == values.surveyType
                                  )}
                                  options={surveyTypes}
                                  getOptionLabel={(option) =>
                                    i18n.language == Lang.AR
                                      ? option.label_ar
                                      : option.label
                                  }
                                  getOptionValue={(option) => option.id}
                                  onChange={(item) => {
                                    if (values.users.length) {
                                      setChangeSurveyWarning({
                                        name: "surveyType",
                                        value: item.id,
                                      });
                                    } else {
                                      const newValues = {
                                        ...values,
                                        surveyType: item.id,
                                        dependency_type:
                                          item.id == 4 ? "after_lesson" : "",
                                        role:
                                          item.id == 4
                                            ? "trainee"
                                            : values.role,
                                        users: [],
                                        search: "",
                                      };
                                      setValues(newValues);
                                      fetchUsers(newValues);
                                    }
                                  }}
                                />
                              </label>
                            </div>

                            {values.surveyType == 2 ? (
                              <div>
                                <label className="tw-block">
                                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                    {t("survey.choose_exhibition")}
                                  </div>
                                  <Field
                                    name="exhibition_id"
                                    as="select"
                                    disabled={survey.responses_count}
                                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  >
                                    {values.exhibition_id ? (
                                      <></>
                                    ) : (
                                      <option value="">
                                        {t("survey.choose_exhibition")}
                                      </option>
                                    )}
                                    {exhibitions.map((exhibition) => {
                                      return (
                                        <option
                                          key={exhibition.id}
                                          value={exhibition.id}
                                        >
                                          {exhibition.name}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </label>
                                <ErrorMessage
                                  name={`exhibition_id`}
                                  component="div"
                                  className="tw-text-xs tw-text-red-700 tw-h-4"
                                />
                              </div>
                            ) : values.surveyType == 3 ||
                              values.surveyType == 4 ? (
                              <>
                                <div>
                                  <label className="tw-block">
                                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                      {t("survey.choose_course")}
                                    </div>

                                    <AsyncSelect
                                      isDisabled={
                                        targetCourseId || survey.responses_count
                                      }
                                      placeholder={t("survey.choose_course")}
                                      cacheOptions
                                      defaultOptions
                                      isClearable
                                      defaultValue={survey.course}
                                      value={values.course}
                                      loadOptions={courseObjectivesOptions}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      onChange={(item) => {
                                        const newValues = {
                                          ...values,
                                          course_id: item?.id,
                                          lesson_id: "",
                                          users: [],
                                          search: "",
                                          course: item,
                                        };
                                        setValues(newValues);
                                        fetchUsers(newValues);

                                        if (item) {
                                          fetchLessons(item.id);
                                        }
                                      }}
                                    />
                                  </label>
                                  <ErrorMessage
                                    name={`course_id`}
                                    component="div"
                                    className="tw-text-xs tw-text-red-700 tw-h-4"
                                  />
                                </div>
                                {values.surveyType == 4 ? (
                                  <div>
                                    <label className="tw-block">
                                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                        {t("survey.choose_lesson")}
                                      </div>

                                      <Select
                                        isDisabled={
                                          targetLessonId ||
                                          survey.responses_count
                                        }
                                        placeholder={t("survey.choose_lesson")}
                                        isClearable
                                        value={lessonsBlock.find(
                                          (item) => item.id == values.lesson_id
                                        )}
                                        options={lessonsBlock}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(item) => {
                                          setFieldValue("lesson_id", item.id);
                                        }}
                                      />
                                    </label>
                                    <ErrorMessage
                                      name={`lesson_id`}
                                      component="div"
                                      className="tw-text-xs tw-text-red-700 tw-h-4"
                                    />
                                  </div>
                                ) : null}
                                <div>
                                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                    {t("survey.place")}
                                  </div>
                                  <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                                    {(values.surveyType == 4
                                      ? [
                                          {
                                            name: t(
                                              "survey.select.option.after_lesson"
                                            ),
                                            value: "after_lesson",
                                          },
                                        ]
                                      : dependencyTypes
                                    ).map((i) => (
                                      <label
                                        key={i.value}
                                        className="tw-flex tw-items-center tw-space-s-2 tw-mb-2"
                                      >
                                        <Field
                                          name="dependency_type"
                                          type="radio"
                                          disabled={survey.responses_count}
                                          value={i.value}
                                          className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                        />
                                        <div className="tw-text-gray-600 tw-font-medium">
                                          {i.name}
                                        </div>
                                      </label>
                                    ))}
                                  </div>
                                  <ErrorMessage
                                    name={`dependency_type`}
                                    component="div"
                                    className="tw-text-xs tw-text-red-700 tw-h-4"
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* {values.surveyType == 1 || values.surveyType == 2 ? (
                              <>
                                <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                  <Field
                                    disabled={survey.responses_count}
                                    name="is_public"
                                    type="checkbox"
                                    onChange={({ target: { checked } }) => {
                                      if (values.users.length && checked) {
                                        setChangeSurveyWarning({
                                          name: "is_public",
                                          value: checked,
                                        });
                                      } else {
                                        setFieldValue("is_public", checked);
                                      }
                                    }}
                                    className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                  />
                                  <div className="tw-text-gray-600 tw-font-medium">{t("survey.isPublic")}</div>
                                </label>
                              </>
                            ) : (
                              <></>
                            )} */}
                            {values.surveyType != 1 &&
                            values.surveyType != 2 ? (
                              <>
                                {/* //////////////////////////////////////////////////////////// */}
                                <div>
                                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                    {t("survey.to")}
                                  </div>
                                  <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                                    <div className="width-50">
                                      <SelectComponent
                                        label={t("gender")}
                                        name="filter_users_by_gender"
                                        onchange={handleChange}
                                        options={genderOptions}
                                        value={values.filter_users_by_gender}
                                        error={errors.filter_users_by_gender}
                                      />
                                    </div>
                                    <div className="width-50">
                                      <SelectWithSearch
                                        label={t("nationality")}
                                        name={"filter_users_by_nationality_id"}
                                        onchange={(value) => {
                                          setFieldValue(
                                            "filter_users_by_nationality_id",
                                            value.value
                                          );
                                        }}
                                        options={[
                                          {
                                            id: "nationality",
                                            value: "0",
                                            label: t("nationality"),
                                          },
                                          ...countriesOptions,
                                        ]}
                                        value={{
                                          id: values.filter_users_by_nationality_id,
                                          value:
                                            values.filter_users_by_nationality_id,
                                          label: (() => {
                                            if (
                                              values.filter_users_by_nationality_id ==
                                              "0"
                                            )
                                              return t("nationality");
                                            const filteredCountry =
                                              countries.find(
                                                (country) =>
                                                  country.id ===
                                                  Number(
                                                    values.filter_users_by_nationality_id
                                                  )
                                              );
                                            return filteredCountry
                                              ? `${filteredCountry.nationality} ${filteredCountry.name}`
                                              : null;
                                          })(),
                                        }}
                                        error={
                                          errors.filter_users_by_nationality_id
                                        }
                                        initialValue={t("nationality")}
                                      />
                                    </div>
                                    <div className="width-50">
                                      <SelectWithSearch
                                        label={t("country")}
                                        name="filter_users_by_country_id"
                                        onchange={(value) => {
                                          setFieldValue(
                                            "filter_users_by_country_id",
                                            value.value
                                          );
                                        }}
                                        options={[
                                          {
                                            id: "country",
                                            value: "0",
                                            label: t("country"),
                                          },
                                          ...countriesOptions,
                                        ]}
                                        // value={countriesOptions.find(
                                        //   (country) => Number(country.value) === Number(values.filter_users_by_country_id)
                                        value={{
                                          id: values.filter_users_by_country_id,
                                          value:
                                            values.filter_users_by_country_id,
                                          label: (() => {
                                            if (
                                              values.filter_users_by_country_id ==
                                              "0"
                                            )
                                              return t("country");
                                            return countriesOptions.find(
                                              (country) =>
                                                Number(country.value) ===
                                                Number(
                                                  values.filter_users_by_country_id
                                                )
                                            )?.label;
                                          })(),
                                        }}
                                        error={
                                          errors.filter_users_by_country_id
                                        }
                                        initialValue={t("country")}
                                      />
                                    </div>
                                    <div className="width-50">
                                      <SelectWithSearch
                                        label={t("editProfile.department")}
                                        name={"filter_users_by_department_id"}
                                        onchange={(value) => {
                                          setFieldValue(
                                            "filter_users_by_department_id",
                                            value.value
                                          );
                                        }}
                                        options={departmentsOptions}
                                        value={departmentsOptions.find(
                                          (department) =>
                                            Number(department.value) ===
                                            Number(
                                              values.filter_users_by_department_id
                                            )
                                        )}
                                        error={
                                          errors.filter_users_by_department_id
                                        }
                                        initialValue={t(
                                          "editProfile.department"
                                        )}
                                      />
                                    </div>
                                    <div className="width-50 tw-mt-8">
                                      <CustomButton
                                        value={t("general.reset")}
                                        type="button"
                                        action={() => {
                                          setFieldValue(
                                            "filter_users_by_gender",
                                            "0"
                                          );
                                          setFieldValue(
                                            "filter_users_by_nationality_id",
                                            "0"
                                          );
                                          setFieldValue(
                                            "filter_users_by_country_id",
                                            "0"
                                          );
                                          setFieldValue(
                                            "filter_users_by_department_id",
                                            "0"
                                          );
                                        }}
                                        colors="#036c77"
                                        classes="tw-w-full tw-flex tw-justify-center tw-items-center"
                                      />
                                    </div>
                                  </div>
                                  {values.surveyType != 4 ? (
                                    <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                                      {toRoles.map((i) => (
                                        <label
                                          key={i.value}
                                          className="tw-flex tw-items-center tw-space-s-2 tw-mb-2"
                                        >
                                          <Field
                                            name="role"
                                            type="radio"
                                            value={i.value}
                                            disabled={survey.responses_count}
                                            className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                            onChange={({
                                              target: { value },
                                            }) => {
                                              if (values.users.length) {
                                                setChangeSurveyWarning({
                                                  name: "role",
                                                  value: value,
                                                });
                                              } else {
                                                const newValues = {
                                                  ...values,
                                                  role: value,
                                                  users: [],
                                                  search: "",
                                                };
                                                setValues(newValues);
                                                if (value != "")
                                                  fetchUsers(newValues);
                                              }
                                            }}
                                          />
                                          <div className="tw-text-gray-600 tw-font-medium">
                                            {i.name}
                                          </div>
                                        </label>
                                      ))}

                                      <ErrorMessage
                                        name={`role`}
                                        component="div"
                                        className="tw-text-xs tw-text-red-700 tw-h-4"
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                {values.role != "" ? (
                                  <FieldArray
                                    name="users"
                                    disabled={survey.responses_count}
                                  >
                                    {({ insert, remove, push }) => (
                                      <div className="tw-border tw-rounded tw-divide-y">
                                        <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                                          <div className="tw-flex tw-items-center tw-space-s-4">
                                            <Field
                                              disabled={survey.responses_count}
                                              type="checkbox"
                                              name="include_all"
                                              onChange={() => {
                                                setValues({
                                                  ...values,
                                                  include_all:
                                                    !values.include_all,
                                                  users: values.include_all
                                                    ? []
                                                    : users.length
                                                    ? users
                                                        .filter((user) => {
                                                          return (
                                                            (values.filter_users_by_gender ===
                                                              "0" ||
                                                            user.gender ===
                                                              (values.filter_users_by_gender ===
                                                              "male"
                                                                ? 1
                                                                : 2)
                                                              ? true
                                                              : false) &&
                                                            (values.filter_users_by_country_id ===
                                                              "0" ||
                                                            user.country_id ===
                                                              values.filter_users_by_country_id
                                                              ? true
                                                              : false) &&
                                                            (values.filter_users_by_department_id ===
                                                              "0" ||
                                                            user.department_id ===
                                                              values.filter_users_by_department_id
                                                              ? true
                                                              : false) &&
                                                            (values.filter_users_by_nationality_id ===
                                                              "0" ||
                                                            user.nationality_id ===
                                                              values.filter_users_by_nationality_id
                                                              ? true
                                                              : false)
                                                          );
                                                        })
                                                        .map((user) => user.id)
                                                    : [],
                                                });
                                              }}
                                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-appearance-none tw-border-[1px] tw-rounded tw-border-gray-300 checked:tw-bg-teal-600 checked:tw-border-white checked:tw-border-x-[3px] ${
                                                values.users.length
                                                  ? "checked:tw-border-y-[6px]"
                                                  : "checked:tw-border-y-[3px]"
                                              }`}
                                            />
                                            <div className="tw-font-semibold tw-text-gray-500">
                                              {t("select_names")}
                                            </div>
                                          </div>
                                          <div className="tw-flex tw-items-center tw-space-s-8">
                                            <Field
                                              name="search"
                                              className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                              placeholder={t(
                                                "search_by_name_or_email"
                                              )}
                                              onChange={({
                                                target: { value },
                                              }) => {
                                                setFieldValue("search", value);
                                                fetchUsers({
                                                  ...values,
                                                  search: value,
                                                });
                                              }}
                                            />
                                            <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                                          </div>
                                        </div>
                                        <div className="tw-py-4 tw-pe-4">
                                          <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                                            {userFetching ? (
                                              [...Array(5).keys()].map((i) => (
                                                <div
                                                  key={i}
                                                  className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                                >
                                                  <div className="tw-flex tw-items-center tw-space-s-4">
                                                    <div
                                                      className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                                    ></div>
                                                    <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                                  </div>
                                                  <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                                </div>
                                              ))
                                            ) : users.length ? (
                                              users
                                                .filter((user) => {
                                                  return (
                                                    (values.filter_users_by_gender ===
                                                      "0" ||
                                                    user.gender ===
                                                      (values.filter_users_by_gender ===
                                                      "male"
                                                        ? 1
                                                        : 2)
                                                      ? true
                                                      : false) &&
                                                    (values.filter_users_by_country_id ===
                                                      "0" ||
                                                    user.country_id ===
                                                      values.filter_users_by_country_id
                                                      ? true
                                                      : false) &&
                                                    (values.filter_users_by_department_id ===
                                                      "0" ||
                                                    user.department_id ===
                                                      values.filter_users_by_department_id
                                                      ? true
                                                      : false) &&
                                                    (values.filter_users_by_nationality_id ===
                                                      "0" ||
                                                    user.nationality_id ===
                                                      values.filter_users_by_nationality_id
                                                      ? true
                                                      : false)
                                                  );
                                                })
                                                .map((user) => (
                                                  <button
                                                    key={user.id}
                                                    disabled={
                                                      survey.responses_count
                                                    }
                                                    type="button"
                                                    onClick={() => {
                                                      const userIndex =
                                                        values.users?.findIndex(
                                                          (i) => i == user.id
                                                        );
                                                      if (
                                                        userIndex !=
                                                          undefined &&
                                                        userIndex >= 0
                                                      )
                                                        remove(userIndex);
                                                      else push(user.id);
                                                    }}
                                                    className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full`}
                                                  >
                                                    <div className="tw-flex tw-items-center tw-space-s-4">
                                                      <div
                                                        className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                                          (values.users.includes(
                                                            user.id
                                                          ) &&
                                                            !values.except_users) ||
                                                          (!values.users.includes(
                                                            user.id
                                                          ) &&
                                                            values.except_users)
                                                            ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                            : "tw-border-gray-300"
                                                        }`}
                                                      ></div>
                                                      <div>{user.name}</div>
                                                    </div>
                                                    <div>{user.email}</div>
                                                  </button>
                                                ))
                                            ) : (
                                              <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                                {t("there_no_matching_users")}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </FieldArray>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                              <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                <Field
                                  disabled={survey.responses_count}
                                  name="one_page"
                                  type="checkbox"
                                  className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                />
                                <div className="tw-text-gray-600 tw-font-medium">
                                  {t("survey.one_page_label")}
                                </div>
                              </label>
                              <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                <Field
                                  disabled={survey.responses_count}
                                  name="is_shared"
                                  type="checkbox"
                                  className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                />
                                <div className="tw-text-gray-600 tw-font-medium">
                                  {t("survey.isShared")}
                                </div>
                              </label>
                              <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                <Field
                                  disabled={
                                    values.surveyType != 3 ||
                                    (values.surveyType == 3 &&
                                      values.dependency_type !==
                                        "during_course")
                                  }
                                  name="include_view_per"
                                  type="checkbox"
                                  checked={
                                    values.surveyType == 3 &&
                                    values.dependency_type == "during_course" &&
                                    values.include_view_per
                                  }
                                  className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                  onChange={({ target: { checked } }) => {
                                    setFieldValue("include_view_per", checked);
                                    // updateTrackingStatus({
                                    //   id: survey.id,
                                    //   payload: {
                                    //     include_view_per: checked ? 1 : 0,
                                    //   },
                                    // });
                                  }}
                                />
                                <div className="tw-text-gray-600 tw-font-medium">
                                  {t("include_view_per")}
                                </div>
                              </label>
                              <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                <Field
                                  disabled={
                                    values.surveyType != 3 ||
                                    (values.surveyType == 3 &&
                                      values.dependency_type !==
                                        "during_course")
                                  }
                                  name="include_achivement_per"
                                  type="checkbox"
                                  checked={
                                    values.surveyType == 3 &&
                                    values.dependency_type == "during_course" &&
                                    values.include_achivement_per
                                  }
                                  className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                  onChange={({ target: { checked } }) => {
                                    setFieldValue(
                                      "include_achivement_per",
                                      checked
                                    );
                                    // updateTrackingStatus({
                                    //   id: survey.id,
                                    //   payload: {
                                    //     include_achivement_per: checked ? 1 : 0,
                                    //   },
                                    // });
                                  }}
                                />
                                <div className="tw-text-gray-600 tw-font-medium">
                                  {t("include_achivement_per")}
                                </div>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div>
                          <div className="tw-text-teal-600 tw-text-xl tw-mb-4">
                            {t("survey_questions")}
                          </div>

                          <FieldArray
                            name="questions"
                            disabled={survey.responses_count}
                          >
                            {({ insert, remove, push }) => (
                              <div>
                                <div className="tw-h-10 tw-flex tw-flex-col tw-justify-center">
                                  {survey.responses_count ? (
                                    <></>
                                  ) : (
                                    <div className="tw-relative">
                                      <hr className="tw-border-dashed" />
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setShowQuestionModel({
                                            onSubmit: (question, after) => {
                                              insert(0, question);
                                            },
                                          })
                                        }
                                        className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
                                      >
                                        <div className="tw-h-4 tw-w-4">
                                          <PlusIcon />
                                        </div>
                                        <div>{t("add_question")}</div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <SortableList
                                  disabled={survey.responses_count}
                                  questions={values.questions}
                                  onSortEnd={({ oldIndex, newIndex }) => {
                                    setFieldValue(
                                      `questions`,
                                      reorderItems(
                                        oldIndex,
                                        newIndex,
                                        values.questions
                                      )
                                    );
                                  }}
                                  values={values}
                                  errors={errors}
                                  setFieldValue={setFieldValue}
                                  remove={remove}
                                  push={push}
                                  insert={insert}
                                  disableAutoscroll
                                  useDragHandle={true}
                                  preview={preview}
                                />
                              </div>
                            )}
                          </FieldArray>

                          <ErrorMessage
                            name="questions"
                            component="div"
                            className="tw-text-xs tw-text-red-700 tw-h-4"
                          />
                        </div>

                        <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                          <button
                            onClick={() => {
                              setPreviewSurvey({
                                id: survey.id ?? 0,
                                name: values.name,
                                description: values.description,
                                thanks_message: values.thanks_message,
                                // is_public: values.is_public,
                                course_id: values.course_id,
                                lesson_id: values.lesson_id,
                                exhibition_id: values.exhibition_id,
                                one_page: values.one_page,
                                start_time: [1, 2].includes(values.surveyType)
                                  ? values.start_time
                                  : "",
                                end_time: [1, 2].includes(values.surveyType)
                                  ? values.end_time
                                  : "",
                                logo: values.logo.name
                                  ? URL.createObjectURL(values.logo)
                                  : values.logo,
                                roles: getRoles(values.role).map(
                                  (item, index) => ({
                                    id: item.id ?? index + 1,
                                    name: item.name ?? item,
                                  })
                                ),
                                questions: values.questions.map(
                                  (question, index) => ({
                                    id: question.id ?? `preview-${index}`,
                                    name: question.name,
                                    is_required: question.is_required,
                                    type: question.type,
                                    order: index,
                                    options: question.options.map(
                                      (item, itemIndex) => ({
                                        id: item.id ?? `preview-${itemIndex}`,
                                        value:
                                          question.type == "graphical_rating" &&
                                          item.value.name
                                            ? URL.createObjectURL(item.value)
                                            : item.value,
                                      })
                                    ),
                                    items: question.items.map(
                                      (item, itemIndex) => ({
                                        id: item.id ?? `preview-${itemIndex}`,
                                        name: item.name,
                                      })
                                    ),
                                    item_options: question.item_options.map(
                                      (item, itemIndex) => ({
                                        id: item.id ?? `preview-${itemIndex}`,
                                        name: item.name,
                                      })
                                    ),
                                  })
                                ),
                              });
                              setPreview(true);
                            }}
                            type="button"
                            className="tw-flex tw-items-center tw-justify-center tw-bg-teal-700 tw-py-2 tw-px-3 tw-rounded"
                          >
                            <li
                              className={`fal tw-w-4 tw-text-white ${
                                preview ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </button>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                          >
                            {t("survey.buttons.save")}
                          </button>
                        </div>
                      </div>
                    </>
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function QuestionItem({
  prefix,
  values,
  errors,
  setFieldValue,
  setValues,
  item,
  disabled = false,
  itemIndex,
  onEdit,
  onDelete,
}) {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  const questionType = getQuestionType(item.type);

  const SortableHandler = SortableHandle(({}) => (
    <div className="tw-h-4 tw-w-4 tw-cursor-move">
      <MemuIcon />
    </div>
  ));

  return (
    <div className="tw-border tw-border-gray-300 tw-rounded tw-divide-y">
      <div
        onClick={() => setShow(!show)}
        className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-2 tw-text-gray-500 tw-cursor-pointer"
      >
        <div className="tw-flex tw-items-center tw-space-s-4">
          {disabled ? <></> : <SortableHandler />}
          <div className="tw-flex tw-items-center tw-flex-wrap">
            <div>{`${item.name} `}</div>
            <div>{`(${t(`survey_types.${item.type}`)}${
              item.is_required
                ? ` - ${t("survey.required")}`
                : ` - ${t("survey.optional")}`
            })`}</div>
          </div>
        </div>
        {disabled ? (
          <></>
        ) : (
          <div className="tw-flex tw-items-center tw-space-s-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
              type="button"
              className="tw-bg-white"
            >
              <PenIcon className="tw-h-4 tw-w-4 tw-stroke-amber-500" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              type="button"
              className="tw-bg-white"
            >
              <CloseIcon className="tw-h-4" />
            </button>
          </div>
        )}
      </div>
      <div className={`tw-p-4 ${show ? "" : "tw-hidden"}`}>
        {["text"].includes(item.type) ? (
          <questionType.component
            prefix={prefix}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            setValues={setValues}
            questionId={item?.id}
            preview
          />
        ) : (
          <JustPreview>
            <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
              <div className="tw-pointer-events-none">
                <questionType.responseComponent
                  question={{
                    ...values,
                    id: values.id ?? 0,
                    options: values.options.map((option, index) => ({
                      ...option,
                      id: option.id ?? index + 1,
                      value:
                        values.type == "graphical_rating" && option.value.name
                          ? URL.createObjectURL(option.value)
                          : option.value,
                    })),
                    items: values.items.map((item, index) => ({
                      ...item,
                      id: item.id ?? index + 1,
                    })),
                    item_options: values.item_options.map(
                      (item_option, index) => ({
                        ...item_option,
                        id: item_option.id ?? index + 1,
                      })
                    ),
                  }}
                  prefix={""}
                  values={{ answers: [] }}
                  errors={{}}
                  setFieldValue={() => {}}
                  preview
                />
              </div>
            </div>
          </JustPreview>
        )}
      </div>
    </div>
  );
}

function QuestionMenu({ onClose, onSelect }) {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full md:tw-w-2/3 lg:tw-w-3/4 xl:tw-w-1/2 tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">
            {t("add_survey_questions")}
          </div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <div className="tw-px-8 tw-py-3">
          <div className="tw-text-lg tw-text-gray-500 tw-mb-4">
            {t("select_question_type")}
          </div>
          <div className="tw-mb-2">
            {QuestionTypes.map((group, index) => (
              <div key={index} className="tw-space-y-3">
                <div className="tw-font-bold tw-text-teal-600">
                  {t(`${group.title}`)}
                </div>
                <div className="tw-flex tw-flex-wrap">
                  {group.children
                    .filter((child) => child.type != "section")
                    .map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => {
                          onSelect(item.type);
                        }}
                        className="tw-flex tw-items-center tw-space-s-3 !tw-me-8 tw-mb-4 tw-text-gray-400 hover:tw-text-gray-500 tw-bg-white"
                      >
                        <div className="">{item.icon}</div>
                        <div className="tw-font-extralight">
                          {t(`survey_types.${item.type}`)}
                        </div>
                      </button>
                    ))}
                </div>
              </div>
            ))}
          </div>

          <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
            <button
              type="button"
              onClick={onClose}
              className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
            >
              {t("next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuestionWarpper({ onSubmit, type, questionData, onClose }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();
  const questionType = getQuestionType(type);
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full md:tw-w-2/3 lg:tw-w-3/4 xl:tw-w-1/2 tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">{`${t(
            questionData ? "edit_question" : "add_question"
          )} / ${t(`survey_types.${type}`)}`}</div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <Formik
          initialValues={{
            type: type,
            is_required: questionData?.is_required ? true : false,
            name: questionData?.name ?? "",
            options: questionData?.options ? questionData.options : [],
            items: questionData?.items ? questionData.items : [],
            item_options: questionData?.item_options
              ? questionData.item_options
              : [],
          }}
          onSubmit={async (values) => {
            if (questionData?.id) values.id = questionData.id;
            values.options = values.options.map((option) => ({
              id: option.id,
              value: option.value,
            }));
            onSubmit(values);
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            if (!values.name) {
              errors.name = t("crud.errors.required");
            }

            let options = [];
            let items = [];
            let item_options = [];
            values.options?.forEach((option, optionIndex) => {
              let err = "";
              if (!option.value) err = t("crud.errors.required");
              else if (option.value?.name) {
                if (
                  !hasExtension(option.value.name, [
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".gif",
                  ])
                ) {
                  err = "الرجاء ارفاق ملف PNG او JPG او JPEG او GIF فقط";
                } else if (option.value.size > 5242880) {
                  err = "حجم الصورة لا يجب ان يتجاوز 5MG";
                }
              }
              if (err)
                options[optionIndex] = {
                  value: err,
                };
            });
            values.items?.forEach((item, itemIndex) => {
              if (!item.name)
                items[itemIndex] = { name: t("crud.errors.required") };
            });
            values.item_options?.forEach((item, itemIndex) => {
              if (!item.name)
                item_options[itemIndex] = {
                  name: t("crud.errors.required"),
                };
            });
            if (options.length) errors.options = options;
            if (items.length) errors.items = items;
            if (item_options.length) errors.item_options = item_options;

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({
            setFieldValue,
            setValues,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-px-8 tw-py-4 tw-space-y-3">
                <div className="tw-space-y-3 tw-mb-3">
                  <div>
                    <label className="tw-block">
                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("question_title")}
                      </div>

                      <Field
                        name="name"
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("question_title")}
                      />
                    </label>
                    <ErrorMessage
                      name={`name`}
                      component="div"
                      className="tw-text-xs tw-text-red-700 tw-h-4"
                    />
                  </div>
                  <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                    <Field
                      type="checkbox"
                      name="is_required"
                      className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                    />
                    <div className="tw-text-gray-600 tw-font-medium">
                      {t("is_required_title")}
                    </div>
                  </label>
                </div>

                <div className="tw-mb-4">
                  <questionType.component
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    questionId={questionData?.id}
                    preview={!!questionData}
                  />
                </div>
                <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                  <button
                    type="submit"
                    className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getAllSpecialty } from "../../../Services/api/HomeCoursesProvider";
import CategoryCard from "../../Shared/Components/categoryCard/CategoryCard";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import CardCarousel from "../../Shared/Components/SlickCarousel/SlickCarousel";
import classes from "./Categories.module.css";

export default function Categories() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const getSpecialties = () => {
    getAllSpecialty()
      .then((res) => {
        if (res.status && res.status === 200) {
          setSpecialtiesList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSpecialties();
  }, []);
  return (
    <div className={classes.Categories}>
      <div className="container-fluid">
        <div className={classes.CategoriesCont}>
          <div className={classes.titleCont}>
            <div />
            <p className={classes.catTitle}>{t("categories")}</p>
            <CustomButton
              value={t("ViewAll")}
              colors={"#fff"}
              classes={classes.showMoreBtn}
              action={() => history.push("/training-courses")}
            />
          </div>
          <div className={classes.cardsCont}>
            <CardCarousel slidesToShow={5} slidesToScroll={5} courses={{ maxWidth: "100%" }}>
              {specialtiesList
                .filter((specialty, index) => index < 20)
                .map((specialty) => (
                  <CategoryCard title={specialty.name} image={specialty.image} key={specialty.id} />
                ))}
            </CardCarousel>
          </div>
          <div className={classes.cardsContMobile}>
            {specialtiesList
              .filter((specialty, index) => index < 4)
              .map((specialty) => (
                <div className={classes.cardCont} key={specialty.id}>
                  <CategoryCard title={specialty.name} image={specialty.image} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

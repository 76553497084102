import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import UploadAdapter from "../../../../CKEditor/Adapter/UploadAdapter";
import TicketDataValueRules from "../../../../Enums/TicketDataValueRules";
import { getExhibitionList } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import {
  getTicketSubjects,
  ticketAdd,
  ticketCreate,
} from "../../../../Services/api/Tickets/TicketsProvider";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { getAllCourses } from "../../../../Services/api/courses/courseProvider";
import { fetchAllProgram } from "../../../../Services/api/program/programContext";
import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilesDragAndDropInput from "../../../Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
function AddTicket(props) {
  require("./Add.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [types, setTypes] = useState([]);
  const [courses, setCourses] = useState("");
  const [subject, setSubject] = useState([]);
  const inputFile = useRef(null);
  const [type, setType] = useState("");
  const [programs, setPrograms] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const authContext = useContext(AuthContext);

  const getAllCoursesHandler = async () => {
    getAllCourses()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourses(res?.data?.courses ?? []);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  const getAllProgramsHandler = async () => {
    fetchAllProgram()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPrograms(res?.data?.data?.programs ?? []);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  const getAllExhibitionsHandler = async () => {
    getExhibitionList()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setExhibitions(res?.data?.data?.exhibitions?.data ?? []);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  useEffect(() => {
    ticketAdd()
      .then((res) => {
        // setCourse(res.data.data[0].courses);
        setTypes(res.data.data.types);
        setPriorities(res.data.data.priorities);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getAllCoursesHandler();
    getAllProgramsHandler();
    getAllExhibitionsHandler();
  }, []);

  const handleSubjects = (id) => {
    getTicketSubjects(id)
      .then((res) => {
        setSubject(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  function breadcrumbList() {
    let breadcrumbList = [];
    if (authContext.inAdminPanel) {
      breadcrumbList = [
        {
          id: t("admin.label.admin_label"),
          page: t("admin.label.admin_label"),
          pagePath: "/admin",
        },
        {
          id: t("admin.label.list_tickets"),
          page: t("admin.label.list_tickets"),
          pagePath: `/admin/tickets`,
        },
      ];
    } else {
      breadcrumbList = [
        {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        },
        {
          id: t("admin.label.list_tickets"),
          page: t("admin.label.list_tickets"),
          pagePath: `/tickets`,
        },
      ];
    }
    if (false) {
    } else {
      breadcrumbList.push({
        id: t("admin.label.add_ticket"),
        page: t("admin.label.add_ticket"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  const setTypeHandler = (type) => {
    switch (type) {
      case "1":
        setType("course");
        break;
      case "2":
        setType("program");
        break;
      case "3":
        setType("exhibition");
        break;
      default:
        setType("");
    }
  };
  const checkDisplayByType = () => {
    return type === "course" || type === "program" || type === "exhibition";
  };

  const checkOptionsByType = () => {
    switch (type) {
      case "course":
        return courses;
      case "program":
        return programs;
      case "exhibition":
        return exhibitions;
      default:
        return [];
    }
  };

  const checkOptionNameByType = (option) => {
    switch (type) {
      case "course":
      case "exhibition":
        return option.name;
      case "program":
        return option.title;
      default:
        return "";
    }
  };

  const displayLabelByType = {
    course: t("crud.placeholders.select.course"),
    program: t("crud.placeholders.select.program"),
    exhibition: t("crud.placeholders.select.exhibition"),
  };

  const displayTypeIdByType = {
    course: "course_id",
    program: "program_id",
    exhibition: "exhibition_id",
  };

  return (
    <>
      <div className="container-fluid add-ticket">
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList()} />
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div>
            {globalIsSubmitting ? (
              <SkeletonCardOverlay borderRadius={20} />
            ) : null}
            <Formik
              initialValues={{
                type_id: "",
                priority: "",
                description: "",
                subject_id: "",
                course_id: "",
                program_id: "",
                exhibition_id: "",
                file: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setGlobalIsSubmitting(true);
                  setSubmitting(true);

                  const { course_id, program_id, exhibition_id, ...payload } =
                    values;
                  if (type === "course") {
                    payload.course_id = course_id;
                  }
                  if (type === "program") {
                    payload.program_id = program_id;
                  }
                  if (type === "exhibition") {
                    payload.exhibition_id = exhibition_id;
                  }

                  let formData = new FormData();
                  Object.keys(payload).forEach((field) => {
                    formData.append(field, payload[field]);
                  });
                  let response = await ticketCreate(formData);
                  if (response.status == 200 && response.data.status) {
                    if (authContext.inAdminPanel) {
                      history.push("/admin/tickets");
                    } else {
                      history.push("/tickets");
                    }
                  } else {
                    alert("Failure");
                  }
                  setGlobalIsSubmitting(false);
                  setSubmitting(false);
                } catch (err) {
                  if (
                    err.response &&
                    err.response.data &&
                    err.response.data.errors != null &&
                    typeof err.response.data.errors === "object" &&
                    !Array.isArray(err.response.data.errors)
                  ) {
                    const issues = [];
                    Object.keys(err.response.data.errors).forEach((key) => {
                      issues.push(
                        Array.isArray(err.response.data.errors[key])
                          ? err.response.data.errors[key][0]
                          : ""
                      );
                    });
                    toast.error(
                      <>
                        <span style={{ fontSize: 14 }}>
                          {err.response.data.msg}:
                        </span>
                        <ul>
                          {issues.map((item, index) => {
                            return (
                              <li key={index}>
                                <span style={{ fontSize: 12 }}>{item}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  } else if (typeof err === "string") {
                    toast.error(<span style={{ fontSize: 14 }}>{err}</span>);
                  }
                  setSubmitting(false);
                  setGlobalIsSubmitting(false);
                }
              }}
              validateOnChange={hasSubmitted}
              validate={(values) => {
                setHasSubmitted(true);
                const errors = {};
                if (!values.type_id) {
                  errors.type_id = t("crud.errors.required");
                } else {
                  const activeType = types.find(
                    (item) => item.id == values.type_id
                  );

                  if (
                    types.filter((item) => {
                      if (item.id == values.type_id) {
                        return item;
                      }
                    })[0].attachment["value"] ===
                      TicketDataValueRules.required &&
                    !values.file
                  ) {
                    errors.file = t("crud.errors.required");
                  }
                  if (values.file) {
                    const fileExtension = values.file.name
                      .split(".")
                      .pop()
                      .toLowerCase();
                    const fileSize = values.file.size / 1024;
                    const allowedFileType = activeType?.allowed_files.find(
                      (type) => type.extension === fileExtension
                    );
                    if (!allowedFileType) {
                      errors.file = t("crud.errors.file_extension_not_allowed");
                    }
                    if (
                      Number.parseFloat(fileSize).toFixed(2) >=
                      allowedFileType?.size
                    ) {
                      errors.file = `${t("crud.errors.file_size_too_large")} (${
                        allowedFileType?.size
                      } KB)`;
                    }
                  }
                  if (
                    displayTypeIdByType[type] &&
                    !values[displayTypeIdByType[type]]
                  ) {
                    errors[displayTypeIdByType[type]] = t(
                      "crud.errors.required"
                    );
                  }
                }

                if (!values.priority) {
                  errors.priority = t("crud.errors.required");
                }

                // if (!values.course_id) {
                //   errors.course_id = t("crud.errors.required");
                // }

                if (!values.subject_id) {
                  errors.subject_id = t("crud.errors.required");
                }

                if (!values.description) {
                  errors.description = t("crud.errors.required");
                }

                return errors;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setErrors,
              }) => (
                <form onSubmit={handleSubmit} className="admin_add_form ">
                  <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                    <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                      {t("admin.label.list_tickets")}
                    </div>
                    <div className="tw-space-y-1">
                      <div>
                        <label className="tw-block mb-0">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("crud.placeholders.select.type")}
                          </div>
                          <Select
                            name="type_id"
                            id="type_id"
                            isClearable={true}
                            options={types.filter(
                              (item) => item.status.value == "active"
                            )}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(item) => {
                              if (!item) {
                                setFieldValue("type_id", "");
                                delete values.file;
                                let errorsObj = errors;
                                delete errorsObj.file;
                                setErrors(errorsObj);
                              } else {
                                setFieldValue("type_id", item.id);
                                handleSubjects(item.id);
                                setFieldValue("file", "");
                                setFieldValue("subject_id", "");
                                setFieldValue("course_id", "");
                                setFieldValue("program_id", "");
                                setFieldValue("exhibition_id", "");
                                setTypeHandler(item.type);
                              }
                            }}
                            placeholder={t("crud.placeholders.select.type")}
                            // classNames={{menu: "tw-z-[600]"}}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </label>
                        <div className={"form-input-error-space"}>
                          {errors.type_id ? errors.type_id : null}
                        </div>
                      </div>

                      <div>
                        <label className="tw-block mb-0">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("crud.placeholders.select.priority")}
                          </div>
                          <Select
                            name="priority"
                            id="priority"
                            isClearable={true}
                            options={priorities}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            onChange={(item) => {
                              if (!item) {
                                setFieldValue("priority", "");
                              } else {
                                setFieldValue("priority", item.value);
                              }
                            }}
                            placeholder={t("crud.placeholders.select.priority")}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </label>
                        <div className={"form-input-error-space"}>
                          {errors.priority ? errors.priority : null}
                        </div>
                      </div>

                      {values.type_id && (
                        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                          <div>
                            <label className="tw-block mb-0">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("crud.placeholders.select.subject")}
                              </div>
                              <Select
                                name="subject_id"
                                id="subject_id"
                                isClearable={true}
                                options={subject}
                                value={
                                  subject?.find(
                                    (item) => item.id == values.subject_id
                                  ) || ""
                                }
                                getOptionLabel={(option) =>
                                  i18n.language === Lang.AR
                                    ? option.name_ar
                                    : option.name_en
                                }
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  if (!item) {
                                    setFieldValue("subject_id", "");
                                  } else {
                                    setFieldValue("subject_id", item.id);
                                  }
                                }}
                                placeholder={t(
                                  "crud.placeholders.select.subject"
                                )}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </label>
                            <div className={"form-input-error-space"}>
                              {errors.subject_id ? errors.subject_id : null}
                            </div>
                          </div>
                          {checkDisplayByType() && (
                            <div>
                              <label className="tw-block mb-0">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {displayLabelByType[type]}
                                </div>
                                <div className="tw-z-[600]"></div>
                                <Select
                                  name={displayTypeIdByType[type]}
                                  id={displayTypeIdByType[type]}
                                  isClearable={true}
                                  options={checkOptionsByType()}
                                  getOptionLabel={(option) =>
                                    checkOptionNameByType(option)
                                  }
                                  getOptionValue={(option) => option.id}
                                  onChange={(item) => {
                                    if (!item) {
                                      setFieldValue(
                                        displayTypeIdByType[type],
                                        ""
                                      );
                                    } else {
                                      setFieldValue(
                                        displayTypeIdByType[type],
                                        item.id
                                      );
                                    }
                                  }}
                                  placeholder={displayLabelByType[type]}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </label>
                              <div className={"form-input-error-space"}>
                                {errors[displayTypeIdByType[type]]
                                  ? errors[displayTypeIdByType[type]]
                                  : null}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <div>
                        <div>
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("crud.placeholders.description")}
                          </div>

                          {/* <CKEditor
                            editor={ClassicEditor}
                            className="tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded"
                            data={values.description}
                            onReady={(editor) => {
                              setFieldValue("description", editor?.getData());
                              if (editor)
                                editor.plugins.get(
                                  "FileRepository"
                                ).createUploadAdapter = function (loader) {
                                  return new UploadAdapter(loader);
                                };
                            }}
                            config={{
                              simpleUpload: {
                                uploadUrl:
                                  "http://tajah-tebx.test/api/ckeditor/image-upload",
                                withCredentials: true,

                                // Headers sent along with the XMLHttpRequest to the upload server.
                                headers: {
                                  // 'X-CSRF-TOKEN': 'CSRF-Token',
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "token"
                                  )}`,
                                },
                              },
                            }}
                            onChange={(event, editor) => {
                              setFieldValue("description", editor?.getData());
                            }}
                          /> */}
                          <SunEditor
                            placeholder={t("crud.placeholders.description")}
                            value={values.description}
                            defaultValue={values.description}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "description", value: e },
                              });
                            }}
                            setOptions={{
                              height: 150,
                              rtl: i18n.language === "ar" ? true : false,
                              buttonList: [
                                ["undo", "redo"],
                                ["fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],

                                [
                                  "fontColor",
                                  "hiliteColor",
                                  "outdent",
                                  "indent",
                                ],
                                ["align", "horizontalRule", "list", "table"],
                                ["link", "image", "video"],
                                ["codeView"],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                          />
                        </div>
                        <div className={"form-input-error-space"}>
                          {errors.description ? errors.description : null}
                        </div>
                      </div>

                      {values.type_id &&
                      types.filter((item) => {
                        if (item.id === values.type_id) {
                          return item;
                        }
                      })[0].attachment["value"] !==
                        TicketDataValueRules.notAllowed ? (
                        <FilesDragAndDropInput
                          id={"file"}
                          label={t("trainer.course.lesson.attachment")}
                          placeholder={t("trainer.course.lesson.attachment")}
                          buttonTitle={
                            <FileIcon className="tw-w-4 tw-h-4 tw-mx-2 tw-fill-white tw-stroke-white" />
                          }
                          accept={types
                            .filter((item) => {
                              if (item.id === values.type_id) {
                                return item;
                              }
                            })[0]
                            .allowed_files.map((item) => item.extension)
                            .join(",")}
                          name={"file"}
                          error={errors.file}
                          value={values.file}
                          onChange={(files) => {
                            // checkFileAllowed(files[0], values.type_id)
                            files[0] && setFieldValue("file", files[0], true);
                          }}
                          canRemove={true}
                          removeAction={() => {
                            setFieldValue("file", "");
                          }}
                        />
                      ) : null}

                      <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4 tw-pt-8">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                        >
                          {t("crud.placeholders.submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
}

export default AddTicket;

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import noResult from "../../../assets/image/Noresult.png";
import i18n from "../../../i18n/i18n";
import { CourseContext } from "../../../Services/api/courses/courseContext";
import { getAllCourses } from "../../../Services/api/courses/courseProvider";
import { getPublicCoursesList } from "../../../Services/api/PublicCourseListProvider";
import { DepartmentContext } from "../../../Services/api/toutorials/categoryContext";
import { getAllDepartment } from "../../../Services/api/toutorials/categoryProvider";
import CourseCard from "../../Shared/Components/CourseCard/CourseCard";
import CardCarousel from "../../Shared/Components/SlickCarousel/SlickCarousel";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import TrainingCourseSpinnerCard from "../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";
import classes from "./courselist.module.css";

function CoursesList({ isLoggedIn }) {
  require("./coursesList.css");
  document.body.dir = i18n.dir();
  const history = useHistory();
  const { t } = useTranslation();
  const departmentsContext = useContext(DepartmentContext);
  const courseContext = useContext(CourseContext);
  const [publicCoursesList, setPublicCoursesList] = useState([]);
  const courseSpinner = [];

  for (let i = 0; i < 2; i++) {
    courseSpinner.push(<TrainingCourseSpinnerCard key={i} />);
  }

  useEffect(async () => {
    getAllDepartment()
      .then((res) => {
        departmentsContext.setDepartment(res.data.data);
        departmentsContext.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    try {
      let response = isLoggedIn
        ? await getPublicCoursesList({ type: "courses", status: "Published" })
        : await getAllCourses();
      courseContext.setIsContentLoaded(true);
      // console.log(response);

      if (response.data.status) {
        courseContext.setCoursesSearch(response.data.data.courses);

        if (i18n.dir() === "rtl") {
          isLoggedIn
            ? setPublicCoursesList(response.data.data.courses?.slice(0, 6))
            : courseContext.setCourseFilter(
                response.data.data.courses?.slice(0, 6)
              );
        } else {
          isLoggedIn
            ? setPublicCoursesList(
                response.data.data.courses?.slice(0, 6)
              ).reverse()
            : courseContext.setCourseFilter(
                response.data.data.courses?.slice(0, 6).reverse()
              );
        }
      }
    } catch (err) {
      console.log(err);
    }

    return () => {
      courseContext.setCoursesSearch([]);
      courseContext.setIsContentLoaded(false);
    };
  }, [isLoggedIn]);

  let spinner = [];
  for (let i = 1; i < 6; i++) {
    spinner.push(
      <div
        className={`col-4 col-md-2`}
        style={{
          marginTop: "-25px",
        }}
      >
        <SkeletonSpinnerOverlay
          backdrop={true}
          skeletonWidth="100"
          skeletonHight="65"
        />
      </div>
    );
  }

  return (
    <div
      className={`container-fluid ${classes.coursesContainer}`}
      id="new-courses"
    >
      <div className="row">
        <div className="col-12">
          <div className={classes.titleCoureses}>
            <div className={classes.titleFont}>
              {isLoggedIn ? t("my_current_courses") : t("NewTrainingCourses")}
            </div>
          </div>
          {publicCoursesList?.length > 0 ||
          courseContext.courseFilter?.length > 0 ? (
            <CardCarousel slidesToShow={3} courses={true}>
              {courseContext.isContentLoaded ? (
                courseContext.courseFilter.length !== 0 ? (
                  [
                    ...(isLoggedIn
                      ? publicCoursesList
                      : courseContext.courseFilter),
                  ].map((item) => {
                    return (
                      <>
                        <CourseCard
                          img={item?.img}
                          mode={item.mode}
                          briefDesc={item.brief_description}
                          seat={`${item.participators} /${item.number_of_users}`}
                          selfPaced={item.self_paced}
                          categoryName={item.category.name}
                          lang={item.language}
                          depName={
                            i18n.language === "ar"
                              ? item.department_name_ar
                              : item.department_name_en
                          }
                          level={item.level}
                          title={item?.name}
                          price={item?.price}
                          date={item.start_date.split(" ")[0]}
                          hour={item?.number_hours}
                          levelbadge={
                            item?.level == 1
                              ? t("levels.beginner")
                              : item?.level == 2
                              ? t("levels.medium")
                              : t("levels.professional")
                          }
                          handleSubmit={() =>
                            item.is_expired
                              ? history.push(
                                  `/training-courses/${item.id}/content`
                                )
                              : history.push(
                                  `/training-courses/${item.id}/details`
                                )
                          }
                          expired={item.is_expired}
                          btn={
                            item.is_expired
                              ? t("general.endcourse")
                              : t("general.registercourse")
                          }
                          home={true}
                          // userrate={3}
                        />
                        {/* <TrainingCoursesCard key={item.id} cardInfo={item} /> */}
                      </>
                    );
                  })
                ) : (
                  <span
                    className="course-details"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "50px",
                    }}
                  >
                    <img src={noResult} alt="" />
                    <h5>{t("no_record")}</h5>
                  </span>
                )
              ) : (
                courseSpinner
              )}
            </CardCarousel>
          ) : (
            <div className={classes["no_courses"]}>
              <p>{t(`home.no_courses`)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoursesList;

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import UploadAdapter from "../../../../../CKEditor/Adapter/UploadAdapter";
import EduGoalsModal from "../../../../../Modals/EduGoalsModal/EduGoalsModal";
import RestrictionModal from "../../../../../Modals/RestrictionModal/Modal/Modal";
import SurveysModal from "../../../../../Modals/surveyModal/SurveysModal";
import UploadModal from "../../../../../Modals/uploadModal/UploadModal";
import { RestrictionContextProvider } from "../../../../../Providers/RestrictionProvider";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";
import {
  deleteLesson,
  editLesson,
  editLessonContent,
  exportLessonContent,
  getCourseTree,
} from "../../../../../Services/api/courses/courseProvider";
import { getSurveysForLessonOrCourse } from "../../../../../Services/api/survey/SurveyProvider";
import WarningIcon from "../../../../../assets/icons/warning.svg";
import CourseDetailsHeader from "../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import DialogModal from "../../../../Shared/Components/DialogModal/DialogModal";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import { LessonRowActions } from "./lessonRowActions";
import EditLessonModal from "./EditLessonModal";

function LessonRow(props) {
  require("../ChapterRow/chapterRow.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const courseProfileContext = useContext(CourseProfileContext);
  const restrictionModalRef = useRef();
  const deleteModalRef = useRef();
  const surveysRef = useRef();

  const [survies, setSurvies] = useState([]);
  const [showSurviesModel, setShowSurviesModel] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isShowingEditPanel, setIsShowingEditPanel] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [showRestrictionModal, setShowRestrictionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [toBeChangedTextLesson, setToBeChangedTextLesson] = useState("");
  const [isLessonLabelEditable, setIsLessonLabelEditable] = useState(false);
  const [isChangingText, setIsChangingText] = useState(false);

  const openSurveyModel = (type) => {
    setShowSurviesModel(true);
    getSurveysForLesson(type);
    surveysRef.current.showModal();
  };
  const closeSurveyModel = () => {
    setShowSurviesModel(false);
    surveysRef.current.dismissModal();
  };
  const openEduGoalsModel = () => {
    setShowPopup(true);
  };
  const closeEduGoalsModel = () => {
    setShowPopup(false);
  };

  const getSurveysForLesson = (type) => {
    let courseId = "";
    let lessonId = "";
    if (courseProfileContext.courseID !== null) {
      courseId = courseProfileContext.courseID;
    } else {
      courseId = null;
    }
    if (courseProfileContext.lessonId !== null || type === "lesson") {
      lessonId = courseProfileContext.lessonId || props.item.id;
    } else {
      lessonId = null;
    }
    setSurvies([]);
    getSurveysForLessonOrCourse(courseId, lessonId)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSurvies(res.data.surveys);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  // const fetchCourseTree = () => {
  //   const myPromise = new Promise((resolve, reject) => {
  //     getCourseTree(courseProfileContext.courseID)
  //       .then((res) => {
  //         if (res.status && res.status == 200 && res.data.status) {
  //           courseProfileContext.setChapters(res.data.response.chapters);
  //           courseProfileContext.setIsChaptersLoaded(true);
  //         }
  //         resolve();
  //       })
  //       .catch((err) => {
  //         console.log("ERROR");
  //         console.log(err);
  //         resolve();
  //       });
  //   });

  //   return myPromise;
  // };

  // const editLessonLocal = () => {
  //   const myPromise = new Promise(async (resolve, reject) => {
  //     setIsChangingText(true);
  //     if (toBeChangedTextLesson != props.item.title) {
  //       editLesson(props.item.id, {
  //         title: toBeChangedTextLesson,
  //       })
  //         .then(async (res) => {
  //           if (res.status && res.status === 201 && res.data.status) {
  //             await fetchCourseTree();
  //           }
  //           setIsChangingText(false);
  //           resolve();
  //         })
  //         .catch((err) => {
  //           console.log("ERROR");
  //           console.log(err);
  //           setIsChangingText(false);
  //           resolve();
  //         });
  //     } else {
  //       setIsChangingText(false);
  //       resolve();
  //     }
  //   });

  //   return myPromise;
  // };

  const editLessonLocal = (payload) => {
    const myPromise = new Promise(async (resolve, reject) => {
      const dataToSend = {
        title: payload.title || props.item.title,
        start_date: payload.start_date,
        end_date: payload.end_date,
        previous_lesson_id: payload.previous_lesson_id,
        team_id: payload.team_id,
      };

      dataToSend.previous_lesson_id === null &&
        delete dataToSend.previous_lesson_id;
      dataToSend.start_date === null && delete dataToSend.start_date;
      dataToSend.end_date === null && delete dataToSend.end_date;
      dataToSend.team_id === null && delete dataToSend.team_id;

      editLesson(props.item.id, dataToSend)
        .then(async (res) => {
          if (res.status && res.status === 201 && res.data.status) {
            await props.fetchCourse();
          }
          resolve();
        })
        .catch((err) => {
          toast.error(
            <>
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {err.response.data.message || err.response.data.msg}
              </span>
              {err.response.data.errors && (
                <ul>
                  {Object.keys(err.response.data.errors).map((key) => {
                    return (
                      <li>
                        <span style={{ fontSize: 11 }}>
                          {err.response.data.errors[key]}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          );
          resolve();
        });
    });

    return myPromise;
  };

  const editLessonContentLocal = () => {
    editLessonContent(props.item.id, editorContent)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setIsShowingEditPanel(false);
          setEditorContent("");
        } else {
          toast.error(
            <>
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {res.data.message || res.data.msg}
              </span>
              {res.data.errors && (
                <ul>
                  {Object.keys(res.data.errors).map((key) => {
                    return (
                      <li>
                        <span style={{ fontSize: 11 }}>
                          {res.data.errors[key]}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };

  const openRestrictionModal = () => {
    setShowRestrictionModal(true);
    restrictionModalRef.current.showModal();
  };

  const closeRestrictionModal = () => {
    setShowRestrictionModal(false);
    restrictionModalRef.current.dismissModal();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    // deleteModalRef?.current?.showModal();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    // deleteModalRef?.current?.dismissModal();
  };

  const downloadFile = () => {
    exportLessonContent(
      "lesson",
      props.item.title,
      "zip",
      props.item.id,
      courseProfileContext
    );
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>
        {t("modal.trainer.course.chapter.messages.titleDownload")}
      </span>
    );
  };

  const removeLesson = () => {
    deleteLesson(props.item.id)
      .then(() => {
        props.removeLessonLocally(props.index);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.lesson.delete.successDeleted")}
          </span>
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.lesson.delete.successDeleted")}
          </span>
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  return (
    <div>
      <div
        className={`lesson-row-container ${
          props.opened ? "lesson-row-container-opened" : ""
        }`}
      >
        <BasicModal ref={surveysRef}>
          {showSurviesModel && (
            <SurveysModal
              label={t("survey.surviesLabel")}
              title={t("survey.surviesLabel")}
              body={survies}
              courseId={id}
              lessonId={courseProfileContext.lessonId}
              isTrainer={true}
              surveysModalRef={surveysRef}
              accept={closeSurveyModel}
            />
          )}
        </BasicModal>
        {showPopup && (
          <DialogModal
            onCancel={closeEduGoalsModel}
            className="tw-w-9/10 sm:tw-w-2/3 md:tw-w-3/5 lg:tw-w-1/2 xl:tw-w-1/3"
          >
            <EduGoalsModal
              title={t("edu.Choose_edu_goals")}
              refuse={closeEduGoalsModel}
              courseId={id}
              lessonId={courseProfileContext.lessonId}
              accept={closeEduGoalsModel}
              role={props.role}
            />
          </DialogModal>
        )}
        <BasicModal ref={restrictionModalRef}>
          {showRestrictionModal && (
            <EditLessonModal
              onClose={closeRestrictionModal}
              onSave={editLessonLocal}
              lesson={props.item}
              courseId={courseProfileContext.courseID}
              fetchCourse={props.fetchCourse}
              lessons={props.allLessons}
            />
          )}
        </BasicModal>
        {/* <BasicModal ref={deleteModalRef}> */}
        {showDeleteModal && (
          <SharedModal
            icon={WarningIcon}
            title={t("modal.trainer.course.chapter.delete.title")}
            name={props.item.title}
            confirmMsg={t("modal.trainer.course.lesson.delete.body")}
            confirmText={t("modal.trainer.course.lesson.delete.delete_aprove")}
            cancelText={t("modal.common.cancel")}
            onConfirm={removeLesson}
            onCancel={closeDeleteModal}
          />
        )}
        {/* </BasicModal> */}
        <CourseDetailsHeader
          title={props.item.title}
          actions={LessonRowActions({
            [props.role !== "content_developer" ? "settingsAction" : ""]:
              () => {
                openRestrictionModal();
              },
            // ...(props.isAdmin
            //   ? {
            //       usersAction: () => {
            //         history.push({
            //           pathname: `/${props.role}/detailed-report`,
            //           state: { title: props.item.title, courseID: id },
            //         });
            //       },
            //     }
            //   : {}),
            [props.role !== "partner" ? "addAction" : ""]: () => {
              history.push(
                `/${props.role}/course/${id}/lesson/${props.item.id}/survey/add`
              );
            },
            [props.role === "trainer" && props.item.available_surveys_count
              ? "surveyAction"
              : ""]: () => {
              // courseProfileContext.setShowSurviesModal(true);
              courseProfileContext.setCourseID(null);
              courseProfileContext.setLessonId(props.item.id);
              openSurveyModel("lesson");
            },
            ...(props.role !== "content_developer"
              ? {
                  goalsAction: () => {
                    // courseProfileContext.setPopupEduGoal(true);
                    courseProfileContext.setLessonId(props.item.id);
                    openEduGoalsModel();
                  },
                }
              : {}),
            [props.role === "partner" ? "" : "downloadAction"]: downloadFile,
            previewAction: () => {
              history.push({
                pathname: `${
                  props.role === "admin"
                    ? "/admin"
                    : props.role === "group-admin"
                    ? "/group-admin"
                    : props.role === "manager"
                    ? "/manager"
                    : props.role === "trainer"
                    ? "/trainer"
                    : props.role === "content_developer"
                    ? "/content_developer"
                    : ""
                }/course/${id}/lesson/${props.item.id}`,
                state: { title: props.item.title, courseID: id },
              });
            },
            [props.role === "partner"
              ? ""
              : isLessonLabelEditable
              ? "saveAction"
              : "editAction"]: async () => {
              if (isLessonLabelEditable) {
                await editLessonLocal({ title: toBeChangedTextLesson });
                setIsLessonLabelEditable(false);
                setToBeChangedTextLesson("");
              } else {
                setIsLessonLabelEditable(true);
                setToBeChangedTextLesson(props.item.title);
              }
            },
            [props.role === "partner" ? "" : "deleteAction"]: openDeleteModal,
            assignmentsAction: () => {
              history.push({
                pathname: `/${props.role}/course/${id}/lesson/${props.item.id}/assessments`,
              });
            },
          })}
          isLabelEditable={isLessonLabelEditable}
          onChange={(event) => {
            setToBeChangedTextLesson(event.target.value);
          }}
          value={toBeChangedTextLesson}
          disabled={isChangingText}
        />
      </div>
      {/* {isShowingEditPanel ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={editorContent}
              config={{
                simpleUpload: {
                  uploadUrl: "http://tajah-tebx.test/api/ckeditor/image-upload",
                  withCredentials: true,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                },
              }}
              onReady={(editor) => {
                editor.plugins.get("FileRepository").createUploadAdapter =
                  function (loader) {
                    return new UploadAdapter(loader);
                  };
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditorContent(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            <button
              className={"btn btn-sm"}
              style={{
                width: "30%",
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: document.body.dir === "rtl" ? 10 : 0,
                marginLeft: document.body.dir === "ltr" ? 10 : 0,
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                // console.log(editorContent);
                editLessonContentLocal();
              }}
            >
              {t("crud.placeholders.submit")}
            </button>
          </div>
        </>
      ) : null} */}
    </div>
  );
}

export default LessonRow;

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { useDispatch } from "react-redux";
import HomeIcon from "../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import classes from "./EventForm.module.css";

import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SunEditor from "suneditor-react";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import InputFile from "../../../Shared/Components/InputFile/InputFile";

const EventForm = ({
  type,
  submitHandler,
  eventDetails,
  types,
  audience_types,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);

  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const downloadFile = (url) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "events",
      page: t("events.events_title"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin/events`
          : localStorage.getItem("type") == "manager"
          ? `/manager/events`
          : "/admin/events",
    },
    {
      id: "create_event",
      page: type !== "Edit" ? t("add_event") : eventDetails?.title,
      active: true,
    },
  ];

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  useEffect(() => {
    currentDate();
    setIsLoading(false);
  }, []);

  return (
    <div className={classes["create_event"]}>
      <div className="container-fluid">
        <div className={classes["create_event__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && !loading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      ...(type === "Edit"
                        ? { _method: "put", resend_email: "" }
                        : {}),
                      title_ar: eventDetails?.title_ar || "",
                      title_en: eventDetails?.title_en || "",
                      description_ar: eventDetails?.description_ar || "",
                      description_en: eventDetails?.description_en || "",
                      location: eventDetails?.location || "",
                      type: eventDetails?.type?.value || "",

                      send_email: eventDetails?.send_email ? 1 : 0 || 0,
                      audience_type: "all",
                      file: eventDetails?.cover || "",
                      date: eventDetails?.date
                        ? getLocaleTime(new Date(eventDetails?.date))
                        : setHours(
                            setMinutes(new Date(), minutesDatepicker),
                            hoursDatepicker
                          ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let formData = new FormData();
                        Object.keys(values).forEach((field) => {
                          console.log(values);
                          if (field === "file") {
                            if (values[field]?.file) {
                              formData.append("cover", values[field].file);
                            } else {
                              if (!values[field]) {
                                formData.append("remove_image", 1);
                                formData.delete("file");
                              }
                            }
                          } else if (field === "date") {
                            formData.append(
                              "date",
                              toServerTime(
                                formatDate(new Date(values[field])) +
                                  " " +
                                  formatTime(new Date(values[field]))
                              )
                            );
                          } else {
                            formData.append(field, values[field]);
                          }
                        });
                        await submitHandler(formData);
                        setSubmitting(false);
                      } catch (err) {
                        console.log("err", err);
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.title_ar) {
                        errors.title_ar = t("crud.errors.required");
                      }
                      if (!values.title_en) {
                        errors.title_en = t("crud.errors.required");
                      }

                      if (!values.date) {
                        errors.date = t("crud.errors.required");
                      }

                      if (!values.description_ar) {
                        errors.description_ar = t("crud.errors.required");
                      }

                      if (!values.description_en) {
                        errors.description_en = t("crud.errors.required");
                      }
                      if (!values.location) {
                        errors.location = t("crud.errors.required");
                      }
                      if (!values.type) {
                        errors.type = t("crud.errors.required");
                      }
                      // if (!values.audience_type) {
                      //   errors.audience_type = t("crud.errors.required");
                      // }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_event__form"]}
                      >
                        <MainBoxHead
                          title={
                            type !== "Edit"
                              ? t("add_event")
                              : eventDetails?.title
                          }
                        />
                        <div
                          className={`row ${classes["create_event__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_ar_input")}
                              type="text"
                              name="title_ar"
                              placeholder={t("title_ar_input")}
                              onChange={handleChange}
                              error={errors.title_ar}
                              value={values.title_ar}
                              required={true}
                              max={50}
                              maxLength={50}
                              // note={t("event_field_note")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.title_ar ? null : t("event_field_note")}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_en_input")}
                              type="text"
                              name="title_en"
                              placeholder={t("title_en_input")}
                              onChange={handleChange}
                              error={errors.title_en}
                              value={values.title_en}
                              required={true}
                              max={50}
                              maxLength={50}
                              // note={t("event_field_note")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.title_en ? null : t("event_field_note")}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_ar"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_ar`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_ar"
                              name="description_ar"
                              placeholder={t(
                                `specialties_manage.specialty_description_ar`
                              )}
                              value={values.description_ar}
                              defaultValue={values.description_ar}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={500}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_ar
                                ? errors.description_ar
                                : t("event_field_note_des")}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_en"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_en`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_en"
                              name="description_en"
                              placeholder={t(
                                `specialties_manage.specialty_description_en`
                              )}
                              value={values.description_en}
                              defaultValue={values.description_en}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={500}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_en
                                ? errors.description_en
                                : t("event_field_note_des")}
                            </p>
                          </div>

                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("location_title")}
                              type="text"
                              name="location"
                              placeholder={t("location_title")}
                              onChange={handleChange}
                              error={errors.location}
                              value={values.location}
                              required={true}
                              max={50}
                              maxLength={50}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.location ? null : t("event_field_note")}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.event_date")}
                              name="date"
                              error={errors.date}
                              value={values.date}
                              onChange={(date) => {
                                setFieldValue("date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatepicker),
                                hoursDatepicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              required
                            />
                            <p className={"form-input-error-space"}>
                              {errors.date ? errors.date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="type">
                              {t("type")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="type"
                              id="type"
                              options={types}
                              value={types.find(
                                (option) => option.value == values.type
                              )}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("type", item.value);
                              }}
                              placeholder={t("type")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.type ? errors.type : null}
                            </p>
                          </div>
                          {/* <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="mechanism">
                              {t("audience_type")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="audience_type"
                              id="audience_type"
                              options={audience_types}
                              value={audience_types.find(
                                (option) => option.value == values.audience_type
                              )}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("audience_type", item.value);
                              }}
                              placeholder={t("audience_type")}
                              isDisabled={type === "Edit"}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.audience_type
                                ? errors.audience_type
                                : null}
                            </p>
                          </div> */}
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <Checkbox
                              name="send_email"
                              label={t(`send_emails`)}
                              onChange={(event) => {
                                setFieldValue(
                                  "send_email",
                                  event.target.checked
                                    ? 1
                                    : eventDetails?.send_email
                                    ? 1
                                    : 0
                                );
                              }}
                              checked={
                                values.send_email === true ||
                                values.send_email === 1
                              }
                              error={errors.send_email}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <InputFile
                              name="file"
                              type="image"
                              documentWithoutTitle={true}
                              accept={"image/*"}
                              label={t("Exhibition.upload_image")}
                              placeholder={t("Exhibition.upload_image")}
                              handleDownload={() => {
                                downloadFile(values.file);
                              }}
                              setFieldValue={setFieldValue}
                              value={values.file}
                              error={errors.file}
                            />
                          </div>
                          <div
                            className={`${classes["event__form__submit__container"]} col-12`}
                          >
                            {type === "Edit" &&
                              eventDetails?.send_email === true && (
                                <CustomButton
                                  type="button"
                                  colors="#036c77"
                                  value={t("Edit_email")}
                                  classes={
                                    classes["event__form__submit__button"]
                                  }
                                  action={() => {
                                    setFieldValue("resend_email", 1);
                                    handleSubmit();
                                  }}
                                  disable={
                                    isSubmitting || values?.send_email === 0
                                  }
                                  loading={isSubmitting}
                                />
                              )}
                            <CustomButton
                              type="submit"
                              colors="#036c77"
                              value={type === "Edit" ? t("Edit") : t("create")}
                              disable={isSubmitting}
                              loading={isSubmitting}
                            />
                          </div>
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventForm;
